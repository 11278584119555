import React from 'react';

interface ISquare_08 {
    fill: string;
    id?: string;
}

const Square_08: React.FC<ISquare_08> = ({ fill, id }) => {
  return (
    <svg width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_514_1944" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="294" height="294">
    <rect x="36" y="-36" width="6" height="366" fill="black"/>
    <rect x="108" y="-36" width="6" height="366" fill="black"/>
    <rect x="180" y="-36" width="6" height="366" fill="black"/>
    <rect x="252" y="-36" width="6" height="366" fill="black"/>
    <rect y="-36" width="6" height="366" fill="black"/>
    <rect x="72" y="-36" width="6" height="366" fill="black"/>
    <rect x="144" y="-36" width="6" height="366" fill="black"/>
    <rect x="216" y="-36" width="6" height="366" fill="black"/>
    <rect x="288" y="-36" width="6" height="366" fill="black"/>
    <rect x="54" y="-36" width="6" height="366" fill="black"/>
    <rect x="126" y="-36" width="6" height="366" fill="black"/>
    <rect x="198" y="-36" width="6" height="366" fill="black"/>
    <rect x="270" y="-36" width="6" height="366" fill="black"/>
    <rect x="18" y="-36" width="6" height="366" fill="black"/>
    <rect x="90" y="-36" width="6" height="366" fill="black"/>
    <rect x="162" y="-36" width="6" height="366" fill="black"/>
    <rect x="234" y="-36" width="6" height="366" fill="black"/>
    </mask>
    <g mask="url(#mask0_514_1944)">
    <rect width="294" height="294" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Square_08;

import React from 'react';

interface IEllipse_05 {
    fill: string;
    id?: string;
}

const Ellipse_05: React.FC<IEllipse_05> = ({ fill, id }) => {
  return (
    <svg width="388" height="200" viewBox="0 0 388 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1912" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="388" height="200">
    <rect y="-555" width="4" height="1272" fill="black"/>
    <rect x="12" y="-555" width="4" height="1272" fill="black"/>
    <rect x="24" y="-555" width="4" height="1272" fill="black"/>
    <rect x="36" y="-555" width="4" height="1272" fill="black"/>
    <rect x="48" y="-555" width="4" height="1272" fill="black"/>
    <rect x="60" y="-555" width="4" height="1272" fill="black"/>
    <rect x="72" y="-555" width="4" height="1272" fill="black"/>
    <rect x="84" y="-555" width="4" height="1272" fill="black"/>
    <rect x="96" y="-555" width="4" height="1272" fill="black"/>
    <rect x="108" y="-555" width="4" height="1272" fill="black"/>
    <rect x="120" y="-555" width="4" height="1272" fill="black"/>
    <rect x="132" y="-555" width="4" height="1272" fill="black"/>
    <rect x="144" y="-555" width="4" height="1272" fill="black"/>
    <rect x="156" y="-555" width="4" height="1272" fill="black"/>
    <rect x="168" y="-555" width="4" height="1272" fill="black"/>
    <rect x="180" y="-555" width="4" height="1272" fill="black"/>
    <rect x="192" y="-555" width="4" height="1272" fill="black"/>
    <rect x="204" y="-555" width="4" height="1272" fill="black"/>
    <rect x="216" y="-555" width="4" height="1272" fill="black"/>
    <rect x="228" y="-555" width="4" height="1272" fill="black"/>
    <rect x="240" y="-555" width="4" height="1272" fill="black"/>
    <rect x="252" y="-555" width="4" height="1272" fill="black"/>
    <rect x="264" y="-555" width="4" height="1272" fill="black"/>
    <rect x="276" y="-555" width="4" height="1272" fill="black"/>
    <rect x="288" y="-555" width="4" height="1272" fill="black"/>
    <rect x="300" y="-555" width="4" height="1272" fill="black"/>
    <rect x="312" y="-555" width="4" height="1272" fill="black"/>
    <rect x="324" y="-555" width="4" height="1272" fill="black"/>
    <rect x="336" y="-555" width="4" height="1272" fill="black"/>
    <rect x="348" y="-555" width="4" height="1272" fill="black"/>
    <rect x="360" y="-555" width="4" height="1272" fill="black"/>
    <rect x="372" y="-555" width="4" height="1272" fill="black"/>
    <rect x="384" y="-555" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1912)">
    <ellipse cx="194" cy="100" rx="194" ry="100" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Ellipse_05;

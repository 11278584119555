import React from 'react';

interface ICircle {
    fill: string;
    id?: string;
}

const Circle: React.FC<ICircle> = ({ fill, id }) => {
  return (
    <svg width="94" height="100" viewBox="0 0 94 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12_1135" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="94" height="100">
    <rect x="31" width="2" height="100" fill="black"/>
    <rect x="13" width="2" height="100" fill="black"/>
    <rect x="49" width="2" height="100" fill="black"/>
    <rect x="67" width="2" height="100" fill="black"/>
    <rect width="3" height="100" fill="black"/>
    <rect x="37" width="2" height="100" fill="black"/>
    <rect x="19" width="2" height="100" fill="black"/>
    <rect x="55" width="2" height="100" fill="black"/>
    <rect x="85" width="2" height="100" fill="black"/>
    <rect x="73" width="2" height="100" fill="black"/>
    <rect x="7" width="2" height="100" fill="black"/>
    <rect x="43" width="2" height="100" fill="black"/>
    <rect x="25" width="2" height="100" fill="black"/>
    <rect x="61" width="2" height="100" fill="black"/>
    <rect x="91" width="3" height="100" fill="black"/>
    <rect x="79" width="2" height="100" fill="black"/>
    </mask>
    <g mask="url(#mask0_12_1135)">
    <circle cx="47" cy="50" r="47" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Circle;

import React from 'react';

interface IEllipse_08 {
    fill: string;
    id?: string;
}

const Ellipse_08: React.FC<IEllipse_08> = ({ fill, id }) => {
  return (
    <svg width="384" height="200" viewBox="0 0 384 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_516_2327" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="384" height="200">
    <rect x="270" y="-44" width="6" height="261" fill="black"/>
    <rect x="126" y="-44" width="6" height="261" fill="black"/>
    <rect x="54" y="-44" width="6" height="261" fill="black"/>
    <rect x="342" y="-44" width="6" height="261" fill="black"/>
    <rect x="378" y="-44" width="6" height="261" fill="black"/>
    <rect x="198" y="-44" width="6" height="261" fill="black"/>
    <rect x="18" y="-44" width="6" height="261" fill="black"/>
    <rect x="306" y="-44" width="6" height="261" fill="black"/>
    <rect x="162" y="-44" width="6" height="261" fill="black"/>
    <rect x="90" y="-44" width="6" height="261" fill="black"/>
    <rect x="234" y="-44" width="6" height="261" fill="black"/>
    <rect y="-44" width="6" height="261" fill="black"/>
    <rect x="288" y="-44" width="6" height="261" fill="black"/>
    <rect x="144" y="-44" width="6" height="261" fill="black"/>
    <rect x="72" y="-44" width="6" height="261" fill="black"/>
    <rect x="360" y="-44" width="6" height="261" fill="black"/>
    <rect x="216" y="-44" width="6" height="261" fill="black"/>
    <rect x="36" y="-44" width="6" height="261" fill="black"/>
    <rect x="324" y="-44" width="6" height="261" fill="black"/>
    <rect x="180" y="-44" width="6" height="261" fill="black"/>
    <rect x="108" y="-44" width="6" height="261" fill="black"/>
    <rect x="252" y="-44" width="6" height="261" fill="black"/>
    </mask>
    <g mask="url(#mask0_516_2327)">
    <ellipse cx="192" cy="100" rx="192" ry="100" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Ellipse_08;

import React from 'react';

interface ISquareSmall_08 {
    fill: string;
    id?: string;
}

const SquareSmall_08: React.FC<ISquareSmall_08> = ({ fill, id }) => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_514_1945" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
    <rect x="36" y="-108" width="6" height="366" fill="black"/>
    <rect x="108" y="-108" width="6" height="366" fill="black"/>
    <rect y="-108" width="6" height="366" fill="black"/>
    <rect x="72" y="-108" width="6" height="366" fill="black"/>
    <rect x="144" y="-108" width="6" height="366" fill="black"/>
    <rect x="54" y="-108" width="6" height="366" fill="black"/>
    <rect x="126" y="-108" width="6" height="366" fill="black"/>
    <rect x="18" y="-108" width="6" height="366" fill="black"/>
    <rect x="90" y="-108" width="6" height="366" fill="black"/>
    </mask>
    <g mask="url(#mask0_514_1945)">
    <rect width="150" height="150" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default SquareSmall_08;

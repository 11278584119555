import React from 'react';

interface IInvertedTriangleSmall_02 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_02: React.FC<IInvertedTriangleSmall_02> = ({ fill, id }) => {
  return (
    <svg width="188" height="156" viewBox="0 0 188 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_520_769" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="188" height="156">
    <rect x="143" y="790" width="2" height="1426" transform="rotate(180 143 790)" fill="black"/>
    <rect x="167" y="790" width="2" height="1426" transform="rotate(180 167 790)" fill="black"/>
    <rect x="119" y="790" width="2" height="1426" transform="rotate(180 119 790)" fill="black"/>
    <rect x="179" y="790" width="2" height="1426" transform="rotate(180 179 790)" fill="black"/>
    <rect x="131" y="790" width="2" height="1426" transform="rotate(180 131 790)" fill="black"/>
    <rect x="155" y="790" width="2" height="1426" transform="rotate(180 155 790)" fill="black"/>
    <rect x="107" y="790" width="2" height="1426" transform="rotate(180 107 790)" fill="black"/>
    <rect x="185" y="790" width="2" height="1426" transform="rotate(180 185 790)" fill="black"/>
    <rect x="137" y="790" width="2" height="1426" transform="rotate(180 137 790)" fill="black"/>
    <rect x="161" y="790" width="2" height="1426" transform="rotate(180 161 790)" fill="black"/>
    <rect x="113" y="790" width="2" height="1426" transform="rotate(180 113 790)" fill="black"/>
    <rect x="173" y="790" width="2" height="1426" transform="rotate(180 173 790)" fill="black"/>
    <rect x="125" y="790" width="2" height="1426" transform="rotate(180 125 790)" fill="black"/>
    <rect x="149" y="790" width="2" height="1426" transform="rotate(180 149 790)" fill="black"/>
    <rect x="101" y="790" width="2" height="1426" transform="rotate(180 101 790)" fill="black"/>
    <rect x="95.0001" y="790" width="2" height="1426" transform="rotate(180 95.0001 790)" fill="black"/>
    <rect x="47.0001" y="790" width="2" height="1426" transform="rotate(180 47.0001 790)" fill="black"/>
    <rect x="71.0001" y="790" width="2" height="1426" transform="rotate(180 71.0001 790)" fill="black"/>
    <rect x="23.0001" y="790" width="2" height="1426" transform="rotate(180 23.0001 790)" fill="black"/>
    <rect x="83.0001" y="790" width="2" height="1426" transform="rotate(180 83.0001 790)" fill="black"/>
    <rect x="35.0001" y="790" width="2" height="1426" transform="rotate(180 35.0001 790)" fill="black"/>
    <rect x="59.0001" y="790" width="2" height="1426" transform="rotate(180 59.0001 790)" fill="black"/>
    <rect x="11.0001" y="790" width="2" height="1426" transform="rotate(180 11.0001 790)" fill="black"/>
    <rect x="89.0001" y="790" width="2" height="1426" transform="rotate(180 89.0001 790)" fill="black"/>
    <rect x="41.0001" y="790" width="2" height="1426" transform="rotate(180 41.0001 790)" fill="black"/>
    <rect x="65.0001" y="790" width="2" height="1426" transform="rotate(180 65.0001 790)" fill="black"/>
    <rect x="17.0001" y="790" width="2" height="1426" transform="rotate(180 17.0001 790)" fill="black"/>
    <rect x="77.0001" y="790" width="2" height="1426" transform="rotate(180 77.0001 790)" fill="black"/>
    <rect x="29.0001" y="790" width="2" height="1426" transform="rotate(180 29.0001 790)" fill="black"/>
    <rect x="53.0001" y="790" width="2" height="1426" transform="rotate(180 53.0001 790)" fill="black"/>
    <rect x="5.00006" y="790" width="2" height="1426" transform="rotate(180 5.00006 790)" fill="black"/>
    </mask>
    <g mask="url(#mask0_520_769)">
    <path d="M94 156L-1.58826e-05 1.07134e-05L188 -5.72205e-06L94 156Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangleSmall_02;

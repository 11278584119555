import React from 'react';

interface IRectangleSmall_10 {
    fill: string;
    id?: string;
}

const RectangleSmall_10: React.FC<IRectangleSmall_10> = ({ fill, id }) => {
  return (
    <svg width="248" height="111" viewBox="0 0 248 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_526_2453" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="248" height="111">
    <rect y="-663" width="8" height="1437" fill="black"/>
    <rect x="24" y="-663" width="8" height="1437" fill="black"/>
    <rect x="48" y="-663" width="8" height="1437" fill="black"/>
    <rect x="72" y="-663" width="8" height="1437" fill="black"/>
    <rect x="96" y="-663" width="8" height="1437" fill="black"/>
    <rect x="120" y="-663" width="8" height="1437" fill="black"/>
    <rect x="144" y="-663" width="8" height="1437" fill="black"/>
    <rect x="168" y="-663" width="8" height="1437" fill="black"/>
    <rect x="192" y="-663" width="8" height="1437" fill="black"/>
    <rect x="216" y="-663" width="8" height="1437" fill="black"/>
    <rect x="240" y="-663" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_526_2453)">
    <rect width="248" height="111" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RectangleSmall_10;

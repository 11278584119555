import React, { useEffect, useState } from 'react';
import './i18n';
import './App.css';
import Header from './components/Top/Header/Header';
import { ColorButtonPalette } from './components/Left/ColorButtonPalette/ColorButtonPalette';
import { ShapeButtonPalette } from './components/Right/ShapeButtonPalette/ShapeButtonPalette';
import Footer from './components/Bottom/Footer/Footer';
import { Palette } from './components/Palette';
import { Shapes } from './components/Shapes';
import { Shape } from './components/Shape';
import { StripeWidthList } from './components/StripeWidthList';
import { Vizualization } from './components/Vizualization';
import { loadConfig, getDefaultStripesWidthIndex, getDefaultShapePositions, getShapePalette, getInitShapes, getDefaultShapeColors } from "./utils/IConfigUtils";
import { IConfig } from "./utils/IConfig";
import { DemoInstructions } from './components/DemoInstructions/DemoInstructions';
import { TimerPopUp } from './components/TimerPopUp/TimerPopUp';
import { Intro } from './components/Intro';
import { getColorsArray, getStripesWidth, getShapes, getShapePosition, getEscapedSearch, getShapeColors, getLogoUrl } from './utils/queryParamsHelper';
import { ShapePosition } from './components/ShapePosition';
import QrCode from './components/QrCode';
import { SettingsPopUp } from './components/SettingsPopUp/SettingsPopUp';
import { SharePopUp } from './components/SharePopUp/SharePopUp';
import RemoteDownload from './components/remoteDownload';
import { useTranslation } from 'react-i18next';
import { ShapeColors } from './components/ShapeColors';

const config: IConfig = loadConfig();
const stripeWidthList: StripeWidthList = new StripeWidthList(getDefaultStripesWidthIndex(config.application.stripesWidths, config.application.stripesWidth));
const shapePalette: Shape[] = getShapePalette(config.application.shapePalette);
const initPalette: string[] = [...config.application.stripeColors];
const initShapes: Shape[] = getInitShapes(config.application.initShapes);
const initPositions: ShapePosition[] = getDefaultShapePositions(config.application.shapePositions);
const initColors: ShapeColors = getDefaultShapeColors(['#000000', '#000000', '#000000']);
let timerInterval: any;

const queryParams: URLSearchParams = new URLSearchParams(getEscapedSearch());
const print: boolean = (queryParams.get('print') === 'true' ? true : false);
const download: boolean = (queryParams.get('download') === null ? false : true);
const showIntro: boolean = (queryParams.get('intro') === null || queryParams.get('intro') === 'true' ? true : false);

const presettedColorArr: string[] = getColorsArray(queryParams, initPalette);
const presettedStripesWidth: number = getStripesWidth(queryParams, stripeWidthList);
const presettedShapes: any = getShapes(queryParams, initShapes, shapePalette, config.application.maxShapeRotationDegree);
const presettedShapePostions: ShapePosition[] = getShapePosition(queryParams, initPositions);
const presettedShapeColors: string[] = getShapeColors(queryParams, initColors);
const logoUrl: string = getLogoUrl(queryParams);


function App() {
  const { t } = useTranslation()
  const [state, setState] = useState({ pauseTimer: false, showShareUrlWithQrCode: false, shareUrl: "", showIntro: showIntro, showSharePopUp: false, showSettingsPopUp: false, showTimerPopUp: false, demoShow: false, demoStep: 1, anyAction: false, timer: config.application.timer, stripesWidth: presettedStripesWidth, colors: presettedColorArr, shapeColors: presettedShapeColors, shapes: presettedShapes, shapePositions: presettedShapePostions, selectedStripe: 0, selectedShape: 1, qrCodeVisible: false});
  const palette: Palette = new Palette([state.colors[0], state.colors[1], state.colors[2]]);
  const shapes: Shapes = new Shapes([state.shapes[0], state.shapes[1], state.shapes[2]]);
  const [currentDeg, setCurrentDeg] = useState<number>(0)
  const timerMin: number = Math.floor(state.timer / 60);
  const timerSec: number = state.timer % 60;

  useEffect(() => {
    setState(pv => ({ ...pv, selectedStripe: -1 }))
  }, [])

  if (typeof timerInterval === 'undefined' && state.anyAction === true && state.timer > 0) {
    timerInterval = setInterval(() => {
      setState(pv => {
          if(!pv.pauseTimer) {
            if (pv.timer <= 0) {
              // const defaultSettings = config.application;
              clearInterval(timerInterval);
              timerInterval = undefined;
              // return ({ ...pv, showIntro: true, showTimerPopUp: false, anyAction: false, timer: defaultSettings.timer, shapes: getInitShapes(defaultSettings.initShapes), colors: [...defaultSettings.stripeColors], shapePositions: getDefaultShapePositions(defaultSettings.shapePositions), stripesWidth: defaultSettings.stripesWidths[getDefaultStripesWidthIndex(defaultSettings.stripesWidths, defaultSettings.stripesWidth)] });
              return ({ ...pv, qrCodeVisible: true, showShareUrlWithQrCode: true });
            } else {
              let showPopUp = pv.showTimerPopUp;
              if (Math.floor(pv.timer) === Math.floor(config.application.timer / 5)) {
                showPopUp = true;
              }
    
              return ({ ...pv, showTimerPopUp: showPopUp, timer: pv.timer - 1 });
            }
          } else {
            return ({ ...pv });
          }
      });
    }, 1000);
  }

  function stopTimer() {
    clearInterval(timerInterval);
    timerInterval = undefined;
  }

  if (download)
    return <RemoteDownload 
    palette={palette} shapes={shapes} selectedShape={state.selectedShape} shapeColors={state.shapeColors}
    stripesWidth={state.stripesWidth} shapePositions={state.shapePositions} anyAction={state.anyAction}
    />
  else if (state.qrCodeVisible)
    return <QrCode
      stripesWidth={state.stripesWidth}
      colors={state.colors}
      shapes={shapes}
      shapePositions={state.shapePositions}
      shapeColors={state.shapeColors}
      showShareUrl={state.showShareUrlWithQrCode}
      stopTimer={() => stopTimer()}
      back={() => { setState(pv => ({ ...pv, qrCodeVisible: false, pauseTimer: false })) }}
      onResetToDefault={() => setState(pv => {
        const defaultSettings = config.application;

        return ({ ...pv, showShareUrlWithQrCode: false, shareUrl: "", showIntro: true, showTimerPopUp: false, anyAction: false, timer: defaultSettings.timer, shapes: getInitShapes(defaultSettings.initShapes), colors: [...defaultSettings.stripeColors], shapePositions: getDefaultShapePositions(defaultSettings.shapePositions), stripesWidth: defaultSettings.stripesWidths[getDefaultStripesWidthIndex(defaultSettings.stripesWidths, defaultSettings.stripesWidth)], qrCodeVisible: false });
      })}
    />
  else
    return (
      <div className={`App ${print === true ? 'App--print' : ''}`}>
        <h3 className="small-screen-warning">{t("smallScreenWarning")}</h3>

        <Intro show={state.showIntro} print={print} onToggleIntro={(bool) => setState(pv => ({ ...pv, showIntro: bool }))} onToggleSettingsPopUp={() => { setState(pv => ({ ...pv, showSettingsPopUp: !pv.showSettingsPopUp })) }} handleAction={(bool) => { setState(pv => ({ ...pv, anyAction: bool })) }} />
        <SettingsPopUp show={state.showSettingsPopUp} onToggleSettingsPopUp={(bool) => setState(pv => ({ ...pv, showSettingsPopUp: bool }))} />

        <div className={`App__content ${print === true ? 'App__content--print' : ''} ${state.showIntro ? 'hide' : ''}`}>
          <div className={`App__content--left ${print === true ? 'App__content--left-print' : ''}`}>
            <ColorButtonPalette onColorChange={(c) => setState(pv => {
              const colors = pv.colors;
              const shapeColors = pv.shapeColors;
              colors[pv.selectedStripe] = c;
              shapeColors[pv.selectedShape] = c;
              return ({ ...pv, anyAction: true, colors: colors })
            })} />
            <div></div>
          </div>

          <div className="App__content--center">
            <DemoInstructions show={state.demoShow} step={state.demoStep} onDemoInstructionsToggle={(bool) => { setState(pv => ({ ...pv, demoShow: bool })) }} />
            <TimerPopUp show={state.showTimerPopUp} text={`${t("only")} ${Math.floor(state.timer)} ${t("secondsLeft")}! \r\n ${t("hurryUp")}`} buttonText={t("ok")} onToggleTimerPopUp={(bool) => { setState(pv => ({ ...pv, showTimerPopUp: bool })) }} 
              onResetToDefault={() => setState(pv => {
                const defaultSettings = config.application;

                return ({ ...pv, showShareUrlWithQrCode: false, shareUrl: "", showIntro: true, showTimerPopUp: false, anyAction: false, timer: defaultSettings.timer, shapes: getInitShapes(defaultSettings.initShapes), colors: [...defaultSettings.stripeColors], shapePositions: getDefaultShapePositions(defaultSettings.shapePositions), stripesWidth: defaultSettings.stripesWidths[getDefaultStripesWidthIndex(defaultSettings.stripesWidths, defaultSettings.stripesWidth)], qrCodeVisible: false });
              })}
              stopTimer={() => stopTimer()}
            />
            <SharePopUp show={state.showSharePopUp} url={state.shareUrl} onToggleSharePopUp={(bool) => setState(pv => ({ ...pv, showSharePopUp: bool }))} />
            <SettingsPopUp show={state.showSettingsPopUp} onToggleSettingsPopUp={(bool) => setState(pv => ({ ...pv, showSettingsPopUp: bool }))} />

            <Header print={print} timer={state.timer} textMaxLength={100} text="Just like a musical score, the interpreter enters in the language and Spirit of the artist."
              onToggleSettingsPopUp={() => { setState(pv => ({ ...pv, showSettingsPopUp: !pv.showSettingsPopUp })) }} />

            <Vizualization
              palette={palette} shapes={shapes} selectedShape={state.selectedShape} shapeColors={state.shapeColors}
              onStripeChanged={(i) => { setState(pv => ({ ...pv, anyAction: true, selectedStripe: i})) }}
              stripesWidth={state.stripesWidth} shapePositions={state.shapePositions} anyAction={state.anyAction}
              onShapePositionChange={(shapePositions) => { setState(pv => ({ ...pv, anyAction: true, shapePositions: shapePositions })) }}
            />

            <Footer shapePalette={shapePalette} selectedStripe={state.selectedStripe} shapeColors={state.shapeColors} palette={palette} selectedShape={state.selectedShape} shapes={shapes} shapePositions={state.shapePositions}
              shapeRotationDegree={config.application.shapeRotationDegree} maxShapeRotationDegree={config.application.maxShapeRotationDegree} stripesWidth={state.stripesWidth} colors={state.colors} print={print}
              showQrCode={() => { setState(pv => ({ ...pv, pauseTimer: true, qrCodeVisible: true })) }}
              onStripeChanged={(i) => { setState(pv => ({ ...pv, anyAction: true, selectedStripe: i, selectedShape: -1 })) }}
              onToggleSettingsPopUp={() => { setState(pv => ({ ...pv, showSettingsPopUp: !pv.showSettingsPopUp })) }}
              stopTimer={() => stopTimer()}
              logoUrl={logoUrl}
              onColorChanged={() => {
                const colorPool = ["#FFFFFF", "#000000", "#CB5100", "#C8CDCE", "#009E33","#00C300", "#A7B001", "#B5F200", "#00CF32", "#0048C2", "#0098EC", "#00D4CF", "#8EC8F8", "#FE1B00", "#FE3B00", "#FF7C00", "#FFB613", "#E400B8", "#6A00BC", "#A14000", "#AD251D", "#F5BED6", "#C6A0F6", "#FED400"];
                
                const shuffleArray = (array: string[]) => {
                  for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                  }
                  return array;
                };
                
                const shuffledPool = shuffleArray(colorPool);
                const randomColors = shuffledPool.slice(0, 3);
                
                setState(pv => ({ ...pv, anyAction: true, colors: randomColors }));
              }}
              
              onShapeSelect={(i) => {
                setState(pv => ({ ...pv, anyAction: true, selectedShape: i, selectedStripe: -1 }))
              }}
              onShapeChange={(shape) => setState(pv => {
                shape.rotate = currentDeg + shape.defaultRotate
                const Shapes = pv.shapes;
                Shapes[pv.selectedShape] = shape;
                return ({ ...pv, anyAction: true, shapes: Shapes })
              })}
              onShapeHidden={() => setState(pv => {
                const Shapes: Shape[] = [...pv.shapes];
                const shape = Shapes[state.selectedShape];

                Shapes[state.selectedShape] = Shape.create(shape.name, shape.fileName, shape.title, 'hidden', shape.r, shape.rx, shape.ry, shape.cx, shape.cy, shape.width, shape.height, shape.rotate, shape.defaultRotate, shape.fill);

                return ({ ...pv, anyAction: true, shapes: Shapes });
              })}
              onRotationChange={(deg: number, direction: string) => setState(pv => {
                const Shapes: Shape[] = [...pv.shapes];
                const shape = Shapes[state.selectedShape];

                if(shape) {
                  Shapes[state.selectedShape] = Shape.create(shape.name, shape.fileName, shape.title, shape.tagName, shape.r, shape.rx, shape.ry, shape.cx, shape.cy, shape.width, shape.height, shape.getNextRotate(deg, direction, config.application.maxShapeRotationDegree), shape.defaultRotate, shape.fill);
                  setCurrentDeg(shape.rotate - shape.defaultRotate)
                }

                return ({ ...pv, anyAction: true, shapes: Shapes });
              })}
              onResetToDefault={() => setState(pv => {
                const defaultSettings = config.application;

                return ({ ...pv, showShareUrlWithQrCode: false, shareUrl: "", showIntro: true, showTimerPopUp: false, anyAction: false, timer: defaultSettings.timer, shapes: getInitShapes(defaultSettings.initShapes), colors: [...defaultSettings.stripeColors], shapePositions: getDefaultShapePositions(defaultSettings.shapePositions), stripesWidth: defaultSettings.stripesWidths[getDefaultStripesWidthIndex(defaultSettings.stripesWidths, defaultSettings.stripesWidth)], qrCodeVisible: false });
              })}
              onDemoInstructionsToggle={(bool) => { setState(pv => ({ ...pv, demoShow: bool })) }}
              onToggleSharePopUp={(url: string) => { setState(pv => ({ ...pv, showSharePopUp: !pv.showSharePopUp, shareUrl: url })) }}
            />
          </div>

          <div className={`App__content--right ${print === true ? 'App__content--right-print' : ''}`}>
            <ShapeButtonPalette shapePalette={shapePalette} onShapeChange={(shape) => setState(pv => {
              shape.rotate = currentDeg + shape.defaultRotate
              const Shapes = pv.shapes;
              Shapes[pv.selectedShape] = shape;
              return ({ ...pv, anyAction: true, shapes: Shapes })
            })} />
            <p className={`header__timer header__timer--${state.timer <= 0 ? 'hide' : 'show'}`}>{`${t("timeRemaining")}:`} {(timerMin < 10 ? `0${timerMin}` : timerMin)}:{(timerSec < 10 ? `0${timerSec}` : timerSec)}</p>
          </div>
        </div>
      </div>
    );
}

export default App;
import React from 'react';

interface ICircle_08 {
    fill: string;
    id?: string;
}

const Circle_08: React.FC<ICircle_08> = ({ fill, id }) => {
  return (
    <svg width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_517_2378" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="294" height="294">
    <rect x="18" y="-87" width="6" height="528" fill="black"/>
    <rect x="162" y="-87" width="6" height="528" fill="black"/>
    <rect x="90" y="-87" width="6" height="528" fill="black"/>
    <rect x="234" y="-87" width="6" height="528" fill="black"/>
    <rect x="54" y="-87" width="6" height="528" fill="black"/>
    <rect x="198" y="-87" width="6" height="528" fill="black"/>
    <rect x="126" y="-87" width="6" height="528" fill="black"/>
    <rect x="270" y="-87" width="6" height="528" fill="black"/>
    <rect x="36" y="-87" width="6" height="528" fill="black"/>
    <rect x="180" y="-87" width="6" height="528" fill="black"/>
    <rect x="108" y="-87" width="6" height="528" fill="black"/>
    <rect x="252" y="-87" width="6" height="528" fill="black"/>
    <rect x="72" y="-87" width="6" height="528" fill="black"/>
    <rect y="-87" width="6" height="528" fill="black"/>
    <rect x="216" y="-87" width="6" height="528" fill="black"/>
    <rect x="144" y="-87" width="6" height="528" fill="black"/>
    <rect x="288" y="-87" width="6" height="528" fill="black"/>
    </mask>
    <g mask="url(#mask0_517_2378)">
    <circle cx="147" cy="147" r="147" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Circle_08;

import React from 'react';

interface ITriangleSmall_05 {
    fill: string;
    id?: string;
}

const TriangleSmall_05: React.FC<ITriangleSmall_05> = ({ fill, id }) => {
  return (
    <svg width="196" height="156" viewBox="0 0 196 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1250" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="196" height="156">
    <rect y="-558" width="4" height="1272" fill="black"/>
    <rect x="12" y="-558" width="4" height="1272" fill="black"/>
    <rect x="24" y="-558" width="4" height="1272" fill="black"/>
    <rect x="36" y="-558" width="4" height="1272" fill="black"/>
    <rect x="48" y="-558" width="4" height="1272" fill="black"/>
    <rect x="60" y="-558" width="4" height="1272" fill="black"/>
    <rect x="72" y="-558" width="4" height="1272" fill="black"/>
    <rect x="84" y="-558" width="4" height="1272" fill="black"/>
    <rect x="96" y="-558" width="4" height="1272" fill="black"/>
    <rect x="108" y="-558" width="4" height="1272" fill="black"/>
    <rect x="120" y="-558" width="4" height="1272" fill="black"/>
    <rect x="132" y="-558" width="4" height="1272" fill="black"/>
    <rect x="144" y="-558" width="4" height="1272" fill="black"/>
    <rect x="156" y="-558" width="4" height="1272" fill="black"/>
    <rect x="168" y="-558" width="4" height="1272" fill="black"/>
    <rect x="180" y="-558" width="4" height="1272" fill="black"/>
    <rect x="192" y="-558" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1250)">
    <path d="M98 0L196 156H0L98 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default TriangleSmall_05;

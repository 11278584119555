import React from 'react';
import Background from './Background';
import useKeypress from 'react-use-keypress';
import { Figure } from './Figure';
import { IVizualizationParams } from './IVizualizationParams';
import { loadConfig } from '../utils/IConfigUtils';
import { IConfig } from '../utils/IConfig';

const config: IConfig = loadConfig();

export function Vizualization(params: IVizualizationParams) {
  const step: number = 1.54;

  /*  useKeypress is for testing - please remove it after developing*/
  useKeypress(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'], (event: any) => {
    if (event.key === 'ArrowLeft') {
      params.shapePositions[params.selectedShape].left -= step;
    } else if (event.key === 'ArrowRight') {
      params.shapePositions[params.selectedShape].left += step;
    } else if (event.key === 'ArrowUp') {
      params.shapePositions[params.selectedShape].top -= step;
    } else if (event.key === 'ArrowDown') {
      params.shapePositions[params.selectedShape].top += step;
    }
    params.onShapePositionChange(params.shapePositions);
  });

  return <div className="App__workspace" style={{width: config.application.workspaceDimensions.width, height: config.application.workspaceDimensions.height}}>
    <Background palette={params.palette} stripesWidth={params.stripesWidth} figure={[
      <Figure key="0" id={0} shapeArr={params.shapes.shapes} shapeHeight={params.shapes.shapes[0].height} shapeWidth={params.shapes.shapes[0].width} shapeRotate={params.shapes.shapes[0].rotate} shape={params.shapes.next()} shapePosition={params.shapePositions[0]} selectedShape={params.selectedShape} stripesWidth={params.stripesWidth} anyAction={params.anyAction} fill={params.shapeColors[0].includes('#') ? params.shapeColors[0] : `#${params.shapeColors[0]}` } onShapePositionChange={(shapePosition: any) => { params.shapePositions[params.selectedShape] = shapePosition; params.onShapePositionChange(params.shapePositions);}} />,
      <Figure key="1" id={1} shapeArr={params.shapes.shapes} shapeHeight={params.shapes.shapes[1].height} shapeWidth={params.shapes.shapes[1].width} shapeRotate={params.shapes.shapes[1].rotate} shape={params.shapes.next()} shapePosition={params.shapePositions[1]} selectedShape={params.selectedShape} stripesWidth={params.stripesWidth} anyAction={params.anyAction} fill={params.shapeColors[1].includes('#') ? params.shapeColors[1] : `#${params.shapeColors[1]}` } onShapePositionChange={(shapePosition: any) => { params.shapePositions[params.selectedShape] = shapePosition; params.onShapePositionChange(params.shapePositions); }} />,
      <Figure key="2" id={2} shapeArr={params.shapes.shapes} shapeHeight={params.shapes.shapes[2].height} shapeWidth={params.shapes.shapes[2].width} shapeRotate={params.shapes.shapes[2].rotate} shape={params.shapes.next()} shapePosition={params.shapePositions[2]} selectedShape={params.selectedShape} stripesWidth={params.stripesWidth} anyAction={params.anyAction} fill={params.shapeColors[2].includes('#') ? params.shapeColors[2] : `#${params.shapeColors[2]}` } onShapePositionChange={(shapePosition: any) => { params.shapePositions[params.selectedShape] = shapePosition; params.onShapePositionChange(params.shapePositions); }} />
    ]} />
  </div>;
}
import React from 'react';

interface IInvertedTriangle_10 {
    fill: string;
    id?: string;
}

const InvertedTriangle_10: React.FC<IInvertedTriangle_10> = ({ fill, id }) => {
  return (
    <svg width="392" height="302" viewBox="0 0 392 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2093" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="392" height="302">
    <rect x="392" y="870" width="8" height="1437" transform="rotate(-180 392 870)" fill="black"/>
    <rect x="368" y="870" width="8" height="1437" transform="rotate(-180 368 870)" fill="black"/>
    <rect x="344" y="870" width="8" height="1437" transform="rotate(-180 344 870)" fill="black"/>
    <rect x="320" y="870" width="8" height="1437" transform="rotate(-180 320 870)" fill="black"/>
    <rect x="296" y="870" width="8" height="1437" transform="rotate(-180 296 870)" fill="black"/>
    <rect x="272" y="870" width="8" height="1437" transform="rotate(-180 272 870)" fill="black"/>
    <rect x="248" y="870" width="8" height="1437" transform="rotate(-180 248 870)" fill="black"/>
    <rect x="224" y="870" width="8" height="1437" transform="rotate(-180 224 870)" fill="black"/>
    <rect x="200" y="870" width="8" height="1437" transform="rotate(-180 200 870)" fill="black"/>
    <rect x="176" y="870" width="8" height="1437" transform="rotate(-180 176 870)" fill="black"/>
    <rect x="152" y="870" width="8" height="1437" transform="rotate(-180 152 870)" fill="black"/>
    <rect x="128" y="870" width="8" height="1437" transform="rotate(-180 128 870)" fill="black"/>
    <rect x="104" y="870" width="8" height="1437" transform="rotate(-180 104 870)" fill="black"/>
    <rect x="80" y="870" width="8" height="1437" transform="rotate(-180 80 870)" fill="black"/>
    <rect x="56" y="870" width="8" height="1437" transform="rotate(-180 56 870)" fill="black"/>
    <rect x="32" y="870" width="8" height="1437" transform="rotate(-180 32 870)" fill="black"/>
    <rect x="8" y="870" width="8" height="1437" transform="rotate(-180 8 870)" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2093)">
    <path d="M196 302L3.6239e-05 -2.93225e-05L392 4.94719e-06L196 302Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangle_10;

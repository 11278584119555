import { StripeWidthList } from '../components/StripeWidthList';
import { ShapePosition } from '../components/ShapePosition';
import { Shape } from '../components/Shape';
import { loadConfig } from '../utils/IConfigUtils';
import { IConfig } from '../utils/IConfig';
import { ShapeColors } from '../components/ShapeColors';

const config: IConfig = loadConfig();
const workspaceDimensions = config.application.workspaceDimensions;

const getEscapedSearch = (): string => {
    var escapedSearch = '';
    for (let i = 0; i < window.location.search.length; i++) {
        const element = window.location.search[i];
        escapedSearch += element.replace("_", "&").replace(":", "=").replace("#", "");
    }
    return escapedSearch;
}

const getColorsArray = (queryParams: URLSearchParams, deafultColors: string[]): string[] => {
    let colorArr: string[] = [];
    for (let i = 1; i <= deafultColors.length; i++) {
        colorArr.push(`${queryParams.get(`c${i}`) === null ? deafultColors[i - 1] : '#'+queryParams.get(`c${i}`)}`);
    }

    return colorArr;
}

const getStripesWidth = (queryParams: URLSearchParams, stripeWidthList: StripeWidthList): number => {
    let presettedStripesWidth: number = 0;

    if (queryParams.get('stripesWidth') !== null) {
        const floatQueryParam: number = Number(queryParams.get('stripesWidth'));

        do {
            presettedStripesWidth = stripeWidthList.next();
        } while (stripeWidthList.widths.includes(floatQueryParam) && presettedStripesWidth !== floatQueryParam);
    } else {
        presettedStripesWidth = stripeWidthList.next();
    }

    return presettedStripesWidth;
}

const getShapes = (queryParams: URLSearchParams, initShapes: Shape[], shapePalette: Shape[], maxShapeRotationDegree: number): Shape[] => {
    for(let i: number = 1; i <= initShapes.length; i++) {
        if (queryParams.get(`shape${i}-name`) !== null) {
            const name: string | null = queryParams.get(`shape${i}-name`);

            shapePalette.some((shape) => {
                if(shape.name === name) {
                    initShapes[i-1] = shape;
                    return true;
                }
                return false;
            });
        }
    
        if (queryParams.get(`shape${i}-rotate`) !== null) {
            const rotate: number = Number(queryParams.get(`shape${i}-rotate`));
            
            if(rotate <= (maxShapeRotationDegree + initShapes[i-1].defaultRotate) || rotate >= 360 - (maxShapeRotationDegree + initShapes[i-1].defaultRotate)) {
                initShapes[i-1].rotate = rotate;
            }
        }
    }

    return initShapes;
}

const getShapePosition = (queryParams: URLSearchParams, initPositions: ShapePosition[]): ShapePosition[] => {
    for(let i: number = 1; i <= initPositions.length; i++) {
        if (queryParams.get(`shape${i}-left`) !== null) {
            const left: number = Number(queryParams.get(`shape${i}-left`));
    
            if (left >= 0 && left <= workspaceDimensions.width) {
                initPositions[i-1].left = left;
            }
        }
    
        if (queryParams.get(`shape${i}-left`) !== null) {
            const top: number = Number(queryParams.get(`shape${i}-top`));
    
            if (top >= 0 && top <= workspaceDimensions.height) {
                initPositions[i-1].top = top;
            }
        }
    }

    return initPositions;
}

const getShapeColors = (queryParams: URLSearchParams, initColors: ShapeColors) => {
    for(let i: number = 1; i <= initColors.colors.length; i++) {
        if (queryParams.get(`shape${i}-color`) !== null) {
            const color: string = String(queryParams.get(`shape${i}-color`));

            if(color) {
                initColors.colors[i-1] = color;
            }
        }
    }

    return initColors.colors;
}

const getLogoUrl = (queryParams: URLSearchParams) => {
    let logoUrl = "";
    if (queryParams.get("logoUrl") !== null) {
      const encodedLogoUrl = queryParams.get("logoUrl")!;
      
      logoUrl = encodedLogoUrl;
    }
  
    return logoUrl;
};

const getLanguage = (queryParams: URLSearchParams): string | null => {
    return queryParams.has('lang') ? queryParams.get('lang') : null;
};
  

export { getColorsArray, getStripesWidth, getShapes, getShapePosition, getEscapedSearch, getShapeColors, getLogoUrl, getLanguage };
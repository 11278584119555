import React from 'react';

interface IInvertedTriangle_15 {
    fill: string;
    id?: string;
}

const InvertedTriangle_15: React.FC<IInvertedTriangle_15> = ({ fill, id }) => {
  return (
    <svg width="372" height="302" viewBox="0 0 372 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_532_328" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="372" height="302">
    <rect x="372" y="1132" width="12" height="1961" transform="rotate(-180 372 1132)" fill="black"/>
    <rect x="336" y="1132" width="12" height="1961" transform="rotate(-180 336 1132)" fill="black"/>
    <rect x="300" y="1132" width="12" height="1961" transform="rotate(-180 300 1132)" fill="black"/>
    <rect x="264" y="1132" width="12" height="1961" transform="rotate(-180 264 1132)" fill="black"/>
    <rect x="228" y="1132" width="12" height="1961" transform="rotate(-180 228 1132)" fill="black"/>
    <rect x="192" y="1132" width="12" height="1961" transform="rotate(-180 192 1132)" fill="black"/>
    <rect x="156" y="1132" width="12" height="1961" transform="rotate(-180 156 1132)" fill="black"/>
    <rect x="120" y="1132" width="12" height="1961" transform="rotate(-180 120 1132)" fill="black"/>
    <rect x="83.9999" y="1132" width="12" height="1961" transform="rotate(-180 83.9999 1132)" fill="black"/>
    <rect x="47.9999" y="1132" width="12" height="1961" transform="rotate(-180 47.9999 1132)" fill="black"/>
    <rect x="11.9999" y="1132" width="12" height="1961" transform="rotate(-180 11.9999 1132)" fill="black"/>
    </mask>
    <g mask="url(#mask0_532_328)">
    <path d="M186 302L7.74589e-06 5.26062e-06L372 3.77819e-05L186 302Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangle_15;

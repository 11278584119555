import React from 'react';

interface ISquareSmall_15 {
    fill: string;
    id?: string;
}

const SquareSmall_15: React.FC<ISquareSmall_15> = ({ fill, id }) => {
  return (
    <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_2968" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="156" height="156">
    <rect y="-905" width="12" height="1961" fill="black"/>
    <rect x="36" y="-905" width="12" height="1961" fill="black"/>
    <rect x="72" y="-905" width="12" height="1961" fill="black"/>
    <rect x="108" y="-905" width="12" height="1961" fill="black"/>
    <rect x="144" y="-905" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_2968)">
    <rect width="156" height="156" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default SquareSmall_15;

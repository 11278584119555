export class User {
    id?: number;
    type?: string;
    name?: string;
    passHash?: number;
    lastActive?: number;
    isLogged: boolean = false;
    inactivityTimeToLogout: number = 2; // min

    static create(isLogged: boolean, id?: number, type?: string, name?: string, passHash?: number, lastActive?: number) {
        let user = new User();
        user.isLogged = isLogged;
        user.id = id;
        user.type = type;
        user.name = name;
        user.passHash = passHash;
        user.lastActive = lastActive;
        return user;
    }
    
    isUserLoggedIn(): boolean {
        if(this.id === undefined) {
            return false;
        } else {
            if(new Date().getTime() - this.lastActive! > this.inactivityTimeToLogout * 60 * 1000) { // should sing out after X min of inactivity
                this.signOut();
                return false;
            } else {
                return true;
            }
        }
    }

    signIn() {
        this.isLogged = true;
        this.lastActive = new Date().getTime();
    }

    signOut() {
        this.isLogged = false;
    }

    updateActivity() {
        this.lastActive = new Date().getTime();
    }
}
import React from 'react';

interface IRhombus {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const Rhombus: React.FC<IRhombus> = ({ fill, id, width, height }) => {
  return (
    <svg width="99" height="97" viewBox="0 0 99 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12_737" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="99" height="97">
    <rect width="1.41429" height="96.8786" fill="black"/>
    <rect x="25.4572" width="1.41429" height="96.8786" fill="black"/>
    <rect x="12.7285" width="1.41429" height="96.8786" fill="black"/>
    <rect x="38.1857" width="1.41429" height="96.8786" fill="black"/>
    <rect x="50.9143" width="1.41429" height="96.8786" fill="black"/>
    <rect x="72.1285" width="1.41429" height="96.8786" fill="black"/>
    <rect x="89.1" width="1.41429" height="96.8786" fill="black"/>
    <rect x="4.94995" width="1.41429" height="96.8786" fill="black"/>
    <rect x="29.7" width="1.41429" height="96.8786" fill="black"/>
    <rect x="16.9714" width="1.41429" height="96.8786" fill="black"/>
    <rect x="42.4286" width="1.41429" height="96.8786" fill="black"/>
    <rect x="63.6428" width="1.41429" height="96.8786" fill="black"/>
    <rect x="55.1571" width="1.41429" height="96.8786" fill="black"/>
    <rect x="76.3715" width="1.41429" height="96.8786" fill="black"/>
    <rect x="92.6357" width="1.41429" height="96.8786" fill="black"/>
    <rect x="8.48572" width="1.41429" height="96.8786" fill="black"/>
    <rect x="33.9429" width="1.41429" height="96.8786" fill="black"/>
    <rect x="21.2142" width="1.41429" height="96.8786" fill="black"/>
    <rect x="46.6714" width="1.41429" height="96.8786" fill="black"/>
    <rect x="67.8857" width="1.41429" height="96.8786" fill="black"/>
    <rect x="84.8572" width="1.41429" height="96.8786" fill="black"/>
    <rect x="59.4" width="1.41429" height="96.8786" fill="black"/>
    <rect x="80.6143" width="1.41429" height="96.8786" fill="black"/>
    <rect x="97.5857" width="1.41429" height="96.8786" fill="black"/>
    </mask>
    <g mask="url(#mask0_12_737)">
    <rect x="4.94995" y="48.7928" width="62.9357" height="62.9357" transform="rotate(-45 4.94995 48.7928)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rhombus;

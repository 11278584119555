import { Shape } from "./Shape";

export class Shapes {
    shapes: Shape[] = [];
    counter: number = 0;

    constructor(shapes : Shape[]){
        this.shapes = shapes;
    }

    next(): Shape {
        return this.shapes[this.counter++ % this.shapes.length];
    }
}
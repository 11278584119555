import React from 'react';

interface ITriangleSmall_02 {
    fill: string;
    id?: string;
}

const TriangleSmall_02: React.FC<ITriangleSmall_02> = ({ fill, id }) => {
  return (
    <svg width="188" height="156" viewBox="0 0 188 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_520_769" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="188" height="156">
    <rect x="45" y="-634" width="2" height="1426" fill="black"/>
    <rect x="21" y="-634" width="2" height="1426" fill="black"/>
    <rect x="69" y="-634" width="2" height="1426" fill="black"/>
    <rect x="9" y="-634" width="2" height="1426" fill="black"/>
    <rect x="57" y="-634" width="2" height="1426" fill="black"/>
    <rect x="33" y="-634" width="2" height="1426" fill="black"/>
    <rect x="81" y="-634" width="2" height="1426" fill="black"/>
    <rect x="3" y="-634" width="2" height="1426" fill="black"/>
    <rect x="51" y="-634" width="2" height="1426" fill="black"/>
    <rect x="27" y="-634" width="2" height="1426" fill="black"/>
    <rect x="75" y="-634" width="2" height="1426" fill="black"/>
    <rect x="15" y="-634" width="2" height="1426" fill="black"/>
    <rect x="63" y="-634" width="2" height="1426" fill="black"/>
    <rect x="39" y="-634" width="2" height="1426" fill="black"/>
    <rect x="87" y="-634" width="2" height="1426" fill="black"/>
    <rect x="93" y="-634" width="2" height="1426" fill="black"/>
    <rect x="141" y="-634" width="2" height="1426" fill="black"/>
    <rect x="117" y="-634" width="2" height="1426" fill="black"/>
    <rect x="165" y="-634" width="2" height="1426" fill="black"/>
    <rect x="105" y="-634" width="2" height="1426" fill="black"/>
    <rect x="153" y="-634" width="2" height="1426" fill="black"/>
    <rect x="129" y="-634" width="2" height="1426" fill="black"/>
    <rect x="177" y="-634" width="2" height="1426" fill="black"/>
    <rect x="99" y="-634" width="2" height="1426" fill="black"/>
    <rect x="147" y="-634" width="2" height="1426" fill="black"/>
    <rect x="123" y="-634" width="2" height="1426" fill="black"/>
    <rect x="171" y="-634" width="2" height="1426" fill="black"/>
    <rect x="111" y="-634" width="2" height="1426" fill="black"/>
    <rect x="159" y="-634" width="2" height="1426" fill="black"/>
    <rect x="135" y="-634" width="2" height="1426" fill="black"/>
    <rect x="183" y="-634" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_520_769)">
    <path d="M94 0L188 156H0L94 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default TriangleSmall_02;

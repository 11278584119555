export class Shape {
    name: string = "square";
    fileName: string = "square.svg";
    title: string = "Square";
    tagName: string = "rect";
    r: number = 0;
    rx: number = 0;
    ry: number = 0;
    cx: number = 0;
    cy: number = 0;
    width: number = 0;
    height: number = 0;
    rotate: number = 0;
    defaultRotate: number = 0;
    fill: string = '#567567'
    hidden: boolean = false;

    static create(name: string, fileName: string, title: string, tagName: string, r: number, rx: number, ry: number, cx: number, cy: number, width: number, height: number, rotate: number, defaultRotate: number, fill: string, hidden?: boolean) {
        let shape = new Shape();
        shape.name = name;
        shape.fileName = fileName;
        shape.title = title;
        shape.tagName = tagName;
        shape.r = r;
        shape.rx = rx;
        shape.ry = ry;
        shape.cx = cx;
        shape.cy = cy;
        shape.width = width;
        shape.height = height;
        shape.rotate = rotate;
        shape.defaultRotate = defaultRotate;
        shape.fill = fill;
        if(!!hidden) {
            shape.hidden = hidden;
        }
        return shape;
    }

    getNextRotate(deg: number, direction: string, maxRotation: number): number {
        let newDeg = (direction === "left" ? this.rotate - deg : (direction === "right" ? this.rotate + deg : this.rotate));
        newDeg = ((this.rotate > 0 && this.rotate < deg) && newDeg < 0 ? 0 : (newDeg < 0 ? 360 + newDeg : (newDeg >= 360 ? 0 : newDeg)));
        
        let tempDefaultRotate = (this.defaultRotate === 0 && newDeg > this.defaultRotate + maxRotation + deg ? 360 : this.defaultRotate);
        
        if((direction === "right" && newDeg <= tempDefaultRotate + maxRotation) || (direction === "left" && newDeg >= tempDefaultRotate - maxRotation)) {
            return newDeg;
        } else {
            return this.rotate;
        }
    }
}
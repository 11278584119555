import React from 'react';

interface ITriangle_08 {
    fill: string;
    id?: string;
}

const Triangle_08: React.FC<ITriangle_08> = ({ fill, id }) => {
  return (
    <svg width="366" height="302" viewBox="0 0 366 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_513_1821" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="366" height="302">
    <rect width="6" height="302" fill="black"/>
    <rect x="108" width="6" height="302" fill="black"/>
    <rect x="216" width="6" height="302" fill="black"/>
    <rect x="54" width="6" height="302" fill="black"/>
    <rect x="162" width="6" height="302" fill="black"/>
    <rect x="270" width="6" height="302" fill="black"/>
    <rect x="18" width="6" height="302" fill="black"/>
    <rect x="126" width="6" height="302" fill="black"/>
    <rect x="234" width="6" height="302" fill="black"/>
    <rect x="72" width="6" height="302" fill="black"/>
    <rect x="180" width="6" height="302" fill="black"/>
    <rect x="288" width="6" height="302" fill="black"/>
    <rect x="324" width="6" height="302" fill="black"/>
    <rect x="36" width="6" height="302" fill="black"/>
    <rect x="144" width="6" height="302" fill="black"/>
    <rect x="252" width="6" height="302" fill="black"/>
    <rect x="90" width="6" height="302" fill="black"/>
    <rect x="198" width="6" height="302" fill="black"/>
    <rect x="306" width="6" height="302" fill="black"/>
    <rect x="342" width="6" height="302" fill="black"/>
    <rect x="360" width="6" height="302" fill="black"/>
    </mask>
    <g mask="url(#mask0_513_1821)">
    <path d="M183 0L366 302H0L183 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Triangle_08;

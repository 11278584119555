import React from 'react';

interface IInvertedTriangle_08 {
    fill: string;
    id?: string;
}

const InvertedTriangle_08: React.FC<IInvertedTriangle_08> = ({ fill, id }) => {
  return (
    <svg width="366" height="302" viewBox="0 0 366 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_515_2145" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="366" height="302">
    <rect x="366" y="302" width="6" height="302" transform="rotate(-180 366 302)" fill="black"/>
    <rect x="258" y="302" width="6" height="302" transform="rotate(-180 258 302)" fill="black"/>
    <rect x="150" y="302" width="6" height="302" transform="rotate(-180 150 302)" fill="black"/>
    <rect x="312" y="302" width="6" height="302" transform="rotate(-180 312 302)" fill="black"/>
    <rect x="204" y="302" width="6" height="302" transform="rotate(-180 204 302)" fill="black"/>
    <rect x="96" y="302" width="6" height="302" transform="rotate(-180 96 302)" fill="black"/>
    <rect x="348" y="302" width="6" height="302" transform="rotate(-180 348 302)" fill="black"/>
    <rect x="240" y="302" width="6" height="302" transform="rotate(-180 240 302)" fill="black"/>
    <rect x="132" y="302" width="6" height="302" transform="rotate(-180 132 302)" fill="black"/>
    <rect x="294" y="302" width="6" height="302" transform="rotate(-180 294 302)" fill="black"/>
    <rect x="186" y="302" width="6" height="302" transform="rotate(-180 186 302)" fill="black"/>
    <rect x="78" y="302" width="6" height="302" transform="rotate(-180 78 302)" fill="black"/>
    <rect x="42" y="302" width="6" height="302" transform="rotate(-180 42 302)" fill="black"/>
    <rect x="330" y="302" width="6" height="302" transform="rotate(-180 330 302)" fill="black"/>
    <rect x="222" y="302" width="6" height="302" transform="rotate(-180 222 302)" fill="black"/>
    <rect x="114" y="302" width="6" height="302" transform="rotate(-180 114 302)" fill="black"/>
    <rect x="276" y="302" width="6" height="302" transform="rotate(-180 276 302)" fill="black"/>
    <rect x="168" y="302" width="6" height="302" transform="rotate(-180 168 302)" fill="black"/>
    <rect x="60" y="302" width="6" height="302" transform="rotate(-180 60 302)" fill="black"/>
    <rect x="24" y="302" width="6" height="302" transform="rotate(-180 24 302)" fill="black"/>
    <rect x="6" y="302" width="6" height="302" transform="rotate(-180 6 302)" fill="black"/>
    </mask>
    <g mask="url(#mask0_515_2145)">
    <path d="M183 302L9.62902e-06 -3.22352e-05L366 -2.38419e-07L183 302Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangle_08;

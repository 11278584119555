import React from 'react';

interface ICircleSmall_08 {
  fill: string;
  id?: string;
}

const CircleSmall_08: React.FC<ICircleSmall_08> = ({ fill, id }) => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_517_2402" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
    <rect x="90" y="-159" width="6" height="528" fill="black"/>
    <rect x="18" y="-159" width="6" height="528" fill="black"/>
    <rect x="126" y="-159" width="6" height="528" fill="black"/>
    <rect x="54" y="-159" width="6" height="528" fill="black"/>
    <rect x="108" y="-159" width="6" height="528" fill="black"/>
    <rect x="36" y="-159" width="6" height="528" fill="black"/>
    <rect y="-159" width="6" height="528" fill="black"/>
    <rect x="144" y="-159" width="6" height="528" fill="black"/>
    <rect x="72" y="-159" width="6" height="528" fill="black"/>
    </mask>
    <g mask="url(#mask0_517_2402)">
    <circle cx="75" cy="75" r="75" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default CircleSmall_08;

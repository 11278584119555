import './CustomColorInput.css';

interface ICustomColorInputParams {
    onColorChange: (color: string) => void;
}

export function CustomColorInput(params: ICustomColorInputParams) {
    const spectrumImage = require(`./../../../assets/img/spectrum-no-shadow.png`);
    
    return <div className="custom-color-input__wrapper">
        <img className="custom-color-input__image" src={spectrumImage.default} alt="Color spectrum" />
        <input className="custom-color-input" type="color" title="Custom color"
                onChange={(event) => { params.onColorChange(event.target.value); }} />
    </div>;
}

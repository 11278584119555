import React from 'react';

interface IEllipseSmall_15 {
    fill: string;
    id?: string;
}

const EllipseSmall_15: React.FC<IEllipseSmall_15> = ({ fill, id }) => {
  return (
    <svg width="228" height="107" viewBox="0 0 228 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_3184" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="228" height="107">
    <rect y="-927" width="12" height="1961" fill="black"/>
    <rect x="36" y="-927" width="12" height="1961" fill="black"/>
    <rect x="72" y="-927" width="12" height="1961" fill="black"/>
    <rect x="108" y="-927" width="12" height="1961" fill="black"/>
    <rect x="144" y="-927" width="12" height="1961" fill="black"/>
    <rect x="180" y="-927" width="12" height="1961" fill="black"/>
    <rect x="216" y="-927" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_3184)">
    <ellipse cx="114" cy="53.5" rx="114" ry="53.5" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default EllipseSmall_15;

import React from 'react';

interface ITriangle_02 {
    fill: string;
    id?: string;
}

const Triangle_02: React.FC<ITriangle_02> = ({ fill, id }) => {
  return (
    <svg width="366" height="302" viewBox="0 0 366 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_519_613" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="366" height="302">
    <rect x="80" y="-559" width="2" height="1426" fill="black"/>
    <rect x="32" y="-559" width="2" height="1426" fill="black"/>
    <rect x="128" y="-559" width="2" height="1426" fill="black"/>
    <rect x="8" y="-559" width="2" height="1426" fill="black"/>
    <rect x="104" y="-559" width="2" height="1426" fill="black"/>
    <rect x="56" y="-559" width="2" height="1426" fill="black"/>
    <rect x="152" y="-559" width="2" height="1426" fill="black"/>
    <rect x="92" y="-559" width="2" height="1426" fill="black"/>
    <rect x="44" y="-559" width="2" height="1426" fill="black"/>
    <rect x="140" y="-559" width="2" height="1426" fill="black"/>
    <rect x="20" y="-559" width="2" height="1426" fill="black"/>
    <rect x="116" y="-559" width="2" height="1426" fill="black"/>
    <rect x="68" y="-559" width="2" height="1426" fill="black"/>
    <rect x="164" y="-559" width="2" height="1426" fill="black"/>
    <rect x="86" y="-559" width="2" height="1426" fill="black"/>
    <rect x="38" y="-559" width="2" height="1426" fill="black"/>
    <rect x="134" y="-559" width="2" height="1426" fill="black"/>
    <rect x="14" y="-559" width="2" height="1426" fill="black"/>
    <rect x="110" y="-559" width="2" height="1426" fill="black"/>
    <rect x="62" y="-559" width="2" height="1426" fill="black"/>
    <rect x="158" y="-559" width="2" height="1426" fill="black"/>
    <rect x="2" y="-559" width="2" height="1426" fill="black"/>
    <rect x="98" y="-559" width="2" height="1426" fill="black"/>
    <rect x="50" y="-559" width="2" height="1426" fill="black"/>
    <rect x="146" y="-559" width="2" height="1426" fill="black"/>
    <rect x="26" y="-559" width="2" height="1426" fill="black"/>
    <rect x="122" y="-559" width="2" height="1426" fill="black"/>
    <rect x="74" y="-559" width="2" height="1426" fill="black"/>
    <rect x="170" y="-559" width="2" height="1426" fill="black"/>
    <rect x="176" y="-559" width="2" height="1426" fill="black"/>
    <rect x="272" y="-559" width="2" height="1426" fill="black"/>
    <rect x="224" y="-559" width="2" height="1426" fill="black"/>
    <rect x="320" y="-559" width="2" height="1426" fill="black"/>
    <rect x="200" y="-559" width="2" height="1426" fill="black"/>
    <rect x="296" y="-559" width="2" height="1426" fill="black"/>
    <rect x="248" y="-559" width="2" height="1426" fill="black"/>
    <rect x="344" y="-559" width="2" height="1426" fill="black"/>
    <rect x="188" y="-559" width="2" height="1426" fill="black"/>
    <rect x="284" y="-559" width="2" height="1426" fill="black"/>
    <rect x="236" y="-559" width="2" height="1426" fill="black"/>
    <rect x="332" y="-559" width="2" height="1426" fill="black"/>
    <rect x="212" y="-559" width="2" height="1426" fill="black"/>
    <rect x="308" y="-559" width="2" height="1426" fill="black"/>
    <rect x="260" y="-559" width="2" height="1426" fill="black"/>
    <rect x="356" y="-559" width="2" height="1426" fill="black"/>
    <rect x="182" y="-559" width="2" height="1426" fill="black"/>
    <rect x="278" y="-559" width="2" height="1426" fill="black"/>
    <rect x="230" y="-559" width="2" height="1426" fill="black"/>
    <rect x="326" y="-559" width="2" height="1426" fill="black"/>
    <rect x="206" y="-559" width="2" height="1426" fill="black"/>
    <rect x="302" y="-559" width="2" height="1426" fill="black"/>
    <rect x="254" y="-559" width="2" height="1426" fill="black"/>
    <rect x="350" y="-559" width="2" height="1426" fill="black"/>
    <rect x="194" y="-559" width="2" height="1426" fill="black"/>
    <rect x="290" y="-559" width="2" height="1426" fill="black"/>
    <rect x="242" y="-559" width="2" height="1426" fill="black"/>
    <rect x="338" y="-559" width="2" height="1426" fill="black"/>
    <rect x="218" y="-559" width="2" height="1426" fill="black"/>
    <rect x="314" y="-559" width="2" height="1426" fill="black"/>
    <rect x="266" y="-559" width="2" height="1426" fill="black"/>
    <rect x="362" y="-559" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_519_613)">
    <path d="M183 0L366 302H0L183 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Triangle_02;

import React from 'react';

interface ITriangle_10 {
    fill: string;
    id?: string;
}

const Triangle_10: React.FC<ITriangle_10> = ({ fill, id }) => {
  return (
    <svg width="392" height="302" viewBox="0 0 392 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2055" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="392" height="302">
    <rect y="-568" width="8" height="1437" fill="black"/>
    <rect x="24" y="-568" width="8" height="1437" fill="black"/>
    <rect x="48" y="-568" width="8" height="1437" fill="black"/>
    <rect x="72" y="-568" width="8" height="1437" fill="black"/>
    <rect x="96" y="-568" width="8" height="1437" fill="black"/>
    <rect x="120" y="-568" width="8" height="1437" fill="black"/>
    <rect x="144" y="-568" width="8" height="1437" fill="black"/>
    <rect x="168" y="-568" width="8" height="1437" fill="black"/>
    <rect x="192" y="-568" width="8" height="1437" fill="black"/>
    <rect x="216" y="-568" width="8" height="1437" fill="black"/>
    <rect x="240" y="-568" width="8" height="1437" fill="black"/>
    <rect x="264" y="-568" width="8" height="1437" fill="black"/>
    <rect x="288" y="-568" width="8" height="1437" fill="black"/>
    <rect x="312" y="-568" width="8" height="1437" fill="black"/>
    <rect x="336" y="-568" width="8" height="1437" fill="black"/>
    <rect x="360" y="-568" width="8" height="1437" fill="black"/>
    <rect x="384" y="-568" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2055)">
    <path d="M196 0L392 302H0L196 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Triangle_10;

import React, { useState, useEffect } from 'react';
import { IWidthButtonParams } from "./IWidthButtonParams";
import { useTranslation } from 'react-i18next';

export function WidthButton(params: IWidthButtonParams) {
    const { t } = useTranslation();
    
    const imageFiles = [
        'stripeWidth1.svg',
        'stripeWidth2.svg',
        'stripeWidth3.svg',
        'stripeWidth4.svg',
        'stripeWidth5.svg'
    ];

    const [currentImage, setCurrentImage] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setCurrentImage(0);
    };

    useEffect(() => {
        let interval: any;

        if (isHovered) {
            interval = setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % imageFiles.length);
            }, 100);
        } else {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isHovered]);

    return (
        <div
            className={`width-button ${params.class}`}
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
            onClick={() => { params.onColorChanged(); }}
            style={{ display: 'inline-block' }}
        >
            <img
                src={require(`./../../../assets/img/icons/stripeWidth/${imageFiles[currentImage]}`).default}
                alt={t("stripesColor")}
                title={t("stripesColor")}
                style={{ marginRight: '12px', maxWidth: '60px', maxHeight: '50px', marginTop: '8px' }}
            />
        </div>
    );
}
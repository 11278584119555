import React from 'react';

interface ICircle_15 {
    fill: string;
    id?: string;
}

const Circle_15: React.FC<ICircle_15> = ({ fill, id }) => {
  return (
    <svg width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_2861" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="294" height="294">
    <rect x="-3" y="-834" width="12" height="1961" fill="black"/>
    <rect x="33" y="-834" width="12" height="1961" fill="black"/>
    <rect x="69" y="-834" width="12" height="1961" fill="black"/>
    <rect x="105" y="-834" width="12" height="1961" fill="black"/>
    <rect x="141" y="-834" width="12" height="1961" fill="black"/>
    <rect x="177" y="-834" width="12" height="1961" fill="black"/>
    <rect x="213" y="-834" width="12" height="1961" fill="black"/>
    <rect x="249" y="-834" width="12" height="1961" fill="black"/>
    <rect x="285" y="-834" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_2861)">
    <circle cx="147" cy="147" r="147" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Circle_15;

import React from 'react';

interface IRectangleSmall_08 {
    fill: string;
    id?: string;
}

const RectangleSmall_08: React.FC<IRectangleSmall_08> = ({ fill, id }) => {
  return (
    <svg width="222" height="111" viewBox="0 0 222 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_516_2266" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="222" height="111">
    <rect x="54" y="-44" width="6" height="200" fill="black"/>
    <rect x="126" y="-44" width="6" height="200" fill="black"/>
    <rect x="198" y="-44" width="6" height="200" fill="black"/>
    <rect x="72" y="-44" width="6" height="200" fill="black"/>
    <rect y="-44" width="6" height="200" fill="black"/>
    <rect x="144" y="-44" width="6" height="200" fill="black"/>
    <rect x="216" y="-44" width="6" height="200" fill="black"/>
    <rect x="90" y="-44" width="6" height="200" fill="black"/>
    <rect x="18" y="-44" width="6" height="200" fill="black"/>
    <rect x="162" y="-44" width="6" height="200" fill="black"/>
    <rect x="108" y="-44" width="6" height="200" fill="black"/>
    <rect x="36" y="-44" width="6" height="200" fill="black"/>
    <rect x="180" y="-44" width="6" height="200" fill="black"/>
    </mask>
    <g mask="url(#mask0_516_2266)">
    <rect width="222" height="111" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RectangleSmall_08;

import { useTranslation } from 'react-i18next';
import './Header.css';
import { IHeaderParams } from './IHeaderParams';

function Header(params: IHeaderParams) {
    const { t } = useTranslation();
    const timerMin: number = Math.floor(params.timer / 60);
    const timerSec: number = params.timer % 60;

    return <header className={`header ${params.print ? 'header--print' : ''}`}>
        <p className="header__text">
            {(params.text.length > params.textMaxLength ? `${params.text.substring(0, params.textMaxLength)} (...)` : t("introText"))}
        </p>
    </header>
}

export default Header;
import React from 'react';

interface IInvertedTriangle_05 {
    fill: string;
    id?: string;
}

const InvertedTriangle_05: React.FC<IInvertedTriangle_05> = ({ fill, id }) => {
  return (
    <svg width="366" height="302" viewBox="0 0 366 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1251" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="366" height="302">
    <rect x="365" y="787" width="4" height="1272" transform="rotate(-180 365 787)" fill="black"/>
    <rect x="353" y="787" width="4" height="1272" transform="rotate(-180 353 787)" fill="black"/>
    <rect x="341" y="787" width="4" height="1272" transform="rotate(-180 341 787)" fill="black"/>
    <rect x="329" y="787" width="4" height="1272" transform="rotate(-180 329 787)" fill="black"/>
    <rect x="317" y="787" width="4" height="1272" transform="rotate(-180 317 787)" fill="black"/>
    <rect x="305" y="787" width="4" height="1272" transform="rotate(-180 305 787)" fill="black"/>
    <rect x="293" y="787" width="4" height="1272" transform="rotate(-180 293 787)" fill="black"/>
    <rect x="281" y="787" width="4" height="1272" transform="rotate(-180 281 787)" fill="black"/>
    <rect x="269" y="787" width="4" height="1272" transform="rotate(-180 269 787)" fill="black"/>
    <rect x="257" y="787" width="4" height="1272" transform="rotate(-180 257 787)" fill="black"/>
    <rect x="245" y="787" width="4" height="1272" transform="rotate(-180 245 787)" fill="black"/>
    <rect x="233" y="787" width="4" height="1272" transform="rotate(-180 233 787)" fill="black"/>
    <rect x="221" y="787" width="4" height="1272" transform="rotate(-180 221 787)" fill="black"/>
    <rect x="209" y="787" width="4" height="1272" transform="rotate(-180 209 787)" fill="black"/>
    <rect x="197" y="787" width="4" height="1272" transform="rotate(-180 197 787)" fill="black"/>
    <rect x="185" y="787" width="4" height="1272" transform="rotate(-180 185 787)" fill="black"/>
    <rect x="173" y="787" width="4" height="1272" transform="rotate(-180 173 787)" fill="black"/>
    <rect x="161" y="787" width="4" height="1272" transform="rotate(-180 161 787)" fill="black"/>
    <rect x="149" y="787" width="4" height="1272" transform="rotate(-180 149 787)" fill="black"/>
    <rect x="137" y="787" width="4" height="1272" transform="rotate(-180 137 787)" fill="black"/>
    <rect x="125" y="787" width="4" height="1272" transform="rotate(-180 125 787)" fill="black"/>
    <rect x="113" y="787" width="4" height="1272" transform="rotate(-180 113 787)" fill="black"/>
    <rect x="101" y="787" width="4" height="1272" transform="rotate(-180 101 787)" fill="black"/>
    <rect x="89" y="787" width="4" height="1272" transform="rotate(-180 89 787)" fill="black"/>
    <rect x="77" y="787" width="4" height="1272" transform="rotate(-180 77 787)" fill="black"/>
    <rect x="65" y="787" width="4" height="1272" transform="rotate(-180 65 787)" fill="black"/>
    <rect x="53" y="787" width="4" height="1272" transform="rotate(-180 53 787)" fill="black"/>
    <rect x="41" y="787" width="4" height="1272" transform="rotate(-180 41 787)" fill="black"/>
    <rect x="29" y="787" width="4" height="1272" transform="rotate(-180 29 787)" fill="black"/>
    <rect x="17" y="787" width="4" height="1272" transform="rotate(-180 17 787)" fill="black"/>
    <rect x="5" y="787" width="4" height="1272" transform="rotate(-180 5 787)" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1251)">
    <path d="M183 302L3.64674e-05 -3.00894e-05L366 1.90735e-06L183 302Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangle_05;

import React from 'react';

interface IInvertedTriangle_02 {
    fill: string;
    id?: string;
}

const InvertedTriangle_02: React.FC<IInvertedTriangle_02> = ({ fill, id }) => {
  return (
    <svg width="366" height="302" viewBox="0 0 366 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_519_613" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="366" height="302">
    <rect x="286" y="861" width="2" height="1426" transform="rotate(-180 286 861)" fill="black"/>
    <rect x="334" y="861" width="2" height="1426" transform="rotate(-180 334 861)" fill="black"/>
    <rect x="238" y="861" width="2" height="1426" transform="rotate(-180 238 861)" fill="black"/>
    <rect x="358" y="861" width="2" height="1426" transform="rotate(-180 358 861)" fill="black"/>
    <rect x="262" y="861" width="2" height="1426" transform="rotate(-180 262 861)" fill="black"/>
    <rect x="310" y="861" width="2" height="1426" transform="rotate(-180 310 861)" fill="black"/>
    <rect x="214" y="861" width="2" height="1426" transform="rotate(-180 214 861)" fill="black"/>
    <rect x="274" y="861" width="2" height="1426" transform="rotate(-180 274 861)" fill="black"/>
    <rect x="322" y="861" width="2" height="1426" transform="rotate(-180 322 861)" fill="black"/>
    <rect x="226" y="861" width="2" height="1426" transform="rotate(-180 226 861)" fill="black"/>
    <rect x="346" y="861" width="2" height="1426" transform="rotate(-180 346 861)" fill="black"/>
    <rect x="250" y="861" width="2" height="1426" transform="rotate(-180 250 861)" fill="black"/>
    <rect x="298" y="861" width="2" height="1426" transform="rotate(-180 298 861)" fill="black"/>
    <rect x="202" y="861" width="2" height="1426" transform="rotate(-180 202 861)" fill="black"/>
    <rect x="280" y="861" width="2" height="1426" transform="rotate(-180 280 861)" fill="black"/>
    <rect x="328" y="861" width="2" height="1426" transform="rotate(-180 328 861)" fill="black"/>
    <rect x="232" y="861" width="2" height="1426" transform="rotate(-180 232 861)" fill="black"/>
    <rect x="352" y="861" width="2" height="1426" transform="rotate(-180 352 861)" fill="black"/>
    <rect x="256" y="861" width="2" height="1426" transform="rotate(-180 256 861)" fill="black"/>
    <rect x="304" y="861" width="2" height="1426" transform="rotate(-180 304 861)" fill="black"/>
    <rect x="208" y="861" width="2" height="1426" transform="rotate(-180 208 861)" fill="black"/>
    <rect x="364" y="861" width="2" height="1426" transform="rotate(-180 364 861)" fill="black"/>
    <rect x="268" y="861" width="2" height="1426" transform="rotate(-180 268 861)" fill="black"/>
    <rect x="316" y="861" width="2" height="1426" transform="rotate(-180 316 861)" fill="black"/>
    <rect x="220" y="861" width="2" height="1426" transform="rotate(-180 220 861)" fill="black"/>
    <rect x="340" y="861" width="2" height="1426" transform="rotate(-180 340 861)" fill="black"/>
    <rect x="244" y="861" width="2" height="1426" transform="rotate(-180 244 861)" fill="black"/>
    <rect x="292" y="861" width="2" height="1426" transform="rotate(-180 292 861)" fill="black"/>
    <rect x="196" y="861" width="2" height="1426" transform="rotate(-180 196 861)" fill="black"/>
    <rect x="190" y="861" width="2" height="1426" transform="rotate(-180 190 861)" fill="black"/>
    <rect x="93.9999" y="861" width="2" height="1426" transform="rotate(-180 93.9999 861)" fill="black"/>
    <rect x="142" y="861" width="2" height="1426" transform="rotate(-180 142 861)" fill="black"/>
    <rect x="45.9999" y="861" width="2" height="1426" transform="rotate(-180 45.9999 861)" fill="black"/>
    <rect x="166" y="861" width="2" height="1426" transform="rotate(-180 166 861)" fill="black"/>
    <rect x="69.9999" y="861" width="2" height="1426" transform="rotate(-180 69.9999 861)" fill="black"/>
    <rect x="118" y="861" width="2" height="1426" transform="rotate(-180 118 861)" fill="black"/>
    <rect x="21.9999" y="861" width="2" height="1426" transform="rotate(-180 21.9999 861)" fill="black"/>
    <rect x="178" y="861" width="2" height="1426" transform="rotate(-180 178 861)" fill="black"/>
    <rect x="81.9999" y="861" width="2" height="1426" transform="rotate(-180 81.9999 861)" fill="black"/>
    <rect x="130" y="861" width="2" height="1426" transform="rotate(-180 130 861)" fill="black"/>
    <rect x="33.9999" y="861" width="2" height="1426" transform="rotate(-180 33.9999 861)" fill="black"/>
    <rect x="154" y="861" width="2" height="1426" transform="rotate(-180 154 861)" fill="black"/>
    <rect x="57.9999" y="861" width="2" height="1426" transform="rotate(-180 57.9999 861)" fill="black"/>
    <rect x="106" y="861" width="2" height="1426" transform="rotate(-180 106 861)" fill="black"/>
    <rect x="9.99994" y="861" width="2" height="1426" transform="rotate(-180 9.99994 861)" fill="black"/>
    <rect x="184" y="861" width="2" height="1426" transform="rotate(-180 184 861)" fill="black"/>
    <rect x="87.9999" y="861" width="2" height="1426" transform="rotate(-180 87.9999 861)" fill="black"/>
    <rect x="136" y="861" width="2" height="1426" transform="rotate(-180 136 861)" fill="black"/>
    <rect x="39.9999" y="861" width="2" height="1426" transform="rotate(-180 39.9999 861)" fill="black"/>
    <rect x="160" y="861" width="2" height="1426" transform="rotate(-180 160 861)" fill="black"/>
    <rect x="63.9999" y="861" width="2" height="1426" transform="rotate(-180 63.9999 861)" fill="black"/>
    <rect x="112" y="861" width="2" height="1426" transform="rotate(-180 112 861)" fill="black"/>
    <rect x="15.9999" y="861" width="2" height="1426" transform="rotate(-180 15.9999 861)" fill="black"/>
    <rect x="172" y="861" width="2" height="1426" transform="rotate(-180 172 861)" fill="black"/>
    <rect x="75.9999" y="861" width="2" height="1426" transform="rotate(-180 75.9999 861)" fill="black"/>
    <rect x="124" y="861" width="2" height="1426" transform="rotate(-180 124 861)" fill="black"/>
    <rect x="27.9999" y="861" width="2" height="1426" transform="rotate(-180 27.9999 861)" fill="black"/>
    <rect x="148" y="861" width="2" height="1426" transform="rotate(-180 148 861)" fill="black"/>
    <rect x="51.9999" y="861" width="2" height="1426" transform="rotate(-180 51.9999 861)" fill="black"/>
    <rect x="99.9999" y="861" width="2" height="1426" transform="rotate(-180 99.9999 861)" fill="black"/>
    <rect x="3.99994" y="861" width="2" height="1426" transform="rotate(-180 3.99994 861)" fill="black"/>
    </mask>
    <g mask="url(#mask0_519_613)">
    <path d="M183 302L1.79937e-05 -3.54762e-05L366 -3.47942e-06L183 302Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangle_02;

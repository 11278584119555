import React from 'react';

interface ITriangle {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const Triangle: React.FC<ITriangle> = ({ fill, id, width, height }) => {
  return (
    <svg width="80" height="70" viewBox="0 0 80 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7_120" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="70">
    <rect x="79.759" y="69.8129" width="0.981705" height="69.7527" transform="rotate(-180 79.759 69.8129)" fill="black"/>
    <rect x="75.8322" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 75.8322 69.813)" fill="black"/>
    <rect x="71.9054" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 71.9054 69.813)" fill="black"/>
    <rect x="67.9785" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 67.9785 69.813)" fill="black"/>
    <rect x="64.0518" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 64.0518 69.813)" fill="black"/>
    <rect x="60.1249" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 60.1249 69.813)" fill="black"/>
    <rect x="56.1981" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 56.1981 69.813)" fill="black"/>
    <rect x="52.2712" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 52.2712 69.813)" fill="black"/>
    <rect x="24.7836" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 24.7836 69.813)" fill="black"/>
    <rect x="48.3445" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 48.3445 69.813)" fill="black"/>
    <rect x="20.8568" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 20.8568 69.813)" fill="black"/>
    <rect x="44.4176" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 44.4176 69.813)" fill="black"/>
    <rect x="16.9301" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 16.9301 69.813)" fill="black"/>
    <rect x="40.4907" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 40.4907 69.813)" fill="black"/>
    <rect x="13.0032" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 13.0032 69.813)" fill="black"/>
    <rect x="32.6371" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 32.6371 69.813)" fill="black"/>
    <rect x="5.14954" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 5.14954 69.813)" fill="black"/>
    <rect x="36.564" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 36.564 69.813)" fill="black"/>
    <rect x="9.07629" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 9.07629 69.813)" fill="black"/>
    <rect x="28.7104" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 28.7104 69.813)" fill="black"/>
    <rect x="1.22266" y="69.813" width="0.981705" height="69.7527" transform="rotate(-180 1.22266 69.813)" fill="black"/>
    </mask>
    <g mask="url(#mask0_7_120)">
    <path d="M39.9999 0.0602722L80.473 70.1617H-0.473164L39.9999 0.0602722Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Triangle;

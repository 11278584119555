import React from 'react';

interface IEllipseSmall_02 {
    fill: string;
    id?: string;
}

const EllipseSmall_02: React.FC<IEllipseSmall_02> = ({ fill, id }) => {
  return (
    <svg width="212" height="107" viewBox="0 0 212 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1069" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="212" height="107">
    <rect x="12" y="-659" width="2" height="1426" fill="black"/>
    <rect x="60" y="-659" width="2" height="1426" fill="black"/>
    <rect x="36" y="-659" width="2" height="1426" fill="black"/>
    <rect x="84" y="-659" width="2" height="1426" fill="black"/>
    <rect x="24" y="-659" width="2" height="1426" fill="black"/>
    <rect x="72" y="-659" width="2" height="1426" fill="black"/>
    <rect x="48" y="-659" width="2" height="1426" fill="black"/>
    <rect y="-659" width="2" height="1426" fill="black"/>
    <rect x="96" y="-659" width="2" height="1426" fill="black"/>
    <rect x="18" y="-659" width="2" height="1426" fill="black"/>
    <rect x="66" y="-659" width="2" height="1426" fill="black"/>
    <rect x="42" y="-659" width="2" height="1426" fill="black"/>
    <rect x="90" y="-659" width="2" height="1426" fill="black"/>
    <rect x="30" y="-659" width="2" height="1426" fill="black"/>
    <rect x="78" y="-659" width="2" height="1426" fill="black"/>
    <rect x="54" y="-659" width="2" height="1426" fill="black"/>
    <rect x="6" y="-659" width="2" height="1426" fill="black"/>
    <rect x="102" y="-659" width="2" height="1426" fill="black"/>
    <rect x="108" y="-659" width="2" height="1426" fill="black"/>
    <rect x="204" y="-659" width="2" height="1426" fill="black"/>
    <rect x="156" y="-659" width="2" height="1426" fill="black"/>
    <rect x="132" y="-659" width="2" height="1426" fill="black"/>
    <rect x="180" y="-659" width="2" height="1426" fill="black"/>
    <rect x="120" y="-659" width="2" height="1426" fill="black"/>
    <rect x="168" y="-659" width="2" height="1426" fill="black"/>
    <rect x="144" y="-659" width="2" height="1426" fill="black"/>
    <rect x="192" y="-659" width="2" height="1426" fill="black"/>
    <rect x="114" y="-659" width="2" height="1426" fill="black"/>
    <rect x="210" y="-659" width="2" height="1426" fill="black"/>
    <rect x="162" y="-659" width="2" height="1426" fill="black"/>
    <rect x="138" y="-659" width="2" height="1426" fill="black"/>
    <rect x="186" y="-659" width="2" height="1426" fill="black"/>
    <rect x="126" y="-659" width="2" height="1426" fill="black"/>
    <rect x="174" y="-659" width="2" height="1426" fill="black"/>
    <rect x="150" y="-659" width="2" height="1426" fill="black"/>
    <rect x="198" y="-659" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1069)">
    <ellipse cx="106" cy="53.5" rx="106" ry="53.5" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default EllipseSmall_02;

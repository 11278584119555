import React from 'react';

interface ISquare_05 {
    fill: string;
    id?: string;
}

const Square_05: React.FC<ISquare_05> = ({ fill, id }) => {
  return (
    <svg width="292" height="292" viewBox="0 0 292 292" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1552" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="292" height="292">
    <rect y="-490" width="4" height="1272" fill="black"/>
    <rect x="12" y="-490" width="4" height="1272" fill="black"/>
    <rect x="24" y="-490" width="4" height="1272" fill="black"/>
    <rect x="36" y="-490" width="4" height="1272" fill="black"/>
    <rect x="48" y="-490" width="4" height="1272" fill="black"/>
    <rect x="60" y="-490" width="4" height="1272" fill="black"/>
    <rect x="72" y="-490" width="4" height="1272" fill="black"/>
    <rect x="84" y="-490" width="4" height="1272" fill="black"/>
    <rect x="96" y="-490" width="4" height="1272" fill="black"/>
    <rect x="108" y="-490" width="4" height="1272" fill="black"/>
    <rect x="120" y="-490" width="4" height="1272" fill="black"/>
    <rect x="132" y="-490" width="4" height="1272" fill="black"/>
    <rect x="144" y="-490" width="4" height="1272" fill="black"/>
    <rect x="156" y="-490" width="4" height="1272" fill="black"/>
    <rect x="168" y="-490" width="4" height="1272" fill="black"/>
    <rect x="180" y="-490" width="4" height="1272" fill="black"/>
    <rect x="192" y="-490" width="4" height="1272" fill="black"/>
    <rect x="204" y="-490" width="4" height="1272" fill="black"/>
    <rect x="216" y="-490" width="4" height="1272" fill="black"/>
    <rect x="228" y="-490" width="4" height="1272" fill="black"/>
    <rect x="240" y="-490" width="4" height="1272" fill="black"/>
    <rect x="252" y="-490" width="4" height="1272" fill="black"/>
    <rect x="264" y="-490" width="4" height="1272" fill="black"/>
    <rect x="276" y="-490" width="4" height="1272" fill="black"/>
    <rect x="288" y="-490" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1552)">
    <rect width="292" height="292" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Square_05;

import React from 'react';

interface IRhombusSmall {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const RhombusSmall: React.FC<IRhombusSmall> = ({ fill, id, width, height }) => {
  return (
    <svg width="102" height="100" viewBox="0 0 102 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12_744" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="102" height="100">
    <rect x="0.00012207" width="1.44755" height="99.1571" fill="black"/>
    <rect x="26.056" width="1.44755" height="99.1571" fill="black"/>
    <rect x="13.0281" width="1.44755" height="99.1571" fill="black"/>
    <rect x="39.084" width="1.44755" height="99.1571" fill="black"/>
    <rect x="52.1121" width="1.44755" height="99.1571" fill="black"/>
    <rect x="73.825" width="1.44755" height="99.1571" fill="black"/>
    <rect x="91.1957" width="1.44755" height="99.1571" fill="black"/>
    <rect x="5.06641" width="1.44755" height="99.1571" fill="black"/>
    <rect x="30.3986" width="1.44755" height="99.1571" fill="black"/>
    <rect x="17.3706" width="1.44755" height="99.1571" fill="black"/>
    <rect x="43.4265" width="1.44755" height="99.1571" fill="black"/>
    <rect x="65.1398" width="1.44755" height="99.1571" fill="black"/>
    <rect x="56.4545" width="1.44755" height="99.1571" fill="black"/>
    <rect x="78.168" width="1.44755" height="99.1571" fill="black"/>
    <rect x="94.8146" width="1.44755" height="99.1571" fill="black"/>
    <rect x="8.6853" width="1.44755" height="99.1571" fill="black"/>
    <rect x="34.7413" width="1.44755" height="99.1571" fill="black"/>
    <rect x="21.7134" width="1.44755" height="99.1571" fill="black"/>
    <rect x="47.7693" width="1.44755" height="99.1571" fill="black"/>
    <rect x="69.4825" width="1.44755" height="99.1571" fill="black"/>
    <rect x="86.8531" width="1.44755" height="99.1571" fill="black"/>
    <rect x="60.7972" width="1.44755" height="99.1571" fill="black"/>
    <rect x="82.5105" width="1.44755" height="99.1571" fill="black"/>
    <rect x="99.881" width="1.44755" height="99.1571" fill="black"/>
    </mask>
    <g mask="url(#mask0_12_744)">
    <rect x="26.3643" y="49.7714" width="34.3286" height="34.3286" transform="rotate(-45 26.3643 49.7714)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RhombusSmall;

import React from 'react';

interface IRectangleSmall_05 {
    fill: string;
    id?: string;
}

const RectangleSmall_05: React.FC<IRectangleSmall_05> = ({ fill, id }) => {
  return (
    <svg width="224" height="111" viewBox="0 0 224 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1852" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="224" height="111">
    <rect x="2" y="-581" width="4" height="1272" fill="black"/>
    <rect x="14" y="-581" width="4" height="1272" fill="black"/>
    <rect x="26" y="-581" width="4" height="1272" fill="black"/>
    <rect x="38" y="-581" width="4" height="1272" fill="black"/>
    <rect x="50" y="-581" width="4" height="1272" fill="black"/>
    <rect x="62" y="-581" width="4" height="1272" fill="black"/>
    <rect x="74" y="-581" width="4" height="1272" fill="black"/>
    <rect x="86" y="-581" width="4" height="1272" fill="black"/>
    <rect x="98" y="-581" width="4" height="1272" fill="black"/>
    <rect x="110" y="-581" width="4" height="1272" fill="black"/>
    <rect x="122" y="-581" width="4" height="1272" fill="black"/>
    <rect x="134" y="-581" width="4" height="1272" fill="black"/>
    <rect x="146" y="-581" width="4" height="1272" fill="black"/>
    <rect x="158" y="-581" width="4" height="1272" fill="black"/>
    <rect x="170" y="-581" width="4" height="1272" fill="black"/>
    <rect x="182" y="-581" width="4" height="1272" fill="black"/>
    <rect x="194" y="-581" width="4" height="1272" fill="black"/>
    <rect x="206" y="-581" width="4" height="1272" fill="black"/>
    <rect x="218" y="-581" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1852)">
    <rect width="224" height="111" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RectangleSmall_05;

import React from 'react';

interface IRhombus_02 {
    fill: string;
    id?: string;
}

const Rhombus_02: React.FC<IRhombus_02> = ({ fill, id }) => {
  return (
    <svg width="414" height="414" viewBox="0 0 414 414" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_413" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="414" height="414">
    <rect x="14" y="-506" width="2" height="1426" fill="black"/>
    <rect x="110" y="-506" width="2" height="1426" fill="black"/>
    <rect x="62" y="-506" width="2" height="1426" fill="black"/>
    <rect x="158" y="-506" width="2" height="1426" fill="black"/>
    <rect x="38" y="-506" width="2" height="1426" fill="black"/>
    <rect x="134" y="-506" width="2" height="1426" fill="black"/>
    <rect x="86" y="-506" width="2" height="1426" fill="black"/>
    <rect x="182" y="-506" width="2" height="1426" fill="black"/>
    <rect x="26" y="-506" width="2" height="1426" fill="black"/>
    <rect x="122" y="-506" width="2" height="1426" fill="black"/>
    <rect x="74" y="-506" width="2" height="1426" fill="black"/>
    <rect x="170" y="-506" width="2" height="1426" fill="black"/>
    <rect x="50" y="-506" width="2" height="1426" fill="black"/>
    <rect x="146" y="-506" width="2" height="1426" fill="black"/>
    <rect x="98" y="-506" width="2" height="1426" fill="black"/>
    <rect x="2" y="-506" width="2" height="1426" fill="black"/>
    <rect x="194" y="-506" width="2" height="1426" fill="black"/>
    <rect x="20" y="-506" width="2" height="1426" fill="black"/>
    <rect x="116" y="-506" width="2" height="1426" fill="black"/>
    <rect x="68" y="-506" width="2" height="1426" fill="black"/>
    <rect x="164" y="-506" width="2" height="1426" fill="black"/>
    <rect x="44" y="-506" width="2" height="1426" fill="black"/>
    <rect x="140" y="-506" width="2" height="1426" fill="black"/>
    <rect x="92" y="-506" width="2" height="1426" fill="black"/>
    <rect x="188" y="-506" width="2" height="1426" fill="black"/>
    <rect x="32" y="-506" width="2" height="1426" fill="black"/>
    <rect x="128" y="-506" width="2" height="1426" fill="black"/>
    <rect x="80" y="-506" width="2" height="1426" fill="black"/>
    <rect x="176" y="-506" width="2" height="1426" fill="black"/>
    <rect x="56" y="-506" width="2" height="1426" fill="black"/>
    <rect x="152" y="-506" width="2" height="1426" fill="black"/>
    <rect x="104" y="-506" width="2" height="1426" fill="black"/>
    <rect x="8" y="-506" width="2" height="1426" fill="black"/>
    <rect x="200" y="-506" width="2" height="1426" fill="black"/>
    <rect x="206" y="-506" width="2" height="1426" fill="black"/>
    <rect x="398" y="-506" width="2" height="1426" fill="black"/>
    <rect x="302" y="-506" width="2" height="1426" fill="black"/>
    <rect x="254" y="-506" width="2" height="1426" fill="black"/>
    <rect x="350" y="-506" width="2" height="1426" fill="black"/>
    <rect x="230" y="-506" width="2" height="1426" fill="black"/>
    <rect x="326" y="-506" width="2" height="1426" fill="black"/>
    <rect x="278" y="-506" width="2" height="1426" fill="black"/>
    <rect x="374" y="-506" width="2" height="1426" fill="black"/>
    <rect x="218" y="-506" width="2" height="1426" fill="black"/>
    <rect x="410" y="-506" width="2" height="1426" fill="black"/>
    <rect x="314" y="-506" width="2" height="1426" fill="black"/>
    <rect x="266" y="-506" width="2" height="1426" fill="black"/>
    <rect x="362" y="-506" width="2" height="1426" fill="black"/>
    <rect x="242" y="-506" width="2" height="1426" fill="black"/>
    <rect x="338" y="-506" width="2" height="1426" fill="black"/>
    <rect x="290" y="-506" width="2" height="1426" fill="black"/>
    <rect x="386" y="-506" width="2" height="1426" fill="black"/>
    <rect x="212" y="-506" width="2" height="1426" fill="black"/>
    <rect x="404" y="-506" width="2" height="1426" fill="black"/>
    <rect x="308" y="-506" width="2" height="1426" fill="black"/>
    <rect x="260" y="-506" width="2" height="1426" fill="black"/>
    <rect x="356" y="-506" width="2" height="1426" fill="black"/>
    <rect x="236" y="-506" width="2" height="1426" fill="black"/>
    <rect x="332" y="-506" width="2" height="1426" fill="black"/>
    <rect x="284" y="-506" width="2" height="1426" fill="black"/>
    <rect x="380" y="-506" width="2" height="1426" fill="black"/>
    <rect x="224" y="-506" width="2" height="1426" fill="black"/>
    <rect x="320" y="-506" width="2" height="1426" fill="black"/>
    <rect x="272" y="-506" width="2" height="1426" fill="black"/>
    <rect x="368" y="-506" width="2" height="1426" fill="black"/>
    <rect x="248" y="-506" width="2" height="1426" fill="black"/>
    <rect x="344" y="-506" width="2" height="1426" fill="black"/>
    <rect x="296" y="-506" width="2" height="1426" fill="black"/>
    <rect x="392" y="-506" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_413)">
    <rect x="207" width="292.742" height="292.742" transform="rotate(45 207 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rhombus_02;

import React from 'react';

interface IInvertedTriangleSmall_10 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_10: React.FC<IInvertedTriangleSmall_10> = ({ fill, id }) => {
  return (
    <svg width="196" height="156" viewBox="0 0 196 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2129" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="196" height="156">
    <rect x="198" y="797" width="8" height="1437" transform="rotate(-180 198 797)" fill="black"/>
    <rect x="174" y="797" width="8" height="1437" transform="rotate(-180 174 797)" fill="black"/>
    <rect x="150" y="797" width="8" height="1437" transform="rotate(-180 150 797)" fill="black"/>
    <rect x="126" y="797" width="8" height="1437" transform="rotate(-180 126 797)" fill="black"/>
    <rect x="102" y="797" width="8" height="1437" transform="rotate(-180 102 797)" fill="black"/>
    <rect x="78" y="797" width="8" height="1437" transform="rotate(-180 78 797)" fill="black"/>
    <rect x="54" y="797" width="8" height="1437" transform="rotate(-180 54 797)" fill="black"/>
    <rect x="30" y="797" width="8" height="1437" transform="rotate(-180 30 797)" fill="black"/>
    <rect x="6" y="797" width="8" height="1437" transform="rotate(-180 6 797)" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2129)">
    <path d="M98 156L7.21528e-06 -1.28433e-05L196 4.29153e-06L98 156Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangleSmall_10;

import React from 'react';

interface ICircleSmall_15 {
  fill: string;
  id?: string;
}

const CircleSmall_15: React.FC<ICircleSmall_15> = ({ fill, id }) => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_2896" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
    <rect x="-3" y="-906" width="12" height="1961" fill="black"/>
    <rect x="33" y="-906" width="12" height="1961" fill="black"/>
    <rect x="69" y="-906" width="12" height="1961" fill="black"/>
    <rect x="105" y="-906" width="12" height="1961" fill="black"/>
    <rect x="141" y="-906" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_2896)">
    <circle cx="75" cy="75" r="75" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default CircleSmall_15;

import React from 'react';

interface IRhombusSmall_08 {
    fill: string;
    id?: string;
}

const RhombusSmall_08: React.FC<IRhombusSmall_08> = ({ fill, id }) => {
  return (
    <svg width="224" height="224" viewBox="0 0 224 224" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_515_2106" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="224" height="224">
    <rect x="73.0063" y="-188" width="6" height="600" fill="black"/>
    <rect x="217.006" y="-188" width="6" height="600" fill="black"/>
    <rect x="145.006" y="-188" width="6" height="600" fill="black"/>
    <rect x="1.00635" y="-188" width="6" height="600" fill="black"/>
    <rect x="109.006" y="-188" width="6" height="600" fill="black"/>
    <rect x="181.006" y="-188" width="6" height="600" fill="black"/>
    <rect x="37.0063" y="-188" width="6" height="600" fill="black"/>
    <rect x="91.0063" y="-188" width="6" height="600" fill="black"/>
    <rect x="163.006" y="-188" width="6" height="600" fill="black"/>
    <rect x="19.0063" y="-188" width="6" height="600" fill="black"/>
    <rect x="127.006" y="-188" width="6" height="600" fill="black"/>
    <rect x="199.006" y="-188" width="6" height="600" fill="black"/>
    <rect x="55.0063" y="-188" width="6" height="600" fill="black"/>
    </mask>
    <g mask="url(#mask0_515_2106)">
    <rect x="112" width="158.392" height="158.392" transform="rotate(45 112 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RhombusSmall_08;

import React from 'react';

interface IRectangle {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const Rectangle: React.FC<IRectangle> = ({ fill, id, width, height }) => {
  return (
    <svg width="92" height="100" viewBox="0 0 92 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12_1057" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="92" height="100">
    <rect x="30" width="2" height="100" fill="black"/>
    <rect x="12" width="2" height="100" fill="black"/>
    <rect x="48" width="2" height="100" fill="black"/>
    <rect x="66" width="2" height="100" fill="black"/>
    <rect width="2" height="100" fill="black"/>
    <rect x="36" width="2" height="100" fill="black"/>
    <rect x="18" width="2" height="100" fill="black"/>
    <rect x="54" width="2" height="100" fill="black"/>
    <rect x="84" width="2" height="100" fill="black"/>
    <rect x="72" width="2" height="100" fill="black"/>
    <rect x="6" width="2" height="100" fill="black"/>
    <rect x="42" width="2" height="100" fill="black"/>
    <rect x="24" width="2" height="100" fill="black"/>
    <rect x="60" width="2" height="100" fill="black"/>
    <rect x="90" width="2" height="100" fill="black"/>
    <rect x="78" width="2" height="100" fill="black"/>
    </mask>
    <g mask="url(#mask0_12_1057)">
    <rect x="-4" y="27" width="100" height="45" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rectangle;

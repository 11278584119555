import React from 'react';

interface IEllipseSmall_10 {
    fill: string;
    id?: string;
}

const EllipseSmall_10: React.FC<IEllipseSmall_10> = ({ fill, id }) => {
  return (
    <svg width="248" height="107" viewBox="0 0 248 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_2526" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="248" height="107">
    <rect y="-665" width="8" height="1437" fill="black"/>
    <rect x="24" y="-665" width="8" height="1437" fill="black"/>
    <rect x="48" y="-665" width="8" height="1437" fill="black"/>
    <rect x="72" y="-665" width="8" height="1437" fill="black"/>
    <rect x="96" y="-665" width="8" height="1437" fill="black"/>
    <rect x="120" y="-665" width="8" height="1437" fill="black"/>
    <rect x="144" y="-665" width="8" height="1437" fill="black"/>
    <rect x="168" y="-665" width="8" height="1437" fill="black"/>
    <rect x="192" y="-665" width="8" height="1437" fill="black"/>
    <rect x="216" y="-665" width="8" height="1437" fill="black"/>
    <rect x="240" y="-665" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_2526)">
    <ellipse cx="124" cy="53.5" rx="124" ry="53.5" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default EllipseSmall_10;

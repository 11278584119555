import React from 'react';
import { Color } from '../../Color';
import { IColorButtonPaletteParams } from './IColorButtonPaletteParams';
import './ColorButtonPalette.css';
import { CustomColorInput } from '../CustomColorInput/CustomColorInput';
import { useTranslation } from 'react-i18next';

export function ColorButtonPalette(params: IColorButtonPaletteParams) {
    const { t } = useTranslation();
    const colorList: Color[] = [
      new Color(t("white"), "#FFFFFF"),
      new Color(t("black"), "#000000"),
      new Color(t("burntOrange"), "#CB5100"),
      new Color(t("lightGray"), "#C8CDCE"),
      new Color(t("emeraldGreen"), "#009E33"),
      new Color(t("brightGreen"), "#00C300"),
      new Color(t("oliveYellow"), "#A7B001"),
      new Color(t("limeGreen"), "#B5F200"),
      new Color(t("mintGreen"), "#00CF32"),
      new Color(t("royalBlue"), "#0048C2"),
      new Color(t("skyBlue"), "#0098EC"),
      new Color(t("turquoise"), "#00D4CF"),
      new Color(t("paleBlue"), "#8EC8F8"),
      new Color(t("scarletRed"), "#FE1B00"),
      new Color(t("vermilionRed"), "#FE3B00"),
      new Color(t("amberOrange"), "#FF7C00"),
      new Color(t("goldenOrange"), "#FFB613"),
      new Color(t("fuchsiaPink"), "#E400B8"),
      new Color(t("indigoPurple"), "#6A00BC"),
      new Color(t("rustBrown"), "#A14000"),
      new Color(t("crimsonRed"), "#AD251D"),
      new Color(t("lightPink"), "#F5BED6"),
      new Color(t("lavenderPurple"), "#C6A0F6"),
      new Color(t("sunshineYellow"), "#FED400")
    ];

    var colorDivs = colorList.map(x => 
        <div key={x.name} onClick={() => { params.onColorChange(x.value); }} className="color-button-palette__button" style={{ background: x.value }} title={x.name}></div>
    );

    const gradientDecoration = require('./../../../assets/img/gradient.png');
    
    return <div className="color-button-palette__wrapper">
        <img className="color-button-palette__image" src={gradientDecoration.default} alt="Gradient decoration" />
        {colorDivs}
    </div>;
}

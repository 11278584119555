export class ShapeColors {
    colors: string[] = ["#000000", "#000000", "#000000"];
    counter: number = 0;

    constructor(colors : string[]){
        this.colors = colors;
    }

    resetCounter() {
        this.counter = 0;
    }

    next(): string {
        return this.colors[this.counter++ % this.colors.length];
    }
}
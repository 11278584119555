import React from 'react';

interface IRhombus_10 {
    fill: string;
    id?: string;
}

const Rhombus_10: React.FC<IRhombus_10> = ({ fill, id }) => {
  return (
    <svg width="393" height="393" viewBox="0 0 393 393" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2345" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="393" height="393">
    <rect x="0.206299" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="24.2063" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="48.2063" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="72.2063" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="96.2063" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="120.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="144.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="168.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="192.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="216.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="240.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="264.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="288.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="312.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="336.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="360.206" y="-521.796" width="8" height="1437" fill="black"/>
    <rect x="384.206" y="-521.796" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2345)">
    <rect x="196.408" width="277.763" height="277.763" transform="rotate(45 196.408 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rhombus_10;

import React from 'react';

interface IInvertedTriangle {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const InvertedTriangle: React.FC<IInvertedTriangle> = ({ fill, id, width, height }) => {
  return (
    <svg width="80" height="70" viewBox="0 0 80 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_29_305" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="70">
    <path d="M1.2227 0.187134L0.240997 0.187134L0.240997 69.9398H1.2227L1.2227 0.187134Z" fill="black"/>
    <path d="M5.14949 0.187012L4.16779 0.187012L4.16779 69.9397H5.14949L5.14949 0.187012Z" fill="black"/>
    <path d="M9.07631 0.187012L8.0946 0.187012L8.0946 69.9397H9.07631L9.07631 0.187012Z" fill="black"/>
    <path d="M13.0032 0.187012L12.0215 0.187012L12.0215 69.9397H13.0032L13.0032 0.187012Z" fill="black"/>
    <path d="M16.9299 0.187012L15.9482 0.187012L15.9482 69.9397H16.9299L16.9299 0.187012Z" fill="black"/>
    <path d="M20.8568 0.187012L19.8751 0.187012L19.8751 69.9397H20.8568L20.8568 0.187012Z" fill="black"/>
    <path d="M24.7836 0.187012L23.8019 0.187012L23.8019 69.9397H24.7836L24.7836 0.187012Z" fill="black"/>
    <path d="M28.7105 0.187012L27.7288 0.187012L27.7288 69.9397H28.7105L28.7105 0.187012Z" fill="black"/>
    <path d="M56.1981 0.187012L55.2164 0.187012L55.2164 69.9397H56.1981L56.1981 0.187012Z" fill="black"/>
    <path d="M32.6372 0.187012L31.6555 0.187012L31.6555 69.9397H32.6372L32.6372 0.187012Z" fill="black"/>
    <path d="M60.1249 0.187012L59.1432 0.187012L59.1432 69.9397H60.1249L60.1249 0.187012Z" fill="black"/>
    <path d="M36.5641 0.187012L35.5824 0.187012L35.5824 69.9397H36.5641L36.5641 0.187012Z" fill="black"/>
    <path d="M64.0516 0.187012L63.0699 0.187012L63.0699 69.9397H64.0516L64.0516 0.187012Z" fill="black"/>
    <path d="M40.491 0.187012L39.5093 0.187012L39.5093 69.9397H40.491L40.491 0.187012Z" fill="black"/>
    <path d="M67.9785 0.187012L66.9968 0.187012L66.9968 69.9397H67.9785L67.9785 0.187012Z" fill="black"/>
    <path d="M48.3446 0.187012L47.3629 0.187012L47.3629 69.9397H48.3446L48.3446 0.187012Z" fill="black"/>
    <path d="M75.8321 0.187012L74.8504 0.187012L74.8504 69.9397H75.8321L75.8321 0.187012Z" fill="black"/>
    <path d="M44.4177 0.187012L43.436 0.187012L43.436 69.9397H44.4177L44.4177 0.187012Z" fill="black"/>
    <path d="M71.9054 0.187012L70.9237 0.187012L70.9237 69.9397H71.9054L71.9054 0.187012Z" fill="black"/>
    <path d="M52.2713 0.187012L51.2896 0.187012L51.2896 69.9397H52.2713L52.2713 0.187012Z" fill="black"/>
    <path d="M79.759 0.187012L78.7773 0.187012L78.7773 69.9397H79.759L79.759 0.187012Z" fill="black"/>
    </mask>
    <g mask="url(#mask0_29_305)">
    <path d="M40.0001 69.9398L-0.473022 -0.161667L80.4731 -0.161667L40.0001 69.9398Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangle;

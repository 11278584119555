import React from 'react';

interface IInvertedTriangleSmall_15 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_15: React.FC<IInvertedTriangleSmall_15> = ({ fill, id }) => {
  return (
    <svg width="228" height="156" viewBox="0 0 228 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_532_364" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="228" height="156">
    <rect x="228" y="1059" width="12" height="1961" transform="rotate(-180 228 1059)" fill="black"/>
    <rect x="192" y="1059" width="12" height="1961" transform="rotate(-180 192 1059)" fill="black"/>
    <rect x="156" y="1059" width="12" height="1961" transform="rotate(-180 156 1059)" fill="black"/>
    <rect x="120" y="1059" width="12" height="1961" transform="rotate(-180 120 1059)" fill="black"/>
    <rect x="83.9999" y="1059" width="12" height="1961" transform="rotate(-180 83.9999 1059)" fill="black"/>
    <rect x="47.9999" y="1059" width="12" height="1961" transform="rotate(-180 47.9999 1059)" fill="black"/>
    <rect x="11.9999" y="1059" width="12" height="1961" transform="rotate(-180 11.9999 1059)" fill="black"/>
    </mask>
    <g mask="url(#mask0_532_364)">
    <path d="M114 156L1.73863e-05 -2.28381e-05L228 -2.90573e-06L114 156Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangleSmall_15;

import React from 'react';
import { IStripeButtonParams } from './IStripeButtonParams';
import './StripeButton.css';
import { useTranslation } from 'react-i18next';

export function StripeButton(params: IStripeButtonParams) {
  const { t } = useTranslation();
  return <div className="stripe-container" title={t("selectStripe")}>
    <div className={'stripe-button ' + (params.selectedStripe === 0 ? 'stripe-button--active' : '')} style={{ background: params.palette.colors[0] }} 
        onClick={() => { params.onStripeChanged(0); }}></div>
    <div className={'stripe-button ' + (params.selectedStripe === 1 ? 'stripe-button--active' : '')} style={{ background: params.palette.colors[1] }} 
        onClick={() => { params.onStripeChanged(1); }}></div>
    <div className={'stripe-button ' + (params.selectedStripe === 2 ? 'stripe-button--active' : '')} style={{ background: params.palette.colors[2] }}
        onClick={() => { params.onStripeChanged(2); }}></div>
  </div>
}

import React, { useEffect, useState } from 'react';
import { ISettingsPopUpParams } from "./ISettingsPopUpParams";
import './SettingsPopUp.css';
import { loginUser, logoutUser, loadConfig, saveStorage, getDefaultAppConfig } from '../../utils/IConfigUtils';
import { IConfig } from "../../utils/IConfig";
import { User } from '../User';
import { convertFileToBase64 } from '../../utils/imgUtils';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const emptyUser = User.create(false);
let checkUserInterval: any;
const config: IConfig = loadConfig();

export function SettingsPopUp(params: ISettingsPopUpParams) {
    const { t } = useTranslation();
    const [state, setState] = useState({ username: "", password: "", userLoggedIn: emptyUser, actionStatus: "", checkUserInterval: checkUserInterval, tempConfig: {...config.application} });
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/');
    const configParam = pathSegments[1];
    
    useEffect(() => {
        const fetchData = async () => {
          const localStorageKey = `config_${configParam}`;

          if (configParam) {
            try {
              const response = await fetch(`https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/${configParam}/configuration.json`);
              const jsonData = await response.json();
      
              let startLogoLeft: any = null;
              let startLogoRight: any = null;
              let artworkLogoLeft: any = null;
              let artworkLogoRight: any = null;
      
              if (jsonData.configDate !== config.application.configDate || !localStorage.getItem(localStorageKey)) {
                if (jsonData.startLogoLeft && jsonData.startLogoLeft.name) {
                  startLogoLeft = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/${configParam}/${jsonData.startLogoLeft.name}`;
                }
                if (jsonData.startLogoRight && jsonData.startLogoRight.name) {
                  startLogoRight = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/${configParam}/${jsonData.startLogoRight.name}`;
                }
                if (jsonData.artworkLogoLeft && jsonData.artworkLogoLeft.name) {
                  artworkLogoLeft = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/${configParam}/${jsonData.artworkLogoLeft.name}`;
                }
                if (jsonData.artworkLogoRight && jsonData.artworkLogoRight.name) {
                  artworkLogoRight = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/${configParam}/${jsonData.artworkLogoRight.name}`;
                }
      
                setState((pv) => {
                  const currentConfig = pv.tempConfig;
                  currentConfig['configDate'] = jsonData.configDate;
                  currentConfig['folderName'] = jsonData.folderName;
                  currentConfig['timer'] = jsonData.timer;
                  currentConfig['stripesWidths'] = jsonData.stripesWidths;
                  currentConfig['maxShapeRotationDegree'] = jsonData.maxShapeRotationDegree;
                  currentConfig['stripesWidth'] = jsonData.stripesWidth;
                  currentConfig['selectedLanguage'] = jsonData.selectedLanguage;
                  currentConfig['shapeRotationDegree'] = jsonData.shapeRotationDegree;
                  currentConfig['hideIcon'] = jsonData.hideIcon;
                  currentConfig['startLogoLeft'].name = jsonData.logoLeftFileName;
                  currentConfig['startLogoRight'].name = jsonData.logoRightFileName;
                  currentConfig['logoPdfFileName'] = jsonData.logoPdfFileName;
                  if (jsonData.startLogoLeft) currentConfig['startLogoLeft'].src = startLogoLeft;
                  if (jsonData.startLogoLeft) currentConfig['startLogoLeft'].hide = jsonData.startLogoLeft.hide;
                  if (jsonData.startLogoRight) currentConfig['startLogoRight'].src = startLogoRight;
                  if (jsonData.startLogoRight) currentConfig['startLogoRight'].hide = jsonData.startLogoRight.hide;
                  if (jsonData.artworkLogoLeft) currentConfig['artworkLogoLeft'].src = artworkLogoLeft;
                  if (jsonData.artworkLogoLeft) currentConfig['artworkLogoLeft'].hide = jsonData.artworkLogoLeft.hide;
                  if (jsonData.artworkLogoRight) currentConfig['artworkLogoRight'].src = artworkLogoRight;
                  if (jsonData.artworkLogoRight) currentConfig['artworkLogoRight'].hide = jsonData.artworkLogoRight.hide;
                  return { ...pv, tempConfig: currentConfig };
                });
      
                if (jsonData.configDate !== config.application.configDate || !localStorage.getItem(localStorageKey)) {
                  saveStorage(state.tempConfig, configParam);
                  window.location.reload();
                }
              }
            } catch (error) {
              console.error("Error loading configuration:", error);

              const response = await fetch(`https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/default/configuration.json`);
              const jsonData = await response.json();
      
              let startLogoLeft: any = null;
              let startLogoRight: any = null;
              let artworkLogoLeft: any = null;
              let artworkLogoRight: any = null;
      
              if (jsonData.configDate !== config.application.configDate || !localStorage.getItem(localStorageKey)) {
                if (jsonData.startLogoLeft && jsonData.startLogoLeft.name) {
                  startLogoLeft = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/default/${jsonData.startLogoLeft.name}`;
                }
                if (jsonData.startLogoRight && jsonData.startLogoRight.name) {
                  startLogoRight = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/default/${jsonData.startLogoRight.name}`;
                }
                if (jsonData.artworkLogoLeft && jsonData.artworkLogoLeft.name) {
                  artworkLogoLeft = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/default/${jsonData.artworkLogoLeft.name}`;
                }
                if (jsonData.artworkLogoRight && jsonData.artworkLogoRight.name) {
                  artworkLogoRight = `https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/default/${jsonData.artworkLogoRight.name}`;
                }
      
                setState((pv) => {
                  const currentConfig = pv.tempConfig;
                  currentConfig['configDate'] = jsonData.configDate;
                  currentConfig['folderName'] = jsonData.folderName;
                  currentConfig['timer'] = jsonData.timer;
                  currentConfig['stripesWidths'] = jsonData.stripesWidths;
                  currentConfig['maxShapeRotationDegree'] = jsonData.maxShapeRotationDegree;
                  currentConfig['stripesWidth'] = jsonData.stripesWidth;
                  currentConfig['selectedLanguage'] = jsonData.selectedLanguage;
                  currentConfig['shapeRotationDegree'] = jsonData.shapeRotationDegree;
                  currentConfig['hideIcon'] = jsonData.hideIcon;
                  currentConfig['startLogoLeft'].name = jsonData.logoLeftFileName;
                  currentConfig['startLogoRight'].name = jsonData.logoRightFileName;
                  currentConfig['logoPdfFileName'] = jsonData.logoPdfFileName;
                  if (jsonData.startLogoLeft) currentConfig['startLogoLeft'].src = startLogoLeft;
                  if (jsonData.startLogoLeft) currentConfig['startLogoLeft'].hide = jsonData.startLogoLeft.hide;
                  if (jsonData.startLogoRight) currentConfig['startLogoRight'].src = startLogoRight;
                  if (jsonData.startLogoRight) currentConfig['startLogoRight'].hide = jsonData.startLogoRight.hide;
                  if (jsonData.artworkLogoLeft) currentConfig['artworkLogoLeft'].src = artworkLogoLeft;
                  if (jsonData.artworkLogoLeft) currentConfig['artworkLogoLeft'].hide = jsonData.artworkLogoLeft.hide;
                  if (jsonData.artworkLogoRight) currentConfig['artworkLogoRight'].src = artworkLogoRight;
                  if (jsonData.artworkLogoRight) currentConfig['artworkLogoRight'].hide = jsonData.artworkLogoRight.hide;
                  return { ...pv, tempConfig: currentConfig };
                });
      
                if (jsonData.configDate !== config.application.configDate || !localStorage.getItem(localStorageKey)) {
                  saveStorage(state.tempConfig, configParam);
                  window.location.reload();
                }
              }
            }
          }
        };
      
        fetchData();
      }, []);
      
  

    let stripeWidthOptions = config.application.stripesWidths.map((option: number, i: number) => {
        return (
            <option key={i} value={option}>{option}</option>
        );
    });

    const updateUserActivity = () => {
        setState(pv => {
            const user: User = pv.userLoggedIn;
            user.updateActivity();

            return ({ ...pv, userLoggedIn: user });
        });
    };

    const validateInput = (value: number, min: number, max: number): boolean => {
        if(!isNaN(value) && value >= min && value <= max) {
            return true;
        } else {
            return false;
        }
    };

    const handleInputChange = (propName: string, newValue: number | boolean, deeperPropName?: string) => {
        updateUserActivity();

        setState(pv => {
            const currentConfig: any = pv.tempConfig;

            if(deeperPropName === undefined) {
                currentConfig[propName] = newValue;
            } else {
                currentConfig[propName][deeperPropName] = newValue;
            }
            return ({ ...pv, tempConfig: currentConfig });
        });
    };

    const handleLanguageChange = (language: string) => {
        setState(pv => {
            const currentConfig: any = pv.tempConfig;
            currentConfig['selectedLanguage'] = language;
            return ({ ...pv, tempConfig: currentConfig });
        });
    };

    const handleFileRead = (event: any, side: string) => {
        updateUserActivity();
        
        const allowedFileTypes = ["image/png", "image/jpeg"];
        const file = event.target.files[0];

        if(file && allowedFileTypes.includes(file.type)) {
            let img = new Image();
            let objectUrl = URL.createObjectURL(file);
            img.onload = async function(this: any) {
                if(this.width <= 185 && this.height <= 85) {
                    const base64 = await convertFileToBase64(file);
        
                    setState(pv => {
                        const currentConfig: any = pv.tempConfig;
                        currentConfig[`startLogo${side}`].src = base64;
                        currentConfig[`startLogo${side}`].name = file.name;
                        currentConfig[`artworkLogo${side}`].src = base64;
                        currentConfig[`artworkLogo${side}`].name = file.name;
                        
                        return ({ ...pv, tempConfig: currentConfig, actionStatus: "" });
                    });
                } else {
                    setState(pv => ({ ...pv, actionStatus: "logoDimensionsError" }));
                }
                URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        } else {
            setState(pv => ({ ...pv, actionStatus: "fileFormatError" }));
        }
    }

    const logoLeft = require(`./../../assets/img/intro/logo-left.png`);
	const logoRight = require(`./../../assets/img/intro/logo-right.png`);

    return <div className={`settings-pop-up settings-pop-up--${(params.show ? 'show' : 'hide')}`}>
        <div className={`settings-pop-up__login-screen settings-pop-up__login-screen--${state.userLoggedIn.isLogged ? 'hide' : 'show'}`}>
            <input className="settings-pop-up__input settings-pop-up__input--text" type="text" placeholder={t("username")} value={state.username} onChange={(event) => { setState(pv => ({ ...pv, username: event.target.value })) }} />
            <input className="settings-pop-up__input settings-pop-up__input--password" type="password" placeholder={t("password")} value={state.password} onChange={(event) => { setState(pv => ({ ...pv, password: event.target.value })) }} />

            <div className="settings-pop-up__buttons-wrapper">
                <button className="settings-pop-up__button settings-pop-up__button--main" onClick={() => {
                    const result = loginUser(state.username, state.password);
                    if(result.status) {
                        checkUserInterval = setInterval(() => {
                            if(!result.user.isUserLoggedIn()) {
                                clearInterval(checkUserInterval);

                                setState(prevState => {
                                    return ({ ...prevState, actionStatus: "logoutInactive", userLoggedIn: emptyUser, checkUserInterval });
                                });
                            }
                        }, 5000);

                        setState(prevState => {
                            return ({ ...prevState, username: "", password: "", userLoggedIn: result.user, actionStatus: "", checkUserInterval});
                        });
                    } else {
                        setState(prevState => {
                            return ({ ...prevState, password: "", actionStatus: result.textStatus });
                        });
                    }
                }}>{t("signIn")}</button>
                <button className="settings-pop-up__button" onClick={() => { params.onToggleSettingsPopUp(false) }}>{t("cancel")}</button>
            </div>

            <p className="settings-pop-up__action-status">{t(state.actionStatus)}</p>
        </div>

        <div className={`settings-pop-up__settings-screen settings-pop-up__settings-screen--${state.userLoggedIn.isLogged ? 'show' : 'hide'}`}>
            <div className={`settings-pop-up__settings-screen-content-wrapper`}>
                <div className={`settings-pop-up__settings-screen--half--left`}>
                    <div className={`settings-pop-up__settings-row ${!['admin','user'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("timer")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input className="settings-pop-up__settings-input" type="number" min="0" max="9999" step="1" value={state.tempConfig.timer} onChange={(event) => { if(validateInput(parseInt(event.target.value), 0, 9999)) handleInputChange("timer", parseInt(event.target.value)) }} />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("maxShapeRotation")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input className="settings-pop-up__settings-input" type="number" min="0" max="359" step="1" value={state.tempConfig.maxShapeRotationDegree} onChange={(event) => { if(validateInput(parseInt(event.target.value), 0, 359)) handleInputChange("maxShapeRotationDegree", parseInt(event.target.value)) }} />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{t("shapeRotationStepLeft")}:</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input className="settings-pop-up__settings-input" type="number" min="0.05" max="180" step="0.05" value={state.tempConfig.shapeRotationDegree.left} onChange={(event) => { if(validateInput(parseFloat(event.target.value), 0.05, 180)) handleInputChange("shapeRotationDegree", parseFloat(parseFloat(event.target.value).toFixed(2)), "left") }} />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{t("shapeRotationStepRight")}:</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input className="settings-pop-up__settings-input" type="number" min="0.05" max="180" step="0.05" value={state.tempConfig.shapeRotationDegree.right} onChange={(event) => { if(validateInput(parseFloat(event.target.value), 0.05, 180)) handleInputChange("shapeRotationDegree", parseFloat(parseFloat(event.target.value).toFixed(2)), "right") }} />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("backgroundStripesStartingWidth")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <select className="settings-pop-up__settings-input" defaultValue={config.application.stripesWidth} onChange={(event) => { if(validateInput(parseFloat(event.target.value), 0, 5)) handleInputChange("stripesWidth", parseFloat(parseFloat(event.target.value).toFixed(2))) }}>
                                {stripeWidthOptions}
                            </select>
                        </span>
                    </div>

                    <span className="settings-pop-up__horizontal-line"></span>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("hideQrCode")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="checkbox" className="settings-pop-up__settings-input-checkbox" defaultChecked={config.application.hideIcon.qrcode} onChange={ event => handleInputChange("hideIcon", event.target.checked, "qrcode") } />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("hideShareCode")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="checkbox" className="settings-pop-up__settings-input-checkbox" defaultChecked={config.application.hideIcon.share} onChange={ event => handleInputChange("hideIcon", event.target.checked, "share") } />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("hidePrintCode")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="checkbox" className="settings-pop-up__settings-input-checkbox" defaultChecked={config.application.hideIcon.print} onChange={ event => handleInputChange("hideIcon", event.target.checked, "print") } />
                        </span>
                    </div>

                    <span className="settings-pop-up__horizontal-line"></span>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("changeDefaultLanguage")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <LanguageSwitcher handleLanguageChange={(language: string) => handleLanguageChange(language)} />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("changeLanguage")}:`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <LanguageSwitcher handleLanguageChange={(language: string) => handleLanguageChange(language)} temporaryLanguageStatus={true} />
                        </span>
                    </div>
                </div>

                <div className={`settings-pop-up__settings-screen--half`}>
                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{t("introLogoLeft")}:</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="file" name="file" onChange={ e => handleFileRead(e, "Left") } />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['user'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <img src={state.tempConfig.startLogoLeft.src.length > 0 ? state.tempConfig.startLogoLeft.src : (config.application.startLogoLeft.src.length > 0 ? config.application.startLogoLeft.src : logoLeft.default)}
                            alt={'Start logo left or placeholder'} />
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        {config.application.startLogoLeft.name && <p className="settings-pop-up__settings-label">{`${t("fileName")}:`} <span>{config.application.startLogoLeft.name}</span></p>}
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{t("hideIntroLogo")}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="checkbox" className="settings-pop-up__settings-input-checkbox" defaultChecked={config.application.startLogoLeft.hide} onChange={ event => handleInputChange("startLogoLeft", event.target.checked, "hide") } />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("hideArtworkLogo")}`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="checkbox" className="settings-pop-up__settings-input-checkbox" defaultChecked={config.application.artworkLogoLeft.hide} onChange={ event => handleInputChange("artworkLogoLeft", event.target.checked, "hide") } />
                        </span>
                    </div>

                    <span className="settings-pop-up__horizontal-line"></span>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{t("introLogoRight")}:</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="file" name="file" onChange={ e => handleFileRead(e, "Right") } />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['user'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <img src={state.tempConfig.startLogoRight.src.length > 0 ? state.tempConfig.startLogoRight.src : (config.application.startLogoRight.src.length > 0 ? config.application.startLogoRight.src : logoRight.default)}
                            alt={'Start logo right or placeholder'} />
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        {config.application.startLogoRight.name && <p className="settings-pop-up__settings-label">{`${t("fileName")}:`} <span>{config.application.startLogoRight.name}</span></p>}
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{t("hideIntroLogo")}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="checkbox" className="settings-pop-up__settings-input-checkbox" defaultChecked={config.application.startLogoRight.hide} onChange={ event => handleInputChange("startLogoRight", event.target.checked, "hide") } />
                        </span>
                    </div>

                    <div className={`settings-pop-up__settings-row ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__settings-row--hide' : ''}`}>
                        <p className="settings-pop-up__settings-label">{`${t("hideArtworkLogo")}`}</p>
                        <span className="settings-pop-up__settings-input-wrapper">
                            <input type="checkbox" className="settings-pop-up__settings-input-checkbox" defaultChecked={config.application.artworkLogoRight.hide} onChange={ event => handleInputChange("artworkLogoRight", event.target.checked, "hide") } />
                        </span>
                    </div>
                </div>
            </div>

            <div className="settings-pop-up__buttons-wrapper">
                <button className="settings-pop-up__button settings-pop-up__button--main" onClick={() => {
                    saveStorage(state.tempConfig, configParam);

                    clearInterval(checkUserInterval);

                    if(logoutUser(state.userLoggedIn)) {
                        setState(prevState => {
                            return ({ ...prevState, actionStatus: "", userLoggedIn: emptyUser, checkUserInterval })
                        });
                    } else {
                        setState(prevState => {
                            return ({ ...prevState, actionStatus: "alreadySignedOut", checkUserInterval })
                        });
                    }

                    params.onToggleSettingsPopUp(false);

                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }}>{t("saveAndRestartApp")}</button>

                <button className={`settings-pop-up__button settings-pop-up__button--reset ${!['admin'].includes(state.userLoggedIn.type!) ? 'settings-pop-up__button--hide' : ''}`} onClick={() => { 
                    updateUserActivity();
                    setState(pv => ({ ...pv, tempConfig: {...getDefaultAppConfig()} }));
                }}>{t("resetSettingsToDefault")}</button>
                            <button className="settings-pop-up__button" onClick={() => {
                if(logoutUser(state.userLoggedIn)) {
                    setState(prevState => {
                        return ({ ...prevState, actionStatus: "", userLoggedIn: emptyUser, checkUserInterval })
                    });
                } else {
                    setState(prevState => {
                        return ({ ...prevState, actionStatus: "alreadySignedOut", checkUserInterval })
                    });
                }

                    params.onToggleSettingsPopUp(false);
                }}>{t("cancelAndSignOut")}</button>
            </div>

            <p className="settings-pop-up__action-status">{t(state.actionStatus)}</p>
        </div>
    </div>;
}
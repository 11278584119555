import React from 'react';

interface IRhombus_08 {
    fill: string;
    id?: string;
}

const Rhombus_08: React.FC<IRhombus_08> = ({ fill, id }) => {
  return (
    <svg width="402" height="402" viewBox="0 0 402 402" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_515_2105" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="402" height="402">
    <rect x="162.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="18.0059" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="306.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="234.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="90.0059" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="378.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="198.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="54.0059" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="342.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="270.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="126.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="180.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="36.0059" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="324.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="252.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="108.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="396.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="216.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="72.0059" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="360.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="0.00585938" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="288.006" y="-98.998" width="6" height="600" fill="black"/>
    <rect x="144.006" y="-98.998" width="6" height="600" fill="black"/>
    </mask>
    <g mask="url(#mask0_515_2105)">
    <rect x="201" width="284.257" height="284.257" transform="rotate(45 201 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rhombus_08;

import React from 'react';

interface ITriangleSmall_08 {
    fill: string;
    id?: string;
}

const TriangleSmall_08: React.FC<ITriangleSmall_08> = ({ fill, id }) => {
  return (
    <svg width="188" height="156" viewBox="0 0 188 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_514_1822" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="188" height="156">
    <rect x="19" y="-73" width="6" height="302" fill="black"/>
    <rect x="127" y="-73" width="6" height="302" fill="black"/>
    <rect x="73" y="-73" width="6" height="302" fill="black"/>
    <rect x="181" y="-73" width="6" height="302" fill="black"/>
    <rect x="37" y="-73" width="6" height="302" fill="black"/>
    <rect x="145" y="-73" width="6" height="302" fill="black"/>
    <rect x="91" y="-73" width="6" height="302" fill="black"/>
    <rect x="55" y="-73" width="6" height="302" fill="black"/>
    <rect x="163" y="-73" width="6" height="302" fill="black"/>
    <rect x="1" y="-73" width="6" height="302" fill="black"/>
    <rect x="109" y="-73" width="6" height="302" fill="black"/>
    </mask>
    <g mask="url(#mask0_514_1822)">
    <path d="M94 0L188 156H0L94 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default TriangleSmall_08;

import React from 'react';
import { IDemoInstructionsParams } from "./IDemoInstructionsParams";
import './DemoInstructions.css';
import { useTranslation } from 'react-i18next';

export function DemoInstructions(params: IDemoInstructionsParams) {
    const { t } = useTranslation();
    let elementsToHide: any = {
        step1: [1724, 1725, 1726, 1727, 1764, 1765, 1766, 1767, 1804, 1805, 1806, 1807, 1844, 1845, 1846, 1847, 1884, 1885, 1886, 1887]
    };

    let hintPosition: any = {
        step1: {
            left: 60,
            top: 385
        }
    };

    let gridElements: any = [];
    for (let i = 0; i < 2000; i++) { // 40 in column X 50 in row
        gridElements.push(<div className={`demo-instructions__grid-element ${(elementsToHide[`step${params.step}`].includes(i+1) ? 'demo-instructions__grid-element--hide' : '')}`} key={i}>
            {/* {i + 1} */}
        </div>);
    }

    return <div className={`demo-instructions demo-instructions--${(params.show ? 'show' : 'hide')} demo-instructions__step-${params.step}`}>
        {gridElements}

        <span className="demo-instructions__hint-wrapper" style={{ left: `${hintPosition[`step${params.step}`].left}px`, top: `${hintPosition[`step${params.step}`].top}px` }}>
            <h4 className="demo-instructions__hint-title">{t("demoInstructionTitle")}</h4>
            <p className="demo-instructions__hint-text">{t("demoInstructionTxt")}</p>
            
            <button className="demo-instructions__hint-button" onClick={() => params.onDemoInstructionsToggle(false)}>OK</button>
        </span>
    </div>;
}

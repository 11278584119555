import React from 'react';

interface ISquareSmall_02 {
    fill: string;
    id?: string;
}

const SquareSmall_02: React.FC<ISquareSmall_02> = ({ fill, id }) => {
  return (
    <svg width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_522_1687" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="146" height="146">
    <rect x="48" y="-566" width="2" height="1426" fill="black"/>
    <rect y="-566" width="2" height="1426" fill="black"/>
    <rect x="96" y="-566" width="2" height="1426" fill="black"/>
    <rect x="72" y="-566" width="2" height="1426" fill="black"/>
    <rect x="24" y="-566" width="2" height="1426" fill="black"/>
    <rect x="120" y="-566" width="2" height="1426" fill="black"/>
    <rect x="60" y="-566" width="2" height="1426" fill="black"/>
    <rect x="12" y="-566" width="2" height="1426" fill="black"/>
    <rect x="108" y="-566" width="2" height="1426" fill="black"/>
    <rect x="84" y="-566" width="2" height="1426" fill="black"/>
    <rect x="36" y="-566" width="2" height="1426" fill="black"/>
    <rect x="132" y="-566" width="2" height="1426" fill="black"/>
    <rect x="54" y="-566" width="2" height="1426" fill="black"/>
    <rect x="6" y="-566" width="2" height="1426" fill="black"/>
    <rect x="102" y="-566" width="2" height="1426" fill="black"/>
    <rect x="78" y="-566" width="2" height="1426" fill="black"/>
    <rect x="30" y="-566" width="2" height="1426" fill="black"/>
    <rect x="126" y="-566" width="2" height="1426" fill="black"/>
    <rect x="66" y="-566" width="2" height="1426" fill="black"/>
    <rect x="18" y="-566" width="2" height="1426" fill="black"/>
    <rect x="114" y="-566" width="2" height="1426" fill="black"/>
    <rect x="90" y="-566" width="2" height="1426" fill="black"/>
    <rect x="42" y="-566" width="2" height="1426" fill="black"/>
    <rect x="138" y="-566" width="2" height="1426" fill="black"/>
    <rect x="144" y="-566" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_522_1687)">
    <rect width="146" height="146" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default SquareSmall_02;

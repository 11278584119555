import React from 'react';

interface ICircleSmall_05 {
  fill: string;
  id?: string;
}

const CircleSmall_05: React.FC<ICircleSmall_05> = ({ fill, id }) => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1490" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
    <rect x="1" y="-561" width="4" height="1272" fill="black"/>
    <rect x="13" y="-561" width="4" height="1272" fill="black"/>
    <rect x="25" y="-561" width="4" height="1272" fill="black"/>
    <rect x="37" y="-561" width="4" height="1272" fill="black"/>
    <rect x="49" y="-561" width="4" height="1272" fill="black"/>
    <rect x="61" y="-561" width="4" height="1272" fill="black"/>
    <rect x="73" y="-561" width="4" height="1272" fill="black"/>
    <rect x="85" y="-561" width="4" height="1272" fill="black"/>
    <rect x="97" y="-561" width="4" height="1272" fill="black"/>
    <rect x="109" y="-561" width="4" height="1272" fill="black"/>
    <rect x="121" y="-561" width="4" height="1272" fill="black"/>
    <rect x="133" y="-561" width="4" height="1272" fill="black"/>
    <rect x="145" y="-561" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1490)">
    <circle cx="75" cy="75" r="75" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default CircleSmall_05;

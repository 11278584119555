import { useState } from "react";
import { ISelectShapeButtonParams } from "./ISelectShapeButtonParams";
import './SelectShapeButton.css';
import { Shape } from "../../Shape";
import { useTranslation } from "react-i18next";
import DynamicSVG from "./DynamicSVG";

export function SelectShapeButton(params: ISelectShapeButtonParams) {
    const { t } = useTranslation();
    const shapeIcon = require(`./../../../assets/img/shapes/${params.shapes.shapes[params.id].fileName}`);
    const [activeShape, setActiveShape] = useState<Shape>()

    const handleShapeDoubleClick = () => {
        if(params.shapes.shapes[params.id].tagName !== params.shapePalette[params.shapePalette.length - 1].tagName) {
            params.onShapeHidden()
        } else if (activeShape) {
            switch (activeShape.name) {
                case 'rhombus':
                    params.shapes.shapes[params.id].tagName = 'rhombus';
                    break;
                case 'rhombus-small':
                    params.shapes.shapes[params.id].tagName = 'rhombus';
                    break;
                case 'triangle':
                    params.shapes.shapes[params.id].tagName = 'triangle';
                    break;
                case 'triangle-small':
                    params.shapes.shapes[params.id].tagName = 'triangle';
                    break;
                case 'inverted-triangle':
                    params.shapes.shapes[params.id].tagName = 'triangle';
                    break;
                case 'inverted-triangle-small':
                    params.shapes.shapes[params.id].tagName = 'triangle';
                    break;
                case 'rectangle':
                    params.shapes.shapes[params.id].tagName = 'rect';
                    break;
                case 'rectangle-small':
                    params.shapes.shapes[params.id].tagName = 'rect';
                    break;
                case 'square':
                    params.shapes.shapes[params.id].tagName = 'rect';
                    break;
                case 'square-small':
                    params.shapes.shapes[params.id].tagName = 'rect';
                    break;
                case 'circle':
                    params.shapes.shapes[params.id].tagName = 'circle';
                    break;
                case 'circle-small':
                    params.shapes.shapes[params.id].tagName = 'circle';
                    break;
                case 'ellipse':
                    params.shapes.shapes[params.id].tagName = 'ellipse';
                    break;
                case 'ellipse-small':
                    params.shapes.shapes[params.id].tagName = 'ellipse';
                    break;
            }
        }
    };

    return (
        <div title={`${t("selectShape")} ${params.id + 1}`} className={`select-shape-button ${params.class} ${(params.id === params.selectedShape ? 'select-shape-button--active' : '')}`}
             onClick={() => { params.onShapeSelect(params.id); setActiveShape(params.shapes.shapes[params.selectedShape])}}
             onDoubleClick={() => handleShapeDoubleClick()}>
            <DynamicSVG title={params.shapes.shapes[params.id].title} fillColor={params.shapes.shapes[params.id].tagName === 'hidden' ? "#E4E4E4" : (params.shapeColor.includes('#') ? params.shapeColor : `#${params.shapeColor}`)}/>
        </div>
    )
}
import React from 'react';

interface IInvertedTriangleSmall_08 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_08: React.FC<IInvertedTriangleSmall_08> = ({ fill, id }) => {
  return (
    <svg width="188" height="156" viewBox="0 0 188 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_515_2169" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="188" height="156">
    <rect x="169" y="229" width="6" height="302" transform="rotate(-180 169 229)" fill="black"/>
    <rect x="61" y="229" width="6" height="302" transform="rotate(-180 61 229)" fill="black"/>
    <rect x="115" y="229" width="6" height="302" transform="rotate(-180 115 229)" fill="black"/>
    <rect x="7" y="229" width="6" height="302" transform="rotate(-180 7 229)" fill="black"/>
    <rect x="151" y="229" width="6" height="302" transform="rotate(-180 151 229)" fill="black"/>
    <rect x="43" y="229" width="6" height="302" transform="rotate(-180 43 229)" fill="black"/>
    <rect x="97" y="229" width="6" height="302" transform="rotate(-180 97 229)" fill="black"/>
    <rect x="133" y="229" width="6" height="302" transform="rotate(-180 133 229)" fill="black"/>
    <rect x="25" y="229" width="6" height="302" transform="rotate(-180 25 229)" fill="black"/>
    <rect x="187" y="229" width="6" height="302" transform="rotate(-180 187 229)" fill="black"/>
    <rect x="79" y="229" width="6" height="302" transform="rotate(-180 79 229)" fill="black"/>
    </mask>
    <g mask="url(#mask0_515_2169)">
    <path d="M94 156L6.17335e-06 -2.53762e-05L188 -8.9407e-06L94 156Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangleSmall_08;

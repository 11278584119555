import React from 'react';

interface ITriangleSmall_10 {
    fill: string;
    id?: string;
}

const TriangleSmall_10: React.FC<ITriangleSmall_10> = ({ fill, id }) => {
  return (
    <svg width="196" height="156" viewBox="0 0 196 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2092" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="196" height="156">
    <rect x="-2" y="-641" width="8" height="1437" fill="black"/>
    <rect x="22" y="-641" width="8" height="1437" fill="black"/>
    <rect x="46" y="-641" width="8" height="1437" fill="black"/>
    <rect x="70" y="-641" width="8" height="1437" fill="black"/>
    <rect x="94" y="-641" width="8" height="1437" fill="black"/>
    <rect x="118" y="-641" width="8" height="1437" fill="black"/>
    <rect x="142" y="-641" width="8" height="1437" fill="black"/>
    <rect x="166" y="-641" width="8" height="1437" fill="black"/>
    <rect x="190" y="-641" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2092)">
    <path d="M98 0L196 156H0L98 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default TriangleSmall_10;

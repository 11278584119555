import React from 'react';

interface ISquare {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const Square: React.FC<ISquare> = ({ fill, id, width, height }) => {
  return (
    <svg width="86" height="74" viewBox="0 0 86 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12_191" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="86" height="74">
    <rect width="2" height="74" fill="black"/>
    <rect x="36" width="2" height="74" fill="black"/>
    <rect x="18" width="2" height="74" fill="black"/>
    <rect x="54" width="2" height="74" fill="black"/>
    <rect x="72" width="2" height="74" fill="black"/>
    <rect x="6" width="2" height="74" fill="black"/>
    <rect x="42" width="2" height="74" fill="black"/>
    <rect x="24" width="2" height="74" fill="black"/>
    <rect x="60" width="2" height="74" fill="black"/>
    <rect x="78" width="2" height="74" fill="black"/>
    <rect x="12" width="2" height="74" fill="black"/>
    <rect x="48" width="2" height="74" fill="black"/>
    <rect x="30" width="2" height="74" fill="black"/>
    <rect x="66" width="2" height="74" fill="black"/>
    <rect x="84" width="2" height="74" fill="black"/>
    </mask>
    <g mask="url(#mask0_12_191)">
    <rect x="6" width="74" height="74" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Square;

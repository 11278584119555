import { useState } from "react";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { ISharePopUpParams } from "./ISharePopUpParams";
import './SharePopUp.css';
import { useTranslation } from "react-i18next";

export function SharePopUp(params: ISharePopUpParams) {
    const { t } = useTranslation();
    const [state, setState] = useState({ buttonColor: "" });

    const copyUrl = (window: any) => {
        if (window["clipboardData"] && window["clipboardData"].setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            return window["clipboardData"].setData("Text", params.url);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            let textarea = document.createElement("textarea");
            textarea.textContent = params.url;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                setState(pv => ({ ...pv, buttonColor: "#62ff0080" }));
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                setState(pv => ({ ...pv, buttonColor: "#ff000080" }));
                return false;
            }
            finally {
                document.body.removeChild(textarea);
                setTimeout(() => { setState(pv => ({ ...pv, buttonColor: '' })) }, 1500);
            }
        }
    };

    return <div className={`share-pop-up share-pop-up--${(params.show ? 'show' : 'hide')} ${params.notModal ? 'share-pop-up--not-modal' : ''}`}>
        <div className="share-pop-up__social-share-wrapper">
            <EmailShareButton url={params.url}>
                <EmailIcon size={40} round={true} />
            </EmailShareButton>

            <FacebookShareButton url={params.url} quote={t("myCruzDiezExperience")}>
                <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <TwitterShareButton url={params.url} title={t("myCruzDiezExperience")}>
                <TwitterIcon size={40} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton url={params.url}>
                <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>

            <WhatsappShareButton url={params.url} title={t("myCruzDiezExperience")}>
                <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
        </div>

        <input className="share-pop-up__input share-pop-up__input--text" type="text" readOnly={true} value={params.url} style={{background: (state.buttonColor.length > 0 ? state.buttonColor : '' )}} />
        
        <div className="settings-pop-up__buttons-wrapper">
            <button className="share-pop-up__button share-pop-up__button--main" style={{background: (state.buttonColor.length > 0 ? state.buttonColor : '' )}} onClick={() => { copyUrl(window) }}>{t("copy")}</button>
            <button className={`share-pop-up__button ${params.notModal ? 'hide' : ''}`} onClick={() => { params.onToggleSharePopUp(false) }}>{t("close")}</button>
        </div>
    </div>;
}

import React from 'react';
import { Stripe } from './Stripe';
import { Palette } from './Palette';
import { loadConfig } from '../utils/IConfigUtils';
import { IConfig } from '../utils/IConfig';

const config: IConfig = loadConfig();
const workspaceDimensions = config.application.workspaceDimensions;

interface IBackgroundParams {
    figure: any;
    stripesWidth: number;
    palette: Palette;
}

function Background(params: IBackgroundParams) {
    const size: number = 100;
    let sizeOfOne: number = params.stripesWidth;
    if(params.stripesWidth === 0.8) sizeOfOne = 0.75
    if(params.stripesWidth === 0.2) sizeOfOne = 0.25
    const count: number = size / sizeOfOne;
    let index = 0;

    let array = [];
    params.palette.resetCounter();
    while (index < count) {
        array.push(new Stripe(params.palette.next(), index * sizeOfOne, sizeOfOne))
        index++;
    }

    const rects = array.map((stripe, index) => <rect key={index} fill={stripe.fill} height={`${workspaceDimensions.height}px`} width={`${stripe.width}%`} x={`${stripe.xPos}%`}></rect>)

    return <svg x="0px" y="0px" height={workspaceDimensions.height} width={workspaceDimensions.width} viewBox={`0 0 ${workspaceDimensions.width} ${workspaceDimensions.height}`}>
        <g height={workspaceDimensions.height} width={workspaceDimensions.width}>
            {rects}
            {params.figure}
        </g>
    </svg>;
}


export default Background;

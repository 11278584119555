import React, { useEffect } from 'react';
import * as d3 from "d3";
import { Shape } from './Shape';
import { ShapePosition } from './ShapePosition';
import { Stripe } from './Stripe';
import { loadConfig } from '../utils/IConfigUtils';
import { IConfig } from '../utils/IConfig';
import CircleSmall_02 from './Shapes/0.2/CircleSmall_02';
import CircleSmall_05 from './Shapes/0.5/CircleSmall_05';
import CircleSmall_08 from './Shapes/0.8/CircleSmall_08';
import CircleSmall_10 from './Shapes/1/CircleSmall_10';
import CircleSmall_15 from './Shapes/1.5/CircleSmall_15';
import Circle_02 from './Shapes/0.2/Circle_02';
import Circle_05 from './Shapes/0.5/Circle_05';
import Circle_08 from './Shapes/0.8/Circle_08';
import Circle_10 from './Shapes/1/Circle_10';
import Circle_15 from './Shapes/1.5/Circle_15';
import EllipseSmall_02 from './Shapes/0.2/EllipseSmall_02';
import EllipseSmall_05 from './Shapes/0.5/EllipseSmall_05';
import EllipseSmall_08 from './Shapes/0.8/EllipseSmall_08';
import EllipseSmall_10 from './Shapes/1/EllipseSmall_10';
import EllipseSmall_15 from './Shapes/1.5/EllipseSmall_15';
import Ellipse_02 from './Shapes/0.2/Ellipse_02';
import Ellipse_05 from './Shapes/0.5/Ellipse_05';
import Ellipse_08 from './Shapes/0.8/Ellipse_08';
import Ellipse_10 from './Shapes/1/Ellipse_10';
import Ellipse_15 from './Shapes/1.5/Ellipse_15';
import InvertedTriangleSmall_02 from './Shapes/0.2/InvertedTriangleSmall_02';
import InvertedTriangleSmall_05 from './Shapes/0.5/InvertedTriangleSmall_05';
import InvertedTriangleSmall_08 from './Shapes/0.8/InvertedTriangleSmall_08';
import InvertedTriangleSmall_10 from './Shapes/1/InvertedTriangleSmall_10';
import InvertedTriangleSmall_15 from './Shapes/1.5/InvertedTriangleSmall_15';
import InvertedTriangle_02 from './Shapes/0.2/InvertedTriangle_02';
import InvertedTriangle_05 from './Shapes/0.5/InvertedTriangle_05';
import InvertedTriangle_08 from './Shapes/0.8/InvertedTriangle_08';
import InvertedTriangle_10 from './Shapes/1/InvertedTriangle_10';
import InvertedTriangle_15 from './Shapes/1.5/InvertedTriangle_15';
import RectangleSmall_02 from './Shapes/0.2/RectangleSmall_02';
import RectangleSmall_05 from './Shapes/0.5/RectangleSmall_05';
import RectangleSmall_08 from './Shapes/0.8/RectangleSmall_08';
import RectangleSmall_10 from './Shapes/1/RectangleSmall_10';
import RectangleSmall_15 from './Shapes/1.5/RectangleSmall_15';
import Rectangle_02 from './Shapes/0.2/Rectangle_02';
import Rectangle_05 from './Shapes/0.5/Rectangle_05';
import Rectangle_08 from './Shapes/0.8/Rectangle_08';
import Rectangle_10 from './Shapes/1/Rectangle_10';
import Rectangle_15 from './Shapes/1.5/Rectangle_15';
import RhombusSmall_02 from './Shapes/0.2/RhombusSmall_02';
import RhombusSmall_05 from './Shapes/0.5/RhombusSmall_05';
import RhombusSmall_08 from './Shapes/0.8/RhombusSmall_08';
import RhombusSmall_10 from './Shapes/1/RhombusSmall_10';
import RhombusSmall_15 from './Shapes/1.5/RhombusSmall_15';
import Triangle_02 from './Shapes/0.2/Triangle_02';
import Triangle_05 from './Shapes/0.5/Triangle_05';
import Triangle_08 from './Shapes/0.8/Triangle_08';
import Triangle_10 from './Shapes/1/Triangle_10';
import Triangle_15 from './Shapes/1.5/Triangle_15';
import TriangleSmall_08 from './Shapes/0.8/TriangleSmall_08';
import TriangleSmall_15 from './Shapes/1.5/TriangleSmall_15';
import TriangleSmall_10 from './Shapes/1/TriangleSmall_10';
import TriangleSmall_05 from './Shapes/0.5/TriangleSmall_05';
import TriangleSmall_02 from './Shapes/0.2/TriangleSmall_02';
import Square_08 from './Shapes/0.8/Square_08';
import Square_15 from './Shapes/1.5/Square_15';
import Square_10 from './Shapes/1/Square_10';
import Square_05 from './Shapes/0.5/Square_05';
import Square_02 from './Shapes/0.2/Square_02';
import SquareSmall_08 from './Shapes/0.8/SquareSmall_08';
import SquareSmall_15 from './Shapes/1.5/SquareSmall_15';
import SquareSmall_10 from './Shapes/1/SquareSmall_10';
import SquareSmall_05 from './Shapes/0.5/SquareSmall_05';
import SquareSmall_02 from './Shapes/0.2/SquareSmall_02';
import Rhombus_08 from './Shapes/0.8/Rhombus_08';
import Rhombus_15 from './Shapes/1.5/Rhombus_15';
import Rhombus_10 from './Shapes/1/Rhombus_10';
import Rhombus_05 from './Shapes/0.5/Rhombus_05';
import Rhombus_02 from './Shapes/0.2/Rhombus_02';

interface IFigureParams {
    id: number;
    shapeWidth: number;
    shapeHeight: number;
    shapeRotate: number;
    shape: Shape;
    shapeArr: Shape[];
    stripesWidth: number;
    shapePosition: ShapePosition;
    selectedShape: number;
    anyAction: boolean;
    fill: string;
}

let selectFigure: any = [];
let figureResetFlag: boolean = false;

export function Figure(params: IFigureParams) { 
    if(params.shapeArr[params.selectedShape]?.width && params.shapeArr[params.selectedShape]?.height) {
        localStorage.setItem("activeShapeWidth", params.shapeArr[params.selectedShape].width.toString())
        localStorage.setItem("activeShapeHeight", params.shapeArr[params.selectedShape].height.toString())
    }    

    const handleDrag = d3.drag().on('drag', (event) => {
        const config: IConfig = loadConfig();
        const workspaceDimensions = config.application.workspaceDimensions;
        let activeShapeWidth = 0;
        let activeShapeHeight = 0;
        if(localStorage.getItem("activeShapeWidth")) {
            activeShapeWidth = Number(localStorage.getItem("activeShapeWidth"))
        }
        if(localStorage.getItem("activeShapeHeight")) {
            activeShapeHeight = Number(localStorage.getItem("activeShapeHeight"))
        }

        let newLeft = params.shapePosition.left + event.dx;
        let newTop = params.shapePosition.top + event.dy;

        if (
            newLeft >= ((activeShapeWidth - (activeShapeWidth - activeShapeWidth / 4)) * -1) && 
            newLeft <= (workspaceDimensions.width + activeShapeWidth / 4) && 
            newTop >= ((activeShapeHeight - (activeShapeHeight - activeShapeHeight / 4)) * -1) && 
            newTop <= (workspaceDimensions.height + activeShapeHeight / 4)
        ) {
            params.shapePosition.left = newLeft;
            params.shapePosition.top = newTop;
        }

    });
    
    useEffect(() => {
        if(params.anyAction === false && figureResetFlag === false) {
            figureResetFlag = true;
            selectFigure = [];
        } else if(params.anyAction === true) {
            figureResetFlag = false;
        }

        if(params.id === params.selectedShape && typeof selectFigure[params.id] === "undefined") {
            selectFigure[params.id] = d3.select(`#${params.shape.name}${params.id}`);
            handleDrag(selectFigure[params.id]);
        }
    }, [params.shapeArr[params.selectedShape]]);

    const size: number = 100;
    const sizeOfOne: number = params.stripesWidth;
    const count: number = size / sizeOfOne;
    let index = 0;

    let array = [];
    while (index < count) {
        array.push(new Stripe("black", index * sizeOfOne, sizeOfOne))
        index++;
        array.push(new Stripe("black", index * sizeOfOne, sizeOfOne))
        index += 2;
    }

    let rects = array.map(stripe => <rect key={Math.random()} fill={stripe.fill} height={`${params.shapeHeight}px`} width={`${stripe.width}%`} x={`${stripe.xPos}%`}></rect>);
    let shape;

    if(params.shape.tagName !== 'hidden') {
        switch (params.shape.title) {
            case 'circleSmall':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <CircleSmall_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <CircleSmall_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <CircleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <CircleSmall_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <CircleSmall_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <CircleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'circle':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Circle_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Circle_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Circle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>                    
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Circle_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>                    
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Circle_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>                    
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Circle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>                    
                }
                break;
            case 'ellipseSmall':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <EllipseSmall_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <EllipseSmall_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <EllipseSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <EllipseSmall_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>  
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <EllipseSmall_15 fill={params.fill} id={`figure${params.id}`} />
                    </g> 
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <EllipseSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'ellipse':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Ellipse_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Ellipse_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Ellipse_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Ellipse_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Ellipse_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Ellipse_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'invertedTriangleSmall':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangleSmall_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangleSmall_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangleSmall_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>  
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangleSmall_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'invertedTriangle':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangle_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangle_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangle_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangle_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate + 180}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <InvertedTriangle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'rectangleSmall':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RectangleSmall_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RectangleSmall_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RectangleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RectangleSmall_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RectangleSmall_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RectangleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'rectangle':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rectangle_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rectangle_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rectangle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rectangle_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rectangle_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rectangle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'rhombusSmall':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RhombusSmall_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RhombusSmall_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RhombusSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RhombusSmall_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RhombusSmall_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <RhombusSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'rhombus':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rhombus_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rhombus_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rhombus_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rhombus_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rhombus_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate - 45}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Rhombus_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'squareSmall':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <SquareSmall_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <SquareSmall_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <SquareSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <SquareSmall_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <SquareSmall_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <SquareSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'square':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Square_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Square_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Square_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Square_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Square_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Square_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'triangleSmall':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <TriangleSmall_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <TriangleSmall_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <TriangleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <TriangleSmall_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <TriangleSmall_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <TriangleSmall_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
            case 'triangle':
                if(params.stripesWidth === 0.2) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Triangle_02 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Triangle_05 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 0.8) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Triangle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Triangle_10 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else if (params.stripesWidth === 1.5) {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Triangle_15 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                } else {
                    shape = <g style={{ transform: `rotate(${params.shapeRotate}deg) translate(-${params.shapeWidth /2}px, -${params.shapeHeight / 2}px)`}}>
                        <Triangle_08 fill={params.fill} id={`figure${params.id}`} />
                    </g>
                }
                break;
        }
    }

    return <g id={`${params.shape.name}${params.id}`} data-selected={params.id === params.selectedShape} fillRule="evenodd" fill={params.fill} style={{ pointerEvents: `${params.selectedShape === params.id ? 'auto' : 'none'}`, cursor: `${params.selectedShape === params.id ? 'move' : 'not-allowed'}`, transform: `translate(${params.shapePosition.left}px, ${params.shapePosition.top}px)` }}>
        <defs>
            <mask id={`stripes${params.id}`}>
                <rect width={`${params.shape.width}px`} height={`${params.shape.height}px`} fill="white" />
                {rects}
            </mask>
        </defs>

        {shape}
    </g>;
}

import React from 'react';

interface ISquare_15 {
    fill: string;
    id?: string;
}

const Square_15: React.FC<ISquare_15> = ({ fill, id }) => {
  return (
    <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_2933" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="300" height="300">
    <rect y="-833" width="12" height="1961" fill="black"/>
    <rect x="36" y="-833" width="12" height="1961" fill="black"/>
    <rect x="72" y="-833" width="12" height="1961" fill="black"/>
    <rect x="108" y="-833" width="12" height="1961" fill="black"/>
    <rect x="144" y="-833" width="12" height="1961" fill="black"/>
    <rect x="180" y="-833" width="12" height="1961" fill="black"/>
    <rect x="216" y="-833" width="12" height="1961" fill="black"/>
    <rect x="252" y="-833" width="12" height="1961" fill="black"/>
    <rect x="288" y="-833" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_2933)">
    <rect width="300" height="300" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Square_15;

import React from 'react';

interface IEllipse_15 {
    fill: string;
    id?: string;
}

const Ellipse_15: React.FC<IEllipse_15> = ({ fill, id }) => {
  return (
    <svg width="444" height="200" viewBox="0 0 444 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_3149" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="444" height="200">
    <rect y="-881" width="12" height="1961" fill="black"/>
    <rect x="36" y="-881" width="12" height="1961" fill="black"/>
    <rect x="72" y="-881" width="12" height="1961" fill="black"/>
    <rect x="108" y="-881" width="12" height="1961" fill="black"/>
    <rect x="144" y="-881" width="12" height="1961" fill="black"/>
    <rect x="180" y="-881" width="12" height="1961" fill="black"/>
    <rect x="216" y="-881" width="12" height="1961" fill="black"/>
    <rect x="252" y="-881" width="12" height="1961" fill="black"/>
    <rect x="288" y="-881" width="12" height="1961" fill="black"/>
    <rect x="324" y="-881" width="12" height="1961" fill="black"/>
    <rect x="360" y="-881" width="12" height="1961" fill="black"/>
    <rect x="396" y="-881" width="12" height="1961" fill="black"/>
    <rect x="432" y="-881" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_3149)">
    <ellipse cx="222" cy="100" rx="222" ry="100" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Ellipse_15;

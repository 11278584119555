import React from 'react';

interface ISquareSmall_05 {
    fill: string;
    id?: string;
}

const SquareSmall_05: React.FC<ISquareSmall_05> = ({ fill, id }) => {
  return (
    <svg width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1611" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="148" height="148">
    <rect y="-562" width="4" height="1272" fill="black"/>
    <rect x="12" y="-562" width="4" height="1272" fill="black"/>
    <rect x="24" y="-562" width="4" height="1272" fill="black"/>
    <rect x="36" y="-562" width="4" height="1272" fill="black"/>
    <rect x="48" y="-562" width="4" height="1272" fill="black"/>
    <rect x="60" y="-562" width="4" height="1272" fill="black"/>
    <rect x="72" y="-562" width="4" height="1272" fill="black"/>
    <rect x="84" y="-562" width="4" height="1272" fill="black"/>
    <rect x="96" y="-562" width="4" height="1272" fill="black"/>
    <rect x="108" y="-562" width="4" height="1272" fill="black"/>
    <rect x="120" y="-562" width="4" height="1272" fill="black"/>
    <rect x="132" y="-562" width="4" height="1272" fill="black"/>
    <rect x="144" y="-562" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1611)">
    <rect width="148" height="148" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default SquareSmall_05;

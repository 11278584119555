export class Palette {
    colors: string[] = ["#3366FF", "#4FFF4F", "#CC6666"];
    counter: number = 0;

    constructor(colors : string[]){
        this.colors = colors;
    }

    resetCounter() {
        this.counter = 0;
    }

    next(): string {
        return this.colors[this.counter++ % this.colors.length];
    }
}
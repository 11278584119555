import React from 'react';

interface ITriangle_05 {
    fill: string;
    id?: string;
}

const Triangle_05: React.FC<ITriangle_05> = ({ fill, id }) => {
  return (
    <svg width="366" height="302" viewBox="0 0 366 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1189" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="366" height="302">
    <rect x="1" y="-485" width="4" height="1272" fill="black"/>
    <rect x="13" y="-485" width="4" height="1272" fill="black"/>
    <rect x="25" y="-485" width="4" height="1272" fill="black"/>
    <rect x="37" y="-485" width="4" height="1272" fill="black"/>
    <rect x="49" y="-485" width="4" height="1272" fill="black"/>
    <rect x="61" y="-485" width="4" height="1272" fill="black"/>
    <rect x="73" y="-485" width="4" height="1272" fill="black"/>
    <rect x="85" y="-485" width="4" height="1272" fill="black"/>
    <rect x="97" y="-485" width="4" height="1272" fill="black"/>
    <rect x="109" y="-485" width="4" height="1272" fill="black"/>
    <rect x="121" y="-485" width="4" height="1272" fill="black"/>
    <rect x="133" y="-485" width="4" height="1272" fill="black"/>
    <rect x="145" y="-485" width="4" height="1272" fill="black"/>
    <rect x="157" y="-485" width="4" height="1272" fill="black"/>
    <rect x="169" y="-485" width="4" height="1272" fill="black"/>
    <rect x="181" y="-485" width="4" height="1272" fill="black"/>
    <rect x="193" y="-485" width="4" height="1272" fill="black"/>
    <rect x="205" y="-485" width="4" height="1272" fill="black"/>
    <rect x="217" y="-485" width="4" height="1272" fill="black"/>
    <rect x="229" y="-485" width="4" height="1272" fill="black"/>
    <rect x="241" y="-485" width="4" height="1272" fill="black"/>
    <rect x="253" y="-485" width="4" height="1272" fill="black"/>
    <rect x="265" y="-485" width="4" height="1272" fill="black"/>
    <rect x="277" y="-485" width="4" height="1272" fill="black"/>
    <rect x="289" y="-485" width="4" height="1272" fill="black"/>
    <rect x="301" y="-485" width="4" height="1272" fill="black"/>
    <rect x="313" y="-485" width="4" height="1272" fill="black"/>
    <rect x="325" y="-485" width="4" height="1272" fill="black"/>
    <rect x="337" y="-485" width="4" height="1272" fill="black"/>
    <rect x="349" y="-485" width="4" height="1272" fill="black"/>
    <rect x="361" y="-485" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1189)">
    <path d="M183 0L366 302H0L183 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Triangle_05;

import QRCode from "react-qr-code";
import { createPrintUrl, getShareUrl } from "./Bottom/Footer/delegate";
import { IPrintParams } from "./Bottom/Footer/IFooterParams";
import './QrCode.css';
import { SharePopUp } from "./SharePopUp/SharePopUp";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { MoreTimePopUp } from "./MoreTimePopUp/MoreTimePopUp";

// const queryParams = new URLSearchParams(window.location.search);

export interface IQrCodeParams extends IPrintParams {
    back: () => void;
    onResetToDefault: () => void;
    stopTimer: () => void;
    showShareUrl: boolean;
}

function QrCode(params: IQrCodeParams) {
    const { t } = useTranslation();
    const [showMoreTimePopUp, setShowMoreTimePopUp] = useState(false);
    let qrParams = createPrintUrl(params, false, false)
        // aditinoal step to generate download link from button not directly
        .replace("print=false", "download=true");

    const startTimer = () => {
        const timer = setTimeout(() => {
            setShowMoreTimePopUp(true);
        }, 30000);

        return () => clearTimeout(timer);
    };

    useEffect(() => {
        const timer = startTimer();
        return timer;
    }, []);

    const resetTimer = () => {
        setShowMoreTimePopUp(false);
        startTimer();
    };

    if(showMoreTimePopUp) {
        return <MoreTimePopUp onToggleTimerPopUp={() => resetTimer()} show={showMoreTimePopUp} onResetToDefault={() => params.onResetToDefault()} stopTimer={() => params.stopTimer()} />;
    }

    if(params.showShareUrl) {
        return <div className="qr__center">
            <h2>{t("qrTitle")}</h2>
                        
            <QRCode value={qrParams} level="H" size={350} />

            <SharePopUp show={true} url={getShareUrl(params)} notModal={true} onToggleSharePopUp={() => {return true}} />

            <button className="qr__back-button" onClick={params.onResetToDefault}>{t("createNewArt")}</button>
        </div>
    } else {
        return <div className="qr__center">
            <QRCode value={qrParams} level="H" size={512} />

            <button className="qr__back-button" onClick={() => { params.onResetToDefault(); params.stopTimer(); window.location.reload(); }}>{t("createNewArt")}</button>
            {/* <button className="qr__back-button" onClick={params.onResetToDefault}>{t("back")}</button> */}
        </div>
    }
}

export default QrCode;

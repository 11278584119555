import React from 'react';

interface IRectangleSmall_15 {
    fill: string;
    id?: string;
}

const RectangleSmall_15: React.FC<IRectangleSmall_15> = ({ fill, id }) => {
  return (
    <svg width="300" height="111" viewBox="0 0 300 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_3112" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="300" height="111">
    <rect y="-925" width="12" height="1961" fill="black"/>
    <rect x="36" y="-925" width="12" height="1961" fill="black"/>
    <rect x="72" y="-925" width="12" height="1961" fill="black"/>
    <rect x="108" y="-925" width="12" height="1961" fill="black"/>
    <rect x="144" y="-925" width="12" height="1961" fill="black"/>
    <rect x="180" y="-925" width="12" height="1961" fill="black"/>
    <rect x="216" y="-925" width="12" height="1961" fill="black"/>
    <rect x="252" y="-925" width="12" height="1961" fill="black"/>
    <rect x="288" y="-925" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_3112)">
    <rect width="300" height="111" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RectangleSmall_15;

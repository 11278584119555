import { IConfig } from "../../../utils/IConfig";
import { loadConfig } from "../../../utils/IConfigUtils";
import { IPrintParams } from "./IFooterParams";

const createPrintUrl = (params: IPrintParams, print: boolean, paramsOnly: boolean): string => {
    const config: IConfig = loadConfig();

    let colorsString = "";
    params.colors.forEach((color, index) => colorsString += `_c${index + 1}:${color.replace("#", "")}`);

    let shapesString = "";
    params.shapes.shapes.forEach((shape, index) => {
        if (shape.tagName !== 'hidden') {
            shapesString += `_shape${index + 1}-name:${shape.name}_shape${index + 1}-rotate:${shape.rotate}`;
        }
    });

    let shapePositionsString = "";
    params.shapePositions.forEach((shape, index) => shapePositionsString += `_shape${index + 1}-left:${shape.left}_shape${index + 1}-top:${shape.top}`);

    let shapeColorsString = "";
    params.shapeColors.forEach((color, index) => shapeColorsString += `_shape${index + 1}-color:${color.replace("#", "")}`);
    
    let logoImg = ``;
    if(config.application.folderName && config.application.logoPdfFileName) {
        logoImg = `_logoUrl:${config.application.folderName}/${config.application.logoPdfFileName}`;
    }

    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/');
    const languages = ["en", "fr", "es", "ko", "zh", "pl", "pt"]
    const languageFromUrl = pathSegments[1] && languages.includes(pathSegments[1])
    ? pathSegments[1]
    : pathSegments[2];
    let languageParam = `${languageFromUrl ? languageFromUrl : config.application.selectedLanguage}`;
    let languageString = `_lang:${languageParam}`

    let justParams = `?print=${print}_intro:false_stripesWidth:${params.stripesWidth}${colorsString}${shapesString}${shapePositionsString}${languageString}${shapeColorsString}${logoImg}`;
    let urlForPrint = `${config.environment.selfUrlForPrintService}${languageParam}${justParams}`;
    return print ? `${config.environment.printServiceUrl}?url=${urlForPrint}` : (paramsOnly ? justParams : urlForPrint);
}

const download = (href: string) => {
    let link = document.createElement('a');
    link.href = href;
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));
}

const downloadPdf = (params: IPrintParams) => {
    download(createPrintUrl(params, true, false))
}

const renderQr = (params: IPrintParams) => {
    let link = document.createElement('a');
    var qrcode = createPrintUrl(params, false, false)
        // aditinoal step to generate download link from button not directly
        .replace("print=false", "download=true");
    var href = `/?qrcode=${qrcode}&params=${createPrintUrl(params, false, true)}`;
    link.href = href;
    link.dispatchEvent(new MouseEvent('click'));
}

const getShareUrl = (params: IPrintParams) => {
    return `${window.location.origin}${createPrintUrl(params, false, true)}`;
}

export { downloadPdf, download, renderQr, getShareUrl, createPrintUrl }
export class ShapePosition {
    shapeId: number = 0;
    left: number = 0;
    top: number = 0;

    constructor(shapeId: number, left: number, top: number) {
        this.shapeId = shapeId;
        this.left = left;
        this.top = top;
    }
}
import { Shape } from '../components/Shape';
import { User } from '../components/User';

export interface IApplicationConfig {
    configDate: string,
    folderName: string,
    timer: number;
    stripesWidths: number[];
    maxShapeRotationDegree: number;
    stripesWidth: number;
    shapePositions: any[];
    stripeColors: string[];
    selectedLanguage: string;
    shapeRotationDegree: {
        left: number;
        right: number;
    };
    shapePalette: Shape[];
    initShapes: Shape[];
    users: User[];
    logoPdfFileName: string;
    workspaceDimensions: {
        width: number;
        height: number;
    },
    startLogoLeft: {
        hide: boolean;
        src: string;
        name: string;
    },
    startLogoRight: {
        hide: boolean;
        src: string;
        name: string;
    },
    artworkLogoLeft: {
        hide: boolean;
        src: string;
        name: string;
    },
    artworkLogoRight: {
        hide: boolean;
        src: string;
        name: string;
    },
    hideIcon: {
        print: boolean;
        share: boolean;
        qrcode: boolean;
        demo: boolean;
    }
}

export class ApplicationConfigFactory {
    static parseFromJson(json: string, defaultAppConfig: IApplicationConfig): IApplicationConfig {
        let app = JSON.parse(json!) as IApplicationConfig;

        app.users = app.users.map(old => {
            let newItem = new User();
            Object.assign(newItem, old);
            return newItem;
        });

        app.initShapes = app.initShapes.map(old => {
            let newItem = new Shape();
            Object.assign(newItem, old);
            return newItem;
        });

        app.shapePalette = app.shapePalette.map(old => {
            let newItem = new Shape();
            Object.assign(newItem, old);
            return newItem;
        });

        let savedConfig = app as any;
        let defaultConfig = defaultAppConfig as any;

        for (let prop in defaultConfig) {
            if (Object.prototype.hasOwnProperty.call(defaultConfig, prop) && typeof savedConfig[prop] !== "undefined") {
                defaultConfig[prop] = savedConfig[prop];
            }
        }

        return defaultConfig as IApplicationConfig;
    }
}
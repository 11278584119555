import React from 'react';

interface ISquareSmall {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const SquareSmall: React.FC<ISquareSmall> = ({ fill, id, width, height }) => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12_197" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="84" height="84">
    <rect width="1.95349" height="84" fill="black"/>
    <rect x="35.1627" width="1.95349" height="84" fill="black"/>
    <rect x="17.5813" width="1.95349" height="84" fill="black"/>
    <rect x="52.7443" width="1.95349" height="84" fill="black"/>
    <rect x="70.3256" width="1.95349" height="84" fill="black"/>
    <rect x="5.86047" width="1.95349" height="84" fill="black"/>
    <rect x="41.0234" width="1.95349" height="84" fill="black"/>
    <rect x="23.4419" width="1.95349" height="84" fill="black"/>
    <rect x="58.6047" width="1.95349" height="84" fill="black"/>
    <rect x="76.1859" width="1.95349" height="84" fill="black"/>
    <rect x="11.7211" width="1.95349" height="84" fill="black"/>
    <rect x="46.8838" width="1.95349" height="84" fill="black"/>
    <rect x="29.3024" width="1.95349" height="84" fill="black"/>
    <rect x="64.4653" width="1.95349" height="84" fill="black"/>
    <rect x="82.0464" width="1.95349" height="84" fill="black"/>
    </mask>
    <g mask="url(#mask0_12_197)">
    <rect x="17" y="17" width="50" height="50" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default SquareSmall;

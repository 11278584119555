import React from 'react';

interface ITriangleSmall_15 {
    fill: string;
    id?: string;
}

const TriangleSmall_15: React.FC<ITriangleSmall_15> = ({ fill, id }) => {
  return (
    <svg width="228" height="156" viewBox="0 0 228 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_2752" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="228" height="156">
    <rect y="-903" width="12" height="1961" fill="black"/>
    <rect x="36" y="-903" width="12" height="1961" fill="black"/>
    <rect x="72" y="-903" width="12" height="1961" fill="black"/>
    <rect x="108" y="-903" width="12" height="1961" fill="black"/>
    <rect x="144" y="-903" width="12" height="1961" fill="black"/>
    <rect x="180" y="-903" width="12" height="1961" fill="black"/>
    <rect x="216" y="-903" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_2752)">
    <path d="M114 0L228 156H0L114 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default TriangleSmall_15;

import React from 'react';

interface ISquareSmall_10 {
    fill: string;
    id?: string;
}

const SquareSmall_10: React.FC<ISquareSmall_10> = ({ fill, id }) => {
  return (
    <svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2308" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="152" height="152">
    <rect y="-643" width="8" height="1437" fill="black"/>
    <rect x="24" y="-643" width="8" height="1437" fill="black"/>
    <rect x="48" y="-643" width="8" height="1437" fill="black"/>
    <rect x="72" y="-643" width="8" height="1437" fill="black"/>
    <rect x="96" y="-643" width="8" height="1437" fill="black"/>
    <rect x="120" y="-643" width="8" height="1437" fill="black"/>
    <rect x="144" y="-643" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2308)">
    <rect width="152" height="152" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default SquareSmall_10;

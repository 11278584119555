import { loadConfig } from "../utils/IConfigUtils";
import { IConfig } from "../utils/IConfig";
const config: IConfig = loadConfig();

export class StripeWidthList {
    widths: number[] = config.application.stripesWidths;
    counter: number = 0;

    constructor(counter: number = 0) {
        this.counter = counter;
    }

    current(): number {
        return this.widths[this.counter % this.widths.length];
    }

    next(): number {
        return this.widths[this.counter++ % this.widths.length];
    }
}

import { IIntroParams } from './IIntroParams';
import './Intro.css';
import Lottie from 'react-lottie';
import animationData from '../assets/lotties/CruzDiezIntroWithoutText.json';
import { IConfig } from '../utils/IConfig';
import { loadConfig } from '../utils/IConfigUtils';
import { useTranslation } from 'react-i18next';
import { FooterIcon } from './Bottom/FooterIcon/FooterIcon';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

const config: IConfig = loadConfig();

export function Intro(params: IIntroParams) {
	const { t } = useTranslation();
	const logoLeft = require(`./../assets/img/intro/logo-left.png`);
	const logoRight = require(`./../assets/img/intro/logo-right.png`);
	
	return (
		<div className={`App__content ${params.show ? '' : 'hide'}`}>
			{/* <p className="App__version">{config.environment.version}</p> */}

			<div className="App__content--left--intro"></div>

			<div className="App__content--center">
				<div style={{minHeight: '40px'}}></div>
			
				<div className="intro__animation-wrapper">
					<Lottie 
						options={defaultOptions}
						height={560}
						width={755}
						isStopped={false}
						isPaused={false}
					/>
					<p className='intro__animation--artist-name'>{t("artistName")}</p>
					<p className='intro__animation--artwork-name'>{t("artworkName1")} <br/> {t("artworkName2")} <br/> {t("artworkName3")} <br/> {t("artworkName4")}</p>
					<p className='intro__animation--tag-line-main'>{t("tagLineMain1")} <br/> {t("tagLineMain2")}</p>
				</div>

				<div className="intro__footer">
					<img className={`${config.application.startLogoLeft.hide ? 'hide-opacity' : ''}`} alt={"Logo left side"}
						src={config.application.startLogoLeft.src.length > 0 ? config.application.startLogoLeft.src : logoLeft.default} />

					<button className="intro__button" onClick={() => {params.onToggleIntro(false); params.handleAction(true)}}>{t("enter")}</button>
					
					<img className={`${config.application.startLogoRight.hide ? 'hide-opacity' : ''}`} alt={"Logo right side"}
						src={config.application.startLogoRight.src.length > 0 ? config.application.startLogoRight.src : logoRight.default} />
				</div>
				<div style={{marginBottom: '10px', marginLeft: '10px'}}>
					<FooterIcon class={`footer__icon footer__icon--settings ${params.onToggleSettingsPopUp ? '' : 'hide'}`} alt="Settings" title={t("openSettingsWindow")} imagePath="assets/img/icons/settings.svg" onClickAction={() => params.onToggleSettingsPopUp!()} />
				</div>
			</div>

			<div className="App__content--right"></div>
		</div>
	);
}
import React, { useEffect } from 'react';
import { IMoreTimePopUpParams } from "./IMoreTimePopUpParams";
import './MoreTimePopUp.css';
import { useTranslation } from 'react-i18next';

export function MoreTimePopUp(params: IMoreTimePopUpParams) {
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(() => {
            params.onResetToDefault(); 
            params.stopTimer(); 
            window.location.reload();
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return <div className={`more-time-pop-up more-time-pop-up--${(params.show ? 'show' : 'hide')}`}>
        <p className="more-time-pop-up__text">{t("moreTimeTxt")}</p>
        <button className="more-time-pop-up__button" onClick={() => { params.onToggleTimerPopUp(); }}>{t("moreTimeBtn")}</button>
    </div>;
}
import React from 'react';

interface IEllipseSmall_08 {
    fill: string;
    id?: string;
}

const EllipseSmall_08: React.FC<IEllipseSmall_08> = ({ fill, id }) => {
  return (
    <svg width="204" height="107" viewBox="0 0 204 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_516_2353" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="204" height="107">
    <rect x="180" y="-91" width="6" height="261" fill="black"/>
    <rect x="36" y="-91" width="6" height="261" fill="black"/>
    <rect x="108" y="-91" width="6" height="261" fill="black"/>
    <rect x="72" y="-91" width="6" height="261" fill="black"/>
    <rect y="-91" width="6" height="261" fill="black"/>
    <rect x="144" y="-91" width="6" height="261" fill="black"/>
    <rect x="198" y="-91" width="6" height="261" fill="black"/>
    <rect x="54" y="-91" width="6" height="261" fill="black"/>
    <rect x="126" y="-91" width="6" height="261" fill="black"/>
    <rect x="90" y="-91" width="6" height="261" fill="black"/>
    <rect x="18" y="-91" width="6" height="261" fill="black"/>
    <rect x="162" y="-91" width="6" height="261" fill="black"/>
    </mask>
    <g mask="url(#mask0_516_2353)">
    <ellipse cx="102" cy="53.5" rx="102" ry="53.5" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default EllipseSmall_08;

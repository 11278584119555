import React from 'react';

interface IRhombusSmall_02 {
    fill: string;
    id?: string;
}

const RhombusSmall_02: React.FC<IRhombusSmall_02> = ({ fill, id }) => {
  return (
    <svg width="224" height="224" viewBox="0 0 224 224" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_545" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="224" height="224">
    <rect x="15.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="63.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="39.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="87.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="27.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="75.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="51.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="3.00049" y="-601" width="2" height="1426" fill="black"/>
    <rect x="99.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="21.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="69.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="45.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="93.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="33.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="81.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="57.0005" y="-601" width="2" height="1426" fill="black"/>
    <rect x="9.00049" y="-601" width="2" height="1426" fill="black"/>
    <rect x="105" y="-601" width="2" height="1426" fill="black"/>
    <rect x="111" y="-601" width="2" height="1426" fill="black"/>
    <rect x="207" y="-601" width="2" height="1426" fill="black"/>
    <rect x="159" y="-601" width="2" height="1426" fill="black"/>
    <rect x="135" y="-601" width="2" height="1426" fill="black"/>
    <rect x="183" y="-601" width="2" height="1426" fill="black"/>
    <rect x="123" y="-601" width="2" height="1426" fill="black"/>
    <rect x="219" y="-601" width="2" height="1426" fill="black"/>
    <rect x="171" y="-601" width="2" height="1426" fill="black"/>
    <rect x="147" y="-601" width="2" height="1426" fill="black"/>
    <rect x="195" y="-601" width="2" height="1426" fill="black"/>
    <rect x="117" y="-601" width="2" height="1426" fill="black"/>
    <rect x="213" y="-601" width="2" height="1426" fill="black"/>
    <rect x="165" y="-601" width="2" height="1426" fill="black"/>
    <rect x="141" y="-601" width="2" height="1426" fill="black"/>
    <rect x="189" y="-601" width="2" height="1426" fill="black"/>
    <rect x="129" y="-601" width="2" height="1426" fill="black"/>
    <rect x="177" y="-601" width="2" height="1426" fill="black"/>
    <rect x="153" y="-601" width="2" height="1426" fill="black"/>
    <rect x="201" y="-601" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_545)">
    <rect x="112" width="158.392" height="158.392" transform="rotate(45 112 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RhombusSmall_02;

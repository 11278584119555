import React from 'react';

interface IRectangle_02 {
    fill: string;
    id?: string;
}

const Rectangle_02: React.FC<IRectangle_02> = ({ fill, id }) => {
  return (
    <svg width="398" height="200" viewBox="0 0 398 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_676" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="398" height="200">
    <rect x="6" y="-613" width="2" height="1426" fill="black"/>
    <rect x="102" y="-613" width="2" height="1426" fill="black"/>
    <rect x="54" y="-613" width="2" height="1426" fill="black"/>
    <rect x="150" y="-613" width="2" height="1426" fill="black"/>
    <rect x="30" y="-613" width="2" height="1426" fill="black"/>
    <rect x="126" y="-613" width="2" height="1426" fill="black"/>
    <rect x="78" y="-613" width="2" height="1426" fill="black"/>
    <rect x="174" y="-613" width="2" height="1426" fill="black"/>
    <rect x="18" y="-613" width="2" height="1426" fill="black"/>
    <rect x="114" y="-613" width="2" height="1426" fill="black"/>
    <rect x="66" y="-613" width="2" height="1426" fill="black"/>
    <rect x="162" y="-613" width="2" height="1426" fill="black"/>
    <rect x="42" y="-613" width="2" height="1426" fill="black"/>
    <rect x="138" y="-613" width="2" height="1426" fill="black"/>
    <rect x="90" y="-613" width="2" height="1426" fill="black"/>
    <rect x="186" y="-613" width="2" height="1426" fill="black"/>
    <rect x="12" y="-613" width="2" height="1426" fill="black"/>
    <rect x="108" y="-613" width="2" height="1426" fill="black"/>
    <rect x="60" y="-613" width="2" height="1426" fill="black"/>
    <rect x="156" y="-613" width="2" height="1426" fill="black"/>
    <rect x="36" y="-613" width="2" height="1426" fill="black"/>
    <rect x="132" y="-613" width="2" height="1426" fill="black"/>
    <rect x="84" y="-613" width="2" height="1426" fill="black"/>
    <rect x="180" y="-613" width="2" height="1426" fill="black"/>
    <rect x="24" y="-613" width="2" height="1426" fill="black"/>
    <rect x="120" y="-613" width="2" height="1426" fill="black"/>
    <rect x="72" y="-613" width="2" height="1426" fill="black"/>
    <rect x="168" y="-613" width="2" height="1426" fill="black"/>
    <rect x="48" y="-613" width="2" height="1426" fill="black"/>
    <rect x="144" y="-613" width="2" height="1426" fill="black"/>
    <rect x="96" y="-613" width="2" height="1426" fill="black"/>
    <rect y="-613" width="2" height="1426" fill="black"/>
    <rect x="192" y="-613" width="2" height="1426" fill="black"/>
    <rect x="198" y="-613" width="2" height="1426" fill="black"/>
    <rect x="390" y="-613" width="2" height="1426" fill="black"/>
    <rect x="294" y="-613" width="2" height="1426" fill="black"/>
    <rect x="246" y="-613" width="2" height="1426" fill="black"/>
    <rect x="342" y="-613" width="2" height="1426" fill="black"/>
    <rect x="222" y="-613" width="2" height="1426" fill="black"/>
    <rect x="318" y="-613" width="2" height="1426" fill="black"/>
    <rect x="270" y="-613" width="2" height="1426" fill="black"/>
    <rect x="366" y="-613" width="2" height="1426" fill="black"/>
    <rect x="210" y="-613" width="2" height="1426" fill="black"/>
    <rect x="306" y="-613" width="2" height="1426" fill="black"/>
    <rect x="258" y="-613" width="2" height="1426" fill="black"/>
    <rect x="354" y="-613" width="2" height="1426" fill="black"/>
    <rect x="234" y="-613" width="2" height="1426" fill="black"/>
    <rect x="330" y="-613" width="2" height="1426" fill="black"/>
    <rect x="282" y="-613" width="2" height="1426" fill="black"/>
    <rect x="378" y="-613" width="2" height="1426" fill="black"/>
    <rect x="204" y="-613" width="2" height="1426" fill="black"/>
    <rect x="396" y="-613" width="2" height="1426" fill="black"/>
    <rect x="300" y="-613" width="2" height="1426" fill="black"/>
    <rect x="252" y="-613" width="2" height="1426" fill="black"/>
    <rect x="348" y="-613" width="2" height="1426" fill="black"/>
    <rect x="228" y="-613" width="2" height="1426" fill="black"/>
    <rect x="324" y="-613" width="2" height="1426" fill="black"/>
    <rect x="276" y="-613" width="2" height="1426" fill="black"/>
    <rect x="372" y="-613" width="2" height="1426" fill="black"/>
    <rect x="216" y="-613" width="2" height="1426" fill="black"/>
    <rect x="312" y="-613" width="2" height="1426" fill="black"/>
    <rect x="264" y="-613" width="2" height="1426" fill="black"/>
    <rect x="360" y="-613" width="2" height="1426" fill="black"/>
    <rect x="240" y="-613" width="2" height="1426" fill="black"/>
    <rect x="336" y="-613" width="2" height="1426" fill="black"/>
    <rect x="288" y="-613" width="2" height="1426" fill="black"/>
    <rect x="384" y="-613" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_676)">
    <rect width="398" height="200" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rectangle_02;

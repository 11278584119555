import React from 'react';

interface IRhombusSmall_10 {
    fill: string;
    id?: string;
}

const RhombusSmall_10: React.FC<IRhombusSmall_10> = ({ fill, id }) => {
  return (
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2380" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="200" height="200">
    <rect x="0.00244141" y="-619" width="8" height="1437" fill="black"/>
    <rect x="24.0024" y="-619" width="8" height="1437" fill="black"/>
    <rect x="48.0024" y="-619" width="8" height="1437" fill="black"/>
    <rect x="72.0024" y="-619" width="8" height="1437" fill="black"/>
    <rect x="96.0024" y="-619" width="8" height="1437" fill="black"/>
    <rect x="120.002" y="-619" width="8" height="1437" fill="black"/>
    <rect x="144.002" y="-619" width="8" height="1437" fill="black"/>
    <rect x="168.002" y="-619" width="8" height="1437" fill="black"/>
    <rect x="192.002" y="-619" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2380)">
    <rect x="100" width="141.421" height="141.421" transform="rotate(45 100 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RhombusSmall_10;

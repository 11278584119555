import React from 'react';

interface ICircleSmall_10 {
  fill: string;
  id?: string;
}

const CircleSmall_10: React.FC<ICircleSmall_10> = ({ fill, id }) => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2236" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
    <rect x="-1" y="-644" width="8" height="1437" fill="black"/>
    <rect x="23" y="-644" width="8" height="1437" fill="black"/>
    <rect x="47" y="-644" width="8" height="1437" fill="black"/>
    <rect x="71" y="-644" width="8" height="1437" fill="black"/>
    <rect x="95" y="-644" width="8" height="1437" fill="black"/>
    <rect x="119" y="-644" width="8" height="1437" fill="black"/>
    <rect x="143" y="-644" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2236)">
    <circle cx="75" cy="75" r="75" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default CircleSmall_10;

import React from 'react';

interface IRectangleSmall_02 {
    fill: string;
    id?: string;
}

const RectangleSmall_02: React.FC<IRectangleSmall_02> = ({ fill, id }) => {
  return (
    <svg width="224" height="111" viewBox="0 0 224 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_807" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="224" height="111">
    <rect x="18" y="-658" width="2" height="1426" fill="black"/>
    <rect x="66" y="-658" width="2" height="1426" fill="black"/>
    <rect x="42" y="-658" width="2" height="1426" fill="black"/>
    <rect x="90" y="-658" width="2" height="1426" fill="black"/>
    <rect x="30" y="-658" width="2" height="1426" fill="black"/>
    <rect x="78" y="-658" width="2" height="1426" fill="black"/>
    <rect x="54" y="-658" width="2" height="1426" fill="black"/>
    <rect x="6" y="-658" width="2" height="1426" fill="black"/>
    <rect x="102" y="-658" width="2" height="1426" fill="black"/>
    <rect x="24" y="-658" width="2" height="1426" fill="black"/>
    <rect x="72" y="-658" width="2" height="1426" fill="black"/>
    <rect x="48" y="-658" width="2" height="1426" fill="black"/>
    <rect y="-658" width="2" height="1426" fill="black"/>
    <rect x="96" y="-658" width="2" height="1426" fill="black"/>
    <rect x="36" y="-658" width="2" height="1426" fill="black"/>
    <rect x="84" y="-658" width="2" height="1426" fill="black"/>
    <rect x="60" y="-658" width="2" height="1426" fill="black"/>
    <rect x="12" y="-658" width="2" height="1426" fill="black"/>
    <rect x="108" y="-658" width="2" height="1426" fill="black"/>
    <rect x="114" y="-658" width="2" height="1426" fill="black"/>
    <rect x="210" y="-658" width="2" height="1426" fill="black"/>
    <rect x="162" y="-658" width="2" height="1426" fill="black"/>
    <rect x="138" y="-658" width="2" height="1426" fill="black"/>
    <rect x="186" y="-658" width="2" height="1426" fill="black"/>
    <rect x="126" y="-658" width="2" height="1426" fill="black"/>
    <rect x="222" y="-658" width="2" height="1426" fill="black"/>
    <rect x="174" y="-658" width="2" height="1426" fill="black"/>
    <rect x="150" y="-658" width="2" height="1426" fill="black"/>
    <rect x="198" y="-658" width="2" height="1426" fill="black"/>
    <rect x="120" y="-658" width="2" height="1426" fill="black"/>
    <rect x="216" y="-658" width="2" height="1426" fill="black"/>
    <rect x="168" y="-658" width="2" height="1426" fill="black"/>
    <rect x="144" y="-658" width="2" height="1426" fill="black"/>
    <rect x="192" y="-658" width="2" height="1426" fill="black"/>
    <rect x="132" y="-658" width="2" height="1426" fill="black"/>
    <rect x="180" y="-658" width="2" height="1426" fill="black"/>
    <rect x="156" y="-658" width="2" height="1426" fill="black"/>
    <rect x="204" y="-658" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_807)">
    <rect width="224" height="111" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RectangleSmall_02;

import React, { useEffect } from 'react';
import { ITimerPopUpParams } from "./ITimerPopUpParams";
import './TimerPopUp.css';

export function TimerPopUp(params: ITimerPopUpParams) {
    useEffect(() => {
        let timer: any;
        if (params.show) {
            timer = setTimeout(() => {
                params.onResetToDefault(); 
                params.stopTimer(); 
                window.location.reload();
            }, 30000);
        }

        return () => clearTimeout(timer);
    }, [params.show]); 
    
    return <div className={`timer-pop-up timer-pop-up--${(params.show ? 'show' : 'hide')}`}>
        <p className="timer-pop-up__text">{params.text}</p>
        <button className="timer-pop-up__button" onClick={() => { params.onToggleTimerPopUp(false); }}>{params.buttonText}</button>
    </div>;
}
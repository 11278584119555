import React from 'react';

interface IInvertedTriangleSmall {
    fill: string;
    id?: string;
    width?: number;
    height?: number;
}

const InvertedTriangleSmall: React.FC<IInvertedTriangleSmall> = ({ fill, id, width, height }) => {
  return (
    <svg width="86" height="37" viewBox="0 0 86 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_29_306)">
    <mask id="mask0_29_306" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="86" height="37">
    <path d="M84 37H86V0H84V37Z" fill="black"/>
    <path d="M48 37H50L50 0H48L48 37Z" fill="black"/>
    <path d="M63 37H65V0H63L63 37Z" fill="black"/>
    <path d="M30 37H32L32 0H30L30 37Z" fill="black"/>
    <path d="M12 37H14L14 0H12L12 37Z" fill="black"/>
    <path d="M78 37H80V0H78V37Z" fill="black"/>
    <path d="M42 37H44L44 0H42L42 37Z" fill="black"/>
    <path d="M60 37H62L62 0H60L60 37Z" fill="black"/>
    <path d="M24 37H26L26 0H24L24 37Z" fill="black"/>
    <path d="M6 37H8L8 0H6L6 37Z" fill="black"/>
    <path d="M72 37H74V0H72V37Z" fill="black"/>
    <path d="M36 37H38L38 0H36L36 37Z" fill="black"/>
    <path d="M54 37H56L56 0H54L54 37Z" fill="black"/>
    <path d="M21 37H23L23 0H21L21 37Z" fill="black"/>
    <path d="M0 37H2L2 0H0L0 37Z" fill="black"/>
    </mask>
    <g mask="url(#mask0_29_306)">
    <path d="M43 37L21.3494 -0.5L64.6506 -0.5L43 37Z" id={id} fill={fill}/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_29_306">
    <rect width="86" height="37" fill="white" transform="matrix(-1 0 0 -1 86 37)"/>
    </clipPath>
    </defs>
    </svg>
  );
};

export default InvertedTriangleSmall;

import React from 'react';

interface ICircle_10 {
    fill: string;
    id?: string;
}

const Circle_10: React.FC<ICircle_10> = ({ fill, id }) => {
  return (
    <svg width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2201" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="294" height="294">
    <rect x="-1" y="-572" width="8" height="1437" fill="black"/>
    <rect x="23" y="-572" width="8" height="1437" fill="black"/>
    <rect x="47" y="-572" width="8" height="1437" fill="black"/>
    <rect x="71" y="-572" width="8" height="1437" fill="black"/>
    <rect x="95" y="-572" width="8" height="1437" fill="black"/>
    <rect x="119" y="-572" width="8" height="1437" fill="black"/>
    <rect x="143" y="-572" width="8" height="1437" fill="black"/>
    <rect x="167" y="-572" width="8" height="1437" fill="black"/>
    <rect x="191" y="-572" width="8" height="1437" fill="black"/>
    <rect x="215" y="-572" width="8" height="1437" fill="black"/>
    <rect x="239" y="-572" width="8" height="1437" fill="black"/>
    <rect x="263" y="-572" width="8" height="1437" fill="black"/>
    <rect x="287" y="-572" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2201)">
    <circle cx="147" cy="147" r="147" id={id} fill={fill} />
    </g>
    </svg>
  );
};

export default Circle_10;

import React from 'react';
import CircleSmall from './ShapeComponents/CircleSmall';
import Circle from './ShapeComponents/Circle';
import EllipseSmall from './ShapeComponents/EllipseSmall';
import Ellipse from './ShapeComponents/Ellipse';
import InvertedTriangleSmall from './ShapeComponents/InvertedTriangleSmall';
import InvertedTriangle from './ShapeComponents/InvertedTriangle';
import RectangleSmall from './ShapeComponents/RectangleSmall';
import Rectangle from './ShapeComponents/Rectangle';
import RhombusSmall from './ShapeComponents/RhombusSmall';
import Rhombus from './ShapeComponents/Rhombus';
import SquareSmall from './ShapeComponents/SquareSmall';
import Square from './ShapeComponents/Square';
import TriangleSmall from './ShapeComponents/TriangleSmall';
import Triangle from './ShapeComponents/Triangle';
import './DynamicSVG.css'

interface DynamicSVGProps {
  title: string;
  fillColor: string;
}

const shapeComponents: Record<string, React.FC<{ fill: string }>> = {
    circleSmall: CircleSmall,
    circle: Circle,
    ellipseSmall: EllipseSmall,
    ellipse: Ellipse,
    invertedTriangleSmall: InvertedTriangleSmall,
    invertedTriangle: InvertedTriangle,
    rectangleSmall: RectangleSmall, 
    rectangle: Rectangle,
    rhombusSmall: RhombusSmall,
    rhombus: Rhombus,
    squareSmall: SquareSmall,
    square: Square,
    triangleSmall: TriangleSmall,
    triangle: Triangle,
};

const DynamicSVG: React.FC<DynamicSVGProps> = ({ title, fillColor }) => {
    const SelectedSVG = shapeComponents[title];
  
    if (!SelectedSVG) {
      return null;
    }
  
    return (
        <div className='svg-container'>
            <SelectedSVG fill={fillColor} />
        </div>
    );
  };

export default DynamicSVG;

import React from 'react';
import { IRotateButtonParams } from "./IRotateButtonParams";
import './RotateButton.css';
import { useTranslation } from 'react-i18next';

export function RotateButton(params: IRotateButtonParams) {
    const { t } = useTranslation();
    const icon = require(`./../../../assets/img/icons/rotate-${params.direction}.svg`);

    return <img className={`rotate-button rotate-button--${params.direction} ${params.class}`} src={icon.default} alt={`Rotate icon`} title={`${t("rotateIn")} ${t(params.direction)} ${params.rotationDeg} ${t("deg")} (${t("maxRotationOf")} ${params.maxRotationDeg} ${t("deg")})`}
                onClick={() => { params.onRotationChange(params.rotationDeg, params.direction); }} style={{minHeight: '60px'}} />;
}

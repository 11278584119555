import React from 'react';

interface ICircle_02 {
    fill: string;
    id?: string;
}

const Circle_02: React.FC<ICircle_02> = ({ fill, id }) => {
  return (
    <svg width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_521_1424" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="294" height="294">
    <rect x="53" y="-566" width="2" height="1426" fill="black"/>
    <rect x="5" y="-566" width="2" height="1426" fill="black"/>
    <rect x="101" y="-566" width="2" height="1426" fill="black"/>
    <rect x="77" y="-566" width="2" height="1426" fill="black"/>
    <rect x="29" y="-566" width="2" height="1426" fill="black"/>
    <rect x="125" y="-566" width="2" height="1426" fill="black"/>
    <rect x="65" y="-566" width="2" height="1426" fill="black"/>
    <rect x="17" y="-566" width="2" height="1426" fill="black"/>
    <rect x="113" y="-566" width="2" height="1426" fill="black"/>
    <rect x="89" y="-566" width="2" height="1426" fill="black"/>
    <rect x="41" y="-566" width="2" height="1426" fill="black"/>
    <rect x="137" y="-566" width="2" height="1426" fill="black"/>
    <rect x="59" y="-566" width="2" height="1426" fill="black"/>
    <rect x="11" y="-566" width="2" height="1426" fill="black"/>
    <rect x="107" y="-566" width="2" height="1426" fill="black"/>
    <rect x="83" y="-566" width="2" height="1426" fill="black"/>
    <rect x="35" y="-566" width="2" height="1426" fill="black"/>
    <rect x="131" y="-566" width="2" height="1426" fill="black"/>
    <rect x="71" y="-566" width="2" height="1426" fill="black"/>
    <rect x="23" y="-566" width="2" height="1426" fill="black"/>
    <rect x="119" y="-566" width="2" height="1426" fill="black"/>
    <rect x="-1" y="-566" width="2" height="1426" fill="black"/>
    <rect x="95" y="-566" width="2" height="1426" fill="black"/>
    <rect x="47" y="-566" width="2" height="1426" fill="black"/>
    <rect x="143" y="-566" width="2" height="1426" fill="black"/>
    <rect x="149" y="-566" width="2" height="1426" fill="black"/>
    <rect x="245" y="-566" width="2" height="1426" fill="black"/>
    <rect x="197" y="-566" width="2" height="1426" fill="black"/>
    <rect x="293" y="-566" width="2" height="1426" fill="black"/>
    <rect x="173" y="-566" width="2" height="1426" fill="black"/>
    <rect x="269" y="-566" width="2" height="1426" fill="black"/>
    <rect x="221" y="-566" width="2" height="1426" fill="black"/>
    <rect x="161" y="-566" width="2" height="1426" fill="black"/>
    <rect x="257" y="-566" width="2" height="1426" fill="black"/>
    <rect x="209" y="-566" width="2" height="1426" fill="black"/>
    <rect x="185" y="-566" width="2" height="1426" fill="black"/>
    <rect x="281" y="-566" width="2" height="1426" fill="black"/>
    <rect x="233" y="-566" width="2" height="1426" fill="black"/>
    <rect x="155" y="-566" width="2" height="1426" fill="black"/>
    <rect x="251" y="-566" width="2" height="1426" fill="black"/>
    <rect x="203" y="-566" width="2" height="1426" fill="black"/>
    <rect x="179" y="-566" width="2" height="1426" fill="black"/>
    <rect x="275" y="-566" width="2" height="1426" fill="black"/>
    <rect x="227" y="-566" width="2" height="1426" fill="black"/>
    <rect x="167" y="-566" width="2" height="1426" fill="black"/>
    <rect x="263" y="-566" width="2" height="1426" fill="black"/>
    <rect x="215" y="-566" width="2" height="1426" fill="black"/>
    <rect x="191" y="-566" width="2" height="1426" fill="black"/>
    <rect x="287" y="-566" width="2" height="1426" fill="black"/>
    <rect x="239" y="-566" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_521_1424)">
    <circle cx="147" cy="147" r="147" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Circle_02;

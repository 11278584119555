import React from 'react';

interface IRhombusSmall_05 {
    fill: string;
    id?: string;
}

const RhombusSmall_05: React.FC<IRhombusSmall_05> = ({ fill, id }) => {
  return (
    <svg width="223" height="223" viewBox="0 0 223 223" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1732" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="223" height="223">
    <rect x="1.24536" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="13.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="25.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="37.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="49.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="61.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="73.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="85.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="97.2454" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="109.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="121.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="133.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="145.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="157.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="169.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="181.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="193.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="205.245" y="-523.997" width="4" height="1272" fill="black"/>
    <rect x="217.245" y="-523.997" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1732)">
    <rect x="111.245" y="0.37793" width="157.324" height="157.324" transform="rotate(45 111.245 0.37793)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RhombusSmall_05;

import React from 'react';

interface IRhombusSmall_15 {
    fill: string;
    id?: string;
}

const RhombusSmall_15: React.FC<IRhombusSmall_15> = ({ fill, id }) => {
  return (
    <svg width="220" height="220" viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_3040" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="220" height="220">
    <rect x="-3.63257" y="-870.631" width="12" height="1961" fill="black"/>
    <rect x="32.3674" y="-870.631" width="12" height="1961" fill="black"/>
    <rect x="68.3674" y="-870.631" width="12" height="1961" fill="black"/>
    <rect x="104.367" y="-870.631" width="12" height="1961" fill="black"/>
    <rect x="140.367" y="-870.631" width="12" height="1961" fill="black"/>
    <rect x="176.367" y="-870.631" width="12" height="1961" fill="black"/>
    <rect x="212.367" y="-870.631" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_3040)">
    <rect x="110" width="155.563" height="155.563" transform="rotate(45 110 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default RhombusSmall_15;

import React from 'react';

interface IRhombus_05 {
    fill: string;
    id?: string;
}

const Rhombus_05: React.FC<IRhombus_05> = ({ fill, id }) => {
  return (
    <svg width="413" height="413" viewBox="0 0 413 413" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1672" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="413" height="413">
    <rect x="0.475098" y="-430" width="4" height="1272" fill="black"/>
    <rect x="12.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="24.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="36.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="48.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="60.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="72.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="84.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="96.4751" y="-430" width="4" height="1272" fill="black"/>
    <rect x="108.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="120.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="132.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="144.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="156.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="168.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="180.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="192.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="204.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="216.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="228.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="240.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="252.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="264.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="276.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="288.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="300.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="312.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="324.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="336.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="348.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="360.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="372.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="384.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="396.475" y="-430" width="4" height="1272" fill="black"/>
    <rect x="408.475" y="-430" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1672)">
    <rect x="206.475" width="292" height="292" transform="rotate(45 206.475 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rhombus_05;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import { IConfig } from '../../utils/IConfig';
import { loadConfig } from '../../utils/IConfigUtils';
import { getEscapedSearch, getLanguage } from '../../utils/queryParamsHelper';

const config: IConfig = loadConfig();

interface ILanguageSwitcherProps {
  handleLanguageChange: (language: string) => void;
  temporaryLanguageStatus?: boolean
}

const LanguageSwitcher: React.FC<ILanguageSwitcherProps> = ({ handleLanguageChange, temporaryLanguageStatus }) => {
  const [temporaryLanguage, setTemporaryLanguage] = useState("")
  const [selectedLanguage, setSelectedLanguage] = useState(config.application.selectedLanguage)
  const { i18n } = useTranslation();
  const languages = ["en", "fr", "es", "ko", "zh", "pl", "pt"]
  const queryParams: URLSearchParams = new URLSearchParams(getEscapedSearch());
  const languageFromParams = getLanguage(queryParams);

  useEffect(() => {
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/');
    const languageFromUrl = pathSegments[1] && languages.includes(pathSegments[1])
    ? pathSegments[1]
    : pathSegments[2];

    if(languageFromUrl) {
      setTemporaryLanguage(languageFromUrl)
    } else {
      setTemporaryLanguage(selectedLanguage)
    }

  }, [])

  const handleChangeLanguage = (event: any) => {
    const selectedLanguage = event.target.value;

    if(temporaryLanguageStatus) {
      setTemporaryLanguage(selectedLanguage)
      i18n.changeLanguage(selectedLanguage);
      const newPath = `/${selectedLanguage}`;
      window.history.pushState(null, '', newPath);
    } else {
      setSelectedLanguage(selectedLanguage)
      handleLanguageChange(selectedLanguage)
    }
  };

  useEffect(() => {
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/');
    const languageFromUrl = pathSegments[1] && languages.includes(pathSegments[1])
    ? pathSegments[1]
    : pathSegments[2];

    let savedLanguage;
    if(languages.includes(languageFromUrl)) {
      savedLanguage = languageFromUrl;
    } else if (languageFromParams) {
      savedLanguage = languageFromParams
    } else if(config.application.selectedLanguage) {
      savedLanguage = config.application.selectedLanguage
    }

    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <select
      className="language-switcher"
      onChange={handleChangeLanguage}
      value={temporaryLanguageStatus ? temporaryLanguage : selectedLanguage}
    >
    {languages.map((item: string) => (
      <option key={item} value={item} className="language-option">
        {item.toUpperCase()}
      </option>
    ))}
    </select>
  );
}

export default LanguageSwitcher;

import React from 'react';

interface ITriangle_15 {
    fill: string;
    id?: string;
}

const Triangle_15: React.FC<ITriangle_15> = ({ fill, id }) => {
  return (
    <svg width="372" height="302" viewBox="0 0 372 302" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_2715" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="372" height="302">
    <rect y="-830" width="12" height="1961" fill="black"/>
    <rect x="36" y="-830" width="12" height="1961" fill="black"/>
    <rect x="72" y="-830" width="12" height="1961" fill="black"/>
    <rect x="108" y="-830" width="12" height="1961" fill="black"/>
    <rect x="144" y="-830" width="12" height="1961" fill="black"/>
    <rect x="180" y="-830" width="12" height="1961" fill="black"/>
    <rect x="216" y="-830" width="12" height="1961" fill="black"/>
    <rect x="252" y="-830" width="12" height="1961" fill="black"/>
    <rect x="288" y="-830" width="12" height="1961" fill="black"/>
    <rect x="324" y="-830" width="12" height="1961" fill="black"/>
    <rect x="360" y="-830" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_2715)">
    <path d="M186 0L372 302H0L186 0Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Triangle_15;

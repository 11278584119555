import { IFooterParams } from "./IFooterParams";
import { FooterIcon } from "../FooterIcon/FooterIcon";
import { StripeButton } from '../StripeButton/StripeButton';
import { WidthButton } from '../WidthButton/WidthButton';
import { SelectShapeButton } from '../SelectShapeButton/SelectShapeButton';
import { RotateButton } from '../RotateButton/RotateButton';
import './Footer.css';
import { downloadPdf, getShareUrl } from "./delegate";
import { IConfig } from "../../../utils/IConfig";
import { loadConfig } from "../../../utils/IConfigUtils";
import { Shape } from "../../Shape";
import { useTranslation } from "react-i18next";
import logo from '../../../assets/img/cruz-diez-logo.png';

const config: IConfig = loadConfig();

export function Footer(params: IFooterParams) {
    const { t } = useTranslation();

    const onShapeChange = (shape: Shape) => {
        params.onShapeChange(shape);
    };

    return <>
        <footer className={`footer ${params.print ? 'footer--print' : ''}`}>
            <div className="footer-logo--wrapper">
                {config.application.artworkLogoLeft.src.length > 0 && <img className={`${config.application.artworkLogoLeft.hide ? 'hide-opacity' : 'footer-logo--artwork'}`} alt={"Logo left side"} src={config.application.artworkLogoLeft.src} />}
                <img className={"footer-logo"} src={logo} alt="Cruz Diez logo" style={{position: 'absolute', left: "50%", transform: 'translate(-50%, 0)'}} />
                {config.application.artworkLogoRight.src.length > 0 && <img className={`${config.application.artworkLogoRight.hide ? 'hide-opacity' : 'footer-logo--artwork right'}`} alt={"Logo right side"} src={config.application.artworkLogoRight.src} />}
            </div>
            <div className="footer__icon-wrapper">
                <div>
                    <StripeButton palette={params.palette} selectedStripe={params.selectedStripe} onStripeChanged={(i) => {
                        params.onStripeChanged(i);
                    }} />
                    <WidthButton class="footer__icon" onColorChanged={params.onColorChanged} />
                </div>
                <div>
                    <SelectShapeButton
                        class="footer__icon"
                        shapePalette={params.shapePalette}
                        shapes={params.shapes}
                        onShapeSelect={(i) => {
                            params.onShapeSelect(i);
                        }}
                        onShapeChange={onShapeChange}
                        id={0}
                        selectedShape={params.selectedShape}
                        onShapeHidden={params.onShapeHidden}
                        shapeColor={params.shapeColors[0]}
                    />
                    <SelectShapeButton
                        class="footer__icon"
                        shapePalette={params.shapePalette}
                        shapes={params.shapes}
                        onShapeSelect={(i) => {
                            params.onShapeSelect(i);
                        }}
                        onShapeChange={onShapeChange}
                        id={1}
                        selectedShape={params.selectedShape}
                        onShapeHidden={params.onShapeHidden}
                        shapeColor={params.shapeColors[1]}
                    />
                    <SelectShapeButton
                        class="footer__icon"
                        shapePalette={params.shapePalette}
                        shapes={params.shapes}
                        onShapeSelect={(i) => {
                            params.onShapeSelect(i);
                        }}
                        onShapeChange={onShapeChange}
                        id={2}
                        selectedShape={params.selectedShape}
                        onShapeHidden={params.onShapeHidden}
                        shapeColor={params.shapeColors[2]}
                    />
                </div>
                <div>
                    <div className="footer__icon--rotating-icon-container">
                        <RotateButton class="footer__icon" direction="left" maxRotationDeg={params.maxShapeRotationDegree} rotationDeg={params.shapeRotationDegree.left} onRotationChange={(deg, direction) => { params.onRotationChange(deg, direction); }} />
                        <RotateButton class="footer__icon" direction="right" maxRotationDeg={params.maxShapeRotationDegree} rotationDeg={params.shapeRotationDegree.right} onRotationChange={(deg, direction) => { params.onRotationChange(deg, direction); }} />
                    </div>
                    <div>
                        {/* <FooterIcon class="footer__icon" alt="Arrow red up" imagePath="assets/img/icons/arrow-up.png" onClickAction={ () => alert("Empty action") } /> */}
                        {/* <FooterIcon class="footer__icon" alt="Arrow green down" imagePath="assets/img/icons/arrow-down.png" onClickAction={ () => alert("Empty action") } /> */}
                        <FooterIcon class={`footer__icon ${config.application.hideIcon.qrcode ? 'hide' : ''}`} alt="Qr code" imagePath="assets/img/icons/qrcode.png" onClickAction={() => params.showQrCode()} />
                        <FooterIcon class={`footer__icon ${config.application.hideIcon.print ? 'hide' : ''}`} alt={t("print")} title={t("print")} imagePath="assets/img/icons/print.png" onClickAction={() => downloadPdf(params)} />
                        {/* <FooterIcon class={`footer__icon ${config.application.hideIcon.print ? 'hide' : ''}`} alt={t("print")} title={t("print")} imagePath="assets/img/icons/print.png" onClickAction={() => window.print()} /> */}
                        {/* <FooterIcon class="footer__icon" alt="Print crossed out" imagePath="assets/img/icons/print-cross.png" onClickAction={ () => alert("Empty action") } /> */}

                        <FooterIcon class={`footer__icon footer__icon--scale-07 ${config.application.hideIcon.share ? 'hide' : ''}`} alt="Share" title={t("shareYourWork")} imagePath="assets/img/icons/share.png" onClickAction={() => params.onToggleSharePopUp(getShareUrl(params))} />
                        <FooterIcon class="footer__icon footer__icon--back-to-intro footer__icon--scale-08" alt="Reset" title={t("backToIntro")} imagePath="assets/img/icons/reset.svg" onClickAction={() => { params.onResetToDefault(); params.stopTimer(); window.location.reload() }} />
                        {/* <FooterIcon class={`footer__icon footer__icon--scale-08 ${config.application.hideIcon.demo ? 'hide' : ''}`} alt="Demo" title={t("showAppDemonstration")} imagePath="assets/img/icons/demo.png" onClickAction={() => params.onDemoInstructionsToggle(true)} /> */}
                    </div>
                </div>
            </div>
            <div style={{marginLeft: '10px'}}>
                <FooterIcon class={`footer__icon footer__icon--settings ${params.onToggleSettingsPopUp ? '' : 'hide'}`} alt="Settings" title={t("openSettingsWindow")} imagePath="assets/img/icons/settings.svg" onClickAction={() => params.onToggleSettingsPopUp!()} />
            </div>
        </footer>
        <footer className={`footer ${params.print ? '' : 'footer--print'}`}>
            <div className="footer-logo--wrapper">
                {params.logoUrl ? <img style={{marginLeft: '150px', marginTop: '15px'}} className={`${config.application.artworkLogoLeft.hide ? 'hide-opacity' : 'footer-logo--artwork'}`} alt={"Logo left side"} src={`https://storagecruzdiezflashprod.blob.core.windows.net/content/configurations/${params.logoUrl}`} /> : <div></div>}
                <img className={"footer-logo"} src={logo} alt="Cruz Diez logo" style={{position: 'absolute', left: "50%", transform: 'translate(-50%, 0)'}} />
                <p style={{marginRight: '150px', fontSize: '14px'}}>{`${t("interpretedBy")}____________________`}</p>
            </div>
        </footer>
    </>
}

export default Footer;
import React from 'react';

interface IInvertedTriangleSmall_05 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_05: React.FC<IInvertedTriangleSmall_05> = ({ fill, id }) => {
  return (
    <svg width="196" height="156" viewBox="0 0 196 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1311" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="196" height="156">
    <rect x="196" y="714" width="4" height="1272" transform="rotate(-180 196 714)" fill="black"/>
    <rect x="184" y="714" width="4" height="1272" transform="rotate(-180 184 714)" fill="black"/>
    <rect x="172" y="714" width="4" height="1272" transform="rotate(-180 172 714)" fill="black"/>
    <rect x="160" y="714" width="4" height="1272" transform="rotate(-180 160 714)" fill="black"/>
    <rect x="148" y="714" width="4" height="1272" transform="rotate(-180 148 714)" fill="black"/>
    <rect x="136" y="714" width="4" height="1272" transform="rotate(-180 136 714)" fill="black"/>
    <rect x="124" y="714" width="4" height="1272" transform="rotate(-180 124 714)" fill="black"/>
    <rect x="112" y="714" width="4" height="1272" transform="rotate(-180 112 714)" fill="black"/>
    <rect x="100" y="714" width="4" height="1272" transform="rotate(-180 100 714)" fill="black"/>
    <rect x="88" y="714" width="4" height="1272" transform="rotate(-180 88 714)" fill="black"/>
    <rect x="76" y="714" width="4" height="1272" transform="rotate(-180 76 714)" fill="black"/>
    <rect x="64" y="714" width="4" height="1272" transform="rotate(-180 64 714)" fill="black"/>
    <rect x="52" y="714" width="4" height="1272" transform="rotate(-180 52 714)" fill="black"/>
    <rect x="40" y="714" width="4" height="1272" transform="rotate(-180 40 714)" fill="black"/>
    <rect x="28" y="714" width="4" height="1272" transform="rotate(-180 28 714)" fill="black"/>
    <rect x="16" y="714" width="4" height="1272" transform="rotate(-180 16 714)" fill="black"/>
    <rect x="4" y="714" width="4" height="1272" transform="rotate(-180 4 714)" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1311)">
    <path d="M98 156L7.74547e-06 -1.14128e-05L196 5.72205e-06L98 156Z" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default InvertedTriangleSmall_05;

import React from 'react';

interface ICircleSmall_02 {
  fill: string;
  id?: string;
}

const CircleSmall_02: React.FC<ICircleSmall_02> = ({ fill, id }) => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_521_1425" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
    <rect x="53" y="-566" width="2" height="1426" fill="black"/>
    <rect x="5" y="-566" width="2" height="1426" fill="black"/>
    <rect x="101" y="-566" width="2" height="1426" fill="black"/>
    <rect x="77" y="-566" width="2" height="1426" fill="black"/>
    <rect x="29" y="-566" width="2" height="1426" fill="black"/>
    <rect x="125" y="-566" width="2" height="1426" fill="black"/>
    <rect x="65" y="-566" width="2" height="1426" fill="black"/>
    <rect x="17" y="-566" width="2" height="1426" fill="black"/>
    <rect x="113" y="-566" width="2" height="1426" fill="black"/>
    <rect x="89" y="-566" width="2" height="1426" fill="black"/>
    <rect x="41" y="-566" width="2" height="1426" fill="black"/>
    <rect x="137" y="-566" width="2" height="1426" fill="black"/>
    <rect x="59" y="-566" width="2" height="1426" fill="black"/>
    <rect x="11" y="-566" width="2" height="1426" fill="black"/>
    <rect x="107" y="-566" width="2" height="1426" fill="black"/>
    <rect x="83" y="-566" width="2" height="1426" fill="black"/>
    <rect x="35" y="-566" width="2" height="1426" fill="black"/>
    <rect x="131" y="-566" width="2" height="1426" fill="black"/>
    <rect x="71" y="-566" width="2" height="1426" fill="black"/>
    <rect x="23" y="-566" width="2" height="1426" fill="black"/>
    <rect x="119" y="-566" width="2" height="1426" fill="black"/>
    <rect x="-1" y="-566" width="2" height="1426" fill="black"/>
    <rect x="95" y="-566" width="2" height="1426" fill="black"/>
    <rect x="47" y="-566" width="2" height="1426" fill="black"/>
    <rect x="143" y="-566" width="2" height="1426" fill="black"/>
    <rect x="149" y="-566" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_521_1425)">
    <circle cx="75" cy="75" r="75" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default CircleSmall_02;

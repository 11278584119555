import React from 'react';

interface IRectangle_10 {
    fill: string;
    id?: string;
}

const Rectangle_10: React.FC<IRectangle_10> = ({ fill, id }) => {
  return (
    <svg width="392" height="200" viewBox="0 0 392 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_526_2417" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="392" height="200">
    <rect y="-619" width="8" height="1437" fill="black"/>
    <rect x="24" y="-619" width="8" height="1437" fill="black"/>
    <rect x="48" y="-619" width="8" height="1437" fill="black"/>
    <rect x="72" y="-619" width="8" height="1437" fill="black"/>
    <rect x="96" y="-619" width="8" height="1437" fill="black"/>
    <rect x="120" y="-619" width="8" height="1437" fill="black"/>
    <rect x="144" y="-619" width="8" height="1437" fill="black"/>
    <rect x="168" y="-619" width="8" height="1437" fill="black"/>
    <rect x="192" y="-619" width="8" height="1437" fill="black"/>
    <rect x="216" y="-619" width="8" height="1437" fill="black"/>
    <rect x="240" y="-619" width="8" height="1437" fill="black"/>
    <rect x="264" y="-619" width="8" height="1437" fill="black"/>
    <rect x="288" y="-619" width="8" height="1437" fill="black"/>
    <rect x="312" y="-619" width="8" height="1437" fill="black"/>
    <rect x="336" y="-619" width="8" height="1437" fill="black"/>
    <rect x="360" y="-619" width="8" height="1437" fill="black"/>
    <rect x="384" y="-619" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_526_2417)">
    <rect width="392" height="200" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rectangle_10;

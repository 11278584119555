import React from 'react';

interface IEllipseSmall_05 {
    fill: string;
    id?: string;
}

const EllipseSmall_05: React.FC<IEllipseSmall_05> = ({ fill, id }) => {
  return (
    <svg width="220" height="107" viewBox="0 0 220 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1974" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="220" height="107">
    <rect y="-583" width="4" height="1272" fill="black"/>
    <rect x="12" y="-583" width="4" height="1272" fill="black"/>
    <rect x="24" y="-583" width="4" height="1272" fill="black"/>
    <rect x="36" y="-583" width="4" height="1272" fill="black"/>
    <rect x="48" y="-583" width="4" height="1272" fill="black"/>
    <rect x="60" y="-583" width="4" height="1272" fill="black"/>
    <rect x="72" y="-583" width="4" height="1272" fill="black"/>
    <rect x="84" y="-583" width="4" height="1272" fill="black"/>
    <rect x="96" y="-583" width="4" height="1272" fill="black"/>
    <rect x="108" y="-583" width="4" height="1272" fill="black"/>
    <rect x="120" y="-583" width="4" height="1272" fill="black"/>
    <rect x="132" y="-583" width="4" height="1272" fill="black"/>
    <rect x="144" y="-583" width="4" height="1272" fill="black"/>
    <rect x="156" y="-583" width="4" height="1272" fill="black"/>
    <rect x="168" y="-583" width="4" height="1272" fill="black"/>
    <rect x="180" y="-583" width="4" height="1272" fill="black"/>
    <rect x="192" y="-583" width="4" height="1272" fill="black"/>
    <rect x="204" y="-583" width="4" height="1272" fill="black"/>
    <rect x="216" y="-583" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1974)">
    <ellipse cx="110" cy="53.5" rx="110" ry="53.5" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default EllipseSmall_05;

import React from 'react';

interface ISquare_10 {
    fill: string;
    id?: string;
}

const Square_10: React.FC<ISquare_10> = ({ fill, id }) => {
  return (
    <svg width="296" height="296" viewBox="0 0 296 296" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_2272" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="296" height="296">
    <rect y="-571" width="8" height="1437" fill="black"/>
    <rect x="24" y="-571" width="8" height="1437" fill="black"/>
    <rect x="48" y="-571" width="8" height="1437" fill="black"/>
    <rect x="72" y="-571" width="8" height="1437" fill="black"/>
    <rect x="96" y="-571" width="8" height="1437" fill="black"/>
    <rect x="120" y="-571" width="8" height="1437" fill="black"/>
    <rect x="144" y="-571" width="8" height="1437" fill="black"/>
    <rect x="168" y="-571" width="8" height="1437" fill="black"/>
    <rect x="192" y="-571" width="8" height="1437" fill="black"/>
    <rect x="216" y="-571" width="8" height="1437" fill="black"/>
    <rect x="240" y="-571" width="8" height="1437" fill="black"/>
    <rect x="264" y="-571" width="8" height="1437" fill="black"/>
    <rect x="288" y="-571" width="8" height="1437" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_2272)">
    <rect width="296" height="296" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Square_10;

import React from 'react';

interface ISquare_02 {
    fill: string;
    id?: string;
}

const Square_02: React.FC<ISquare_02> = ({ fill, id }) => {
  return (
    <svg width="290" height="290" viewBox="0 0 290 290" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_522_1686" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="290" height="290">
    <rect x="48" y="-566" width="2" height="1426" fill="black"/>
    <rect y="-566" width="2" height="1426" fill="black"/>
    <rect x="96" y="-566" width="2" height="1426" fill="black"/>
    <rect x="72" y="-566" width="2" height="1426" fill="black"/>
    <rect x="24" y="-566" width="2" height="1426" fill="black"/>
    <rect x="120" y="-566" width="2" height="1426" fill="black"/>
    <rect x="60" y="-566" width="2" height="1426" fill="black"/>
    <rect x="12" y="-566" width="2" height="1426" fill="black"/>
    <rect x="108" y="-566" width="2" height="1426" fill="black"/>
    <rect x="84" y="-566" width="2" height="1426" fill="black"/>
    <rect x="36" y="-566" width="2" height="1426" fill="black"/>
    <rect x="132" y="-566" width="2" height="1426" fill="black"/>
    <rect x="54" y="-566" width="2" height="1426" fill="black"/>
    <rect x="6" y="-566" width="2" height="1426" fill="black"/>
    <rect x="102" y="-566" width="2" height="1426" fill="black"/>
    <rect x="78" y="-566" width="2" height="1426" fill="black"/>
    <rect x="30" y="-566" width="2" height="1426" fill="black"/>
    <rect x="126" y="-566" width="2" height="1426" fill="black"/>
    <rect x="66" y="-566" width="2" height="1426" fill="black"/>
    <rect x="18" y="-566" width="2" height="1426" fill="black"/>
    <rect x="114" y="-566" width="2" height="1426" fill="black"/>
    <rect x="90" y="-566" width="2" height="1426" fill="black"/>
    <rect x="42" y="-566" width="2" height="1426" fill="black"/>
    <rect x="138" y="-566" width="2" height="1426" fill="black"/>
    <rect x="144" y="-566" width="2" height="1426" fill="black"/>
    <rect x="240" y="-566" width="2" height="1426" fill="black"/>
    <rect x="192" y="-566" width="2" height="1426" fill="black"/>
    <rect x="288" y="-566" width="2" height="1426" fill="black"/>
    <rect x="168" y="-566" width="2" height="1426" fill="black"/>
    <rect x="264" y="-566" width="2" height="1426" fill="black"/>
    <rect x="216" y="-566" width="2" height="1426" fill="black"/>
    <rect x="156" y="-566" width="2" height="1426" fill="black"/>
    <rect x="252" y="-566" width="2" height="1426" fill="black"/>
    <rect x="204" y="-566" width="2" height="1426" fill="black"/>
    <rect x="180" y="-566" width="2" height="1426" fill="black"/>
    <rect x="276" y="-566" width="2" height="1426" fill="black"/>
    <rect x="228" y="-566" width="2" height="1426" fill="black"/>
    <rect x="150" y="-566" width="2" height="1426" fill="black"/>
    <rect x="246" y="-566" width="2" height="1426" fill="black"/>
    <rect x="198" y="-566" width="2" height="1426" fill="black"/>
    <rect x="174" y="-566" width="2" height="1426" fill="black"/>
    <rect x="270" y="-566" width="2" height="1426" fill="black"/>
    <rect x="222" y="-566" width="2" height="1426" fill="black"/>
    <rect x="162" y="-566" width="2" height="1426" fill="black"/>
    <rect x="258" y="-566" width="2" height="1426" fill="black"/>
    <rect x="210" y="-566" width="2" height="1426" fill="black"/>
    <rect x="186" y="-566" width="2" height="1426" fill="black"/>
    <rect x="282" y="-566" width="2" height="1426" fill="black"/>
    <rect x="234" y="-566" width="2" height="1426" fill="black"/>
    </mask>
    <g mask="url(#mask0_522_1686)">
    <rect width="290" height="290" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Square_02;

import React from 'react';

interface ICircle_05 {
    fill: string;
    id?: string;
}

const Circle_05: React.FC<ICircle_05> = ({ fill, id }) => {
  return (
    <svg width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_525_1431" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="294" height="294">
    <rect x="-2" y="-486" width="4" height="1272" fill="black"/>
    <rect x="10" y="-486" width="4" height="1272" fill="black"/>
    <rect x="22" y="-486" width="4" height="1272" fill="black"/>
    <rect x="34" y="-486" width="4" height="1272" fill="black"/>
    <rect x="46" y="-486" width="4" height="1272" fill="black"/>
    <rect x="58" y="-486" width="4" height="1272" fill="black"/>
    <rect x="70" y="-486" width="4" height="1272" fill="black"/>
    <rect x="82" y="-486" width="4" height="1272" fill="black"/>
    <rect x="94" y="-486" width="4" height="1272" fill="black"/>
    <rect x="106" y="-486" width="4" height="1272" fill="black"/>
    <rect x="118" y="-486" width="4" height="1272" fill="black"/>
    <rect x="130" y="-486" width="4" height="1272" fill="black"/>
    <rect x="142" y="-486" width="4" height="1272" fill="black"/>
    <rect x="154" y="-486" width="4" height="1272" fill="black"/>
    <rect x="166" y="-486" width="4" height="1272" fill="black"/>
    <rect x="178" y="-486" width="4" height="1272" fill="black"/>
    <rect x="190" y="-486" width="4" height="1272" fill="black"/>
    <rect x="202" y="-486" width="4" height="1272" fill="black"/>
    <rect x="214" y="-486" width="4" height="1272" fill="black"/>
    <rect x="226" y="-486" width="4" height="1272" fill="black"/>
    <rect x="238" y="-486" width="4" height="1272" fill="black"/>
    <rect x="250" y="-486" width="4" height="1272" fill="black"/>
    <rect x="262" y="-486" width="4" height="1272" fill="black"/>
    <rect x="274" y="-486" width="4" height="1272" fill="black"/>
    <rect x="286" y="-486" width="4" height="1272" fill="black"/>
    </mask>
    <g mask="url(#mask0_525_1431)">
    <circle cx="147" cy="147" r="147" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Circle_05;

import React from "react";
import { IConfig } from "../utils/IConfig";
import { loadConfig } from "../utils/IConfigUtils";
import { download } from "./Bottom/Footer/delegate";
import { useTranslation } from "react-i18next";
import { Vizualization } from "./VizualizationDownload";
import { Palette } from "./Palette";
import { Shapes } from "./Shapes";
import { ShapePosition } from "./ShapePosition";
import "./remoteDownload.css"; 

const queryParams = new URLSearchParams(window.location.search);

export interface IRemoteDownloadParams {
    palette: Palette;
    shapes: Shapes;
    selectedShape: number;
    shapeColors: string[];
    stripesWidth: number;
    shapePositions: ShapePosition[];
    anyAction: boolean;
}

function RemoteDownload(remoteDownloadParams: IRemoteDownloadParams) {
    const { t } = useTranslation();
    let params = queryParams.get('download') || "";
    const config: IConfig = loadConfig();
    let url = `${config.environment.printServiceUrl}?url=${config.environment.selfUrlForPrintService}?print=${params}`
    return <div className="App">
        <div>
            <Vizualization
                palette={remoteDownloadParams.palette} shapes={remoteDownloadParams.shapes} selectedShape={remoteDownloadParams.selectedShape} shapeColors={remoteDownloadParams.shapeColors}
                stripesWidth={remoteDownloadParams.stripesWidth} shapePositions={remoteDownloadParams.shapePositions} anyAction={remoteDownloadParams.anyAction}
            />
            <button
                style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginLeft: 'auto', marginRight: 'auto' }}
                className="intro__button"
                onClick={() => download(url)}>{t("download")}</button>
        </div>
    </div>
}

export default RemoteDownload;
import React from 'react';

interface IRhombus_15 {
    fill: string;
    id?: string;
}

const Rhombus_15: React.FC<IRhombus_15> = ({ fill, id }) => {
  return (
    <svg width="437" height="437" viewBox="0 0 437 437" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_529_3005" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="437" height="437">
    <rect x="-3.80518" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="32.1948" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="68.1948" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="104.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="140.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="176.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="212.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="248.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="284.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="320.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="356.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="392.195" y="-762.677" width="12" height="1961" fill="black"/>
    <rect x="428.195" y="-762.677" width="12" height="1961" fill="black"/>
    </mask>
    <g mask="url(#mask0_529_3005)">
    <rect x="218.185" width="308.56" height="308.56" transform="rotate(45 218.185 0)" id={id} fill={fill}/>
    </g>
    </svg>
  );
};

export default Rhombus_15;
